export default function barChart({
  theme,
  colorPalette,
  loader,
  chartData,
  label,
}) {
  let noData = true;
  let greaterSide = null;
  let leftSide = null;
  let rightSide = null;
  chartData?.series?.forEach((series) => {
    series?.data?.forEach((data) => {
      if (data.value !== null) {
        noData = false;
      }
    });
  });
  // if (noData === false) {
  //   chartData?.series[0]?.data.map((value, i) => (leftSide += value?.value));
  //   leftSide /= chartData?.series[0]?.data.length;
  //   chartData?.series[1]?.data.map((value, i) => (rightSide += value?.value));
  //   rightSide /= chartData?.series[1]?.data.length;
  //   if (leftSide > rightSide) {
  //     greaterSide = "leftSide";
  //   } else {
  //     greaterSide = "rightSide";
  //   }
  // }

  const roundToNearestHundred = (value) => Math?.ceil(value / 100) * 100;
  const roundToNearestThousand = (value) => Math?.ceil(value / 1000) * 1000;
  const chart = {
    title: {
      text:
        !loader && noData
          ? `No Data available for ${chartData.title} `
          : chartData.title,
      left: noData ? "center" : "left",
      top: noData ? "center" : "top",
      textStyle: {
        color: "#777",
        fontFamily: "Montserrat Bold",
        fontSize: "13px",
        overflow: "break",
        width: noData ? 200 : 400,
      },
    },
    tooltip: {
      trigger: "axis",
      // formatter: "{a} <br/>{b} : {c} ({d}%)" ,
      order: "seriesDesc",
      axisPointer: {
        // Use axis to trigger tooltip
        type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
      },
    },
    toolbox: {
      show: true,
      showTitle: false, // hide the default text so they don't overlap each other
      feature: {
        saveAsImage: {
          show: true,
          title: "Save As Image",
        },
      },
    },
    legend: {
      data: noData ? [] : label,
      orient: "horizontal",
      left: "right",
      top: "bottom",
      icon: "circle",
      padding: [40, 0, 0, 0],
      itemGap: 10,
      itemHeight: 10,
      textStyle: {
        color: "#777",
        fontFamily: "Montserrat Regular",
        fontSize: "10px",
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    yAxis: [
      {
        type: "value",
        scale: true,
        // max: greaterSide === "rightSide" ? Math.ceil(leftSide * 5) : null,
        min: 0,
        max: (value) => roundToNearestHundred(Math.ceil(value?.max * 1.2)),
        position: "right",
        alignTicks: true,
        axisLine: {
          show: false,
          lineStyle: {
            color: colorPalette[0],
          },
        },
        axisLabel: {
          formatter: (value) => `${parseInt(value).toString()}`,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: theme === "light" ? "#ccc" : "#333",
          },
        },
      },
      {
        type: "value",
        scale: true,
        //name: 'Unmatched',
        // max: greaterSide === "leftSide" ? Math.ceil(rightSide * 5) : null,
        min: 0,
        max: (value) => roundToNearestThousand(Math.ceil(value?.max * 4)),
        position: "left",
        axisLine: {
          show: false,
          lineStyle: {
            color: colorPalette[1],
          },
        },
        axisLabel: {
          formatter: (value) => `${parseInt(value).toString()}`,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: theme === "light" ? "#ccc" : "#333",
          },
        },
      },
    ],
    xAxis: {
      type: "category",
      data: noData ? [] : chartData.xAxis.data,
      axisLabel: {
        interval: 0,
        rotate: 30, //If the label names are too long you can manage this by rotating the label.
      },
      axisLine: {
        show: noData ? false : true,
      },
    },
    series: [
      {
        name: label[0],
        type: "bar",
        barGap: "0%",
        barCategoryGap: "40%",
        //stack: 'total',
        color: colorPalette[0],
        // barWidth:
        //   chartData?.xAxis?.data?.length === 3
        //     ? 14
        //     : chartData?.xAxis?.data?.length === 2
        //     ? 20
        //     : null,
        label: {
          show: true,
          color: "#ccc",
          position: "insideBottom",
          distance: 5,
          align: "left",
          verticalAlign: "middle",
          rotate: 90,
          fontSize: 10,
          backgroundColor: "transparent",
          rich: {
            name: {},
          },
        },
        emphasis: {
          focus: "series",
        },
        data: chartData?.series[0]?.data,
        yAxisIndex: 0,
      },
      {
        name: label[1],
        color: colorPalette[1],
        type: "bar",
        //stack: 'total',
        // barWidth:
        //   chartData?.xAxis?.data?.length === 3
        //     ? 14
        //     : chartData?.xAxis?.data?.length === 2
        //     ? 20
        //     : null,
        label: {
          show: true,
          color: "#ccc",
          position: "insideBottom",
          distance: 5,
          align: "left",
          verticalAlign: "middle",
          rotate: 90,
          fontSize: 10,
          backgroundColor: "transparent",
          rich: {
            name: {},
          },
        },
        emphasis: {
          focus: "series",
        },
        animationDelay: function (idx) {
          return idx * 100;
        },
        yAxisIndex: 1,
        data: chartData?.series[1]?.data,
      },
      {
        name: label[2],
        color: colorPalette[2],
        type: "bar",
        //stack: 'total',
        // barWidth:
        //   chartData?.xAxis?.data?.length === 3
        //     ? 14
        //     : chartData?.xAxis?.data?.length === 2
        //     ? 20
        //     : null,
        label: {
          show: true,
          color: "#ccc",
          position: "insideBottom",
          distance: 5,
          align: "left",
          verticalAlign: "middle",
          rotate: 90,
          fontSize: 10,
          backgroundColor: "transparent",
          rich: {
            name: {},
          },
        },
        emphasis: {
          focus: "series",
        },
        animationDelay: function (idx) {
          return idx * 100;
        },
        yAxisIndex: 1,
        data: chartData?.series[2]?.data,
      },
      {
        name: label[3],
        color: colorPalette[3],
        type: "bar",
        //stack: 'total',
        // barWidth:
        //   chartData?.xAxis?.data?.length === 3
        //     ? 14
        //     : chartData?.xAxis?.data?.length === 2
        //     ? 20
        //     : null,
        label: {
          show: true,
          color: "#ccc",
          position: "insideBottom",
          distance: 5,
          align: "left",
          verticalAlign: "middle",
          rotate: 90,
          fontSize: 10,
          backgroundColor: "transparent",
          rich: {
            name: {},
          },
        },
        emphasis: {
          focus: "series",
        },
        animationDelay: function (idx) {
          return idx * 100;
        },
        yAxisIndex: 1,
        data: chartData?.series[3]?.data,
      },
    ],
  };
  return chart;
}
