import React, { useState, useEffect, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import axiosInstance from "../../../utils/axiosInstance";
import queryString from "query-string";
import { ReconValidationSchema, errorStyling } from "./ValidationSchema";
import LoaderComponent from "../../../components/Spinner/LoaderComponent";
import ShowForPermission from "../../../utils/permissionsWrapper";
import { Modal, Tab, Tabs } from "react-bootstrap";
import ButtonBasic from "../../../components/Buttons/ButtonBasic";
import ButtonTransparent from "../../../components/Buttons/ButtonTransparent";
import AddIcon from "../../../assets/images/add-icon.png";
import { useDateTime } from "../../../components/Helper/DateTime";
import { Formik, Form, Field, ErrorMessage, FormikConsumer } from "formik";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import CustomAccordion from "../../../components/Accordion/CustomAccordion";
import PairExecutionModal from "./PairExecutionModal";
import Toast from "../../../components/Toast/Toast";
import Select from "react-select";
import AddPairModal from "./AddPairModal";
import ReconModal from "./ReconModal";
import LeavingModal from "./LeavingModal";
import { DSA } from "./UpdatePairComponents/DSA";
import { DSB } from "./UpdatePairComponents/DSB";
import { ChainID } from "./UpdatePairComponents/ChainID";
import MatchingRule from "./UpdatePairComponents/MatchingRule";

const WorkflowManagerPairs = (props) => {
  const history = useHistory();
  const { setRefresh, isMobile } = useDateTime();
  const [formikFlag, setFormikFlag] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [pairId, setPairId] = useState("");
  const [reconPairId, setReconPairId] = useState("");
  const [pairIndex, setPairIndex] = useState("");
  const [pairData, setPairData] = useState({});
  const [loading, setLoading] = useState(true);
  const [isActive, setIsActive] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showPairModal, setShowPairModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeletePairModal, setShowDeletePairModal] = useState(false);
  const [showEditPairModal, setShowEditPairModal] = useState(false);
  const [breadCrumbState, setBreadCrumbState] = useState([
    {
      id: 0,
      title: "Workflow Manager",
      isActive: false,
      routeTo: "/workflow-manager",
    },
    {
      id: 1,
      title: "Workflow Pairs",
      isActive: true,
      routeTo: `/workflow-managerpairs?id=${
        queryString.parse(window?.location?.search)?.id
      }`,
    },
  ]);
  const [masterDataSource, setMasterDataSource] = useState([]);
  const [aggregateFields, setAggregateFields] = useState([]);
  const [dsaFields, setDsaFields] = useState([]);
  const [dsbFields, setDsbFields] = useState([]);
  const [operatorOptions, setOperatorOptions] = useState([]);
  const encodeBase64 = (data) => {
    return btoa(data);
  };
  const getWorkflowsPairsData = async (id) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/recon/workflow/${id}`);
      if (response.message.status !== "200") {
        setLoading(false);
      } else {
        setPairData(response?.data || {});
        setSelectedData((prevState) =>
          response?.data?.workflow?.length > 0
            ? response?.data?.workflow?.find(
                (item) => item?.id === prevState?.id
              ) ?? response?.data?.workflow[0]
            : null
        );
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  useEffect(() => {
    const { id } = queryString.parse(window.location.search);
    getWorkflowsPairsData(id);
    setRefresh(() => () => {
      getWorkflowsPairsData(id);
    });
    return () => {
      setRefresh(() => () => {});
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (pairData?.reconName) {
      if (
        breadCrumbState[breadCrumbState.length - 1].title !==
        pairData?.reconName
      ) {
        setBreadCrumbState((prevState) => [
          ...prevState,
          {
            id: 2,
            title: pairData?.reconName,
            isActive: true,
            routeTo: `/workflow-managerpairs?id=${
              queryString.parse(window?.location?.search)?.id
            }`,
          },
        ]);
      }
    }
  }, [pairData?.reconName]);

  const [reconInitialValues, setReconInitialValues] = useState({});

  const handleSelected = (dataSource) => {
    setSelectedData(dataSource);
    setIsActive(true);
  };
  const addExecutionPair = (values) => {
    setLoading(true);
    setSelectedData((prevState) => ({
      ...prevState,
      pairExecution: [
        ...reconInitialValues?.pairExecution,
        {
          name: values?.ruleName,
          pairId: selectedData?.id,
          reconType: values?.ruleType,
          matchingRule: JSON.stringify(values?.matchingRule),
          matchingBucket: values?.matchingBucket,
          sequence: values?.sequence,
          script: values?.matchingScript,
          scriptName: null,
          enabled: values?.enabled,
        },
      ],
    }));
    setLoading(false);
  };

  const matchingOptions = [
    {
      value: "1:1",
      label: "1:1",
    },
    {
      value: "1:M",
      label: "1:M",
    },
    {
      value: "M:1",
      label: "M:1",
    },
    {
      value: "M:M",
      label: "M:M",
    },
    {
      value: "1:MAG",
      label: "1:MAG",
    },
  ];

  const ruleTypeOptions = (value) => {
    if (value === "1:1") {
      return [{ value: "1:1", label: "1:1" }];
    } else if (value === "1:M") {
      return [
        { value: "1:1", label: "1:1" },
        { value: "1:M", label: "1:M" },
      ];
    } else if (value === "M:1") {
      return [
        { value: "1:1", label: "1:1" },
        { value: "1:M", label: "1:M" },
        { value: "M:1", label: "M:1" },
      ];
    } else if (value === "M:M") {
      return [
        { value: "1:1", label: "1:1" },
        { value: "1:M", label: "1:M" },
        { value: "M:1", label: "M:1" },
        { value: "M:M", label: "M:M" },
      ];
    } else if (value === "1:MAG") {
      return [
        { value: "1:1", label: "1:1" },
        { value: "1:M", label: "1:M" },
      ];
    } else {
      return [];
    }
  };

  const matchingBucketOptions = [
    { value: "MATCHED", label: "MATCHED" },
    { value: "SUGGESTED", label: "SUGGESTED" },
  ];

  const deleteExecutionPair = (id) => {
    setLoading(true);
    const updatedPairExecution = reconInitialValues.pairExecution.filter(
      (item) => item.id !== id
    );
    reconInitialValues.pairExecution = updatedPairExecution;
    setSelectedData((prevState) => ({
      ...prevState,
      pairExecution: reconInitialValues?.pairExecution,
    }));
    setLoading(false);
  };

  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  // const handleDragSort = () => {
  //   let _pairExecution = [...reconInitialValues.pairExecution];
  //   const draggedItem = _pairExecution.splice(dragItem.current, 1)[0];
  //   _pairExecution.splice(dragOverItem.current, 0, draggedItem);
  //   dragItem.current = null;
  //   dragOverItem.current = null;
  //   reconInitialValues.pairExecution = _pairExecution;
  //   setSelectedData((prevState) => ({
  //     ...prevState,
  //     pairExecution: reconInitialValues?.pairExecution,
  //   }));
  // };

  const handleMoveDataSource = () => {
    const newDataSources = [...pairData?.workflow];
    const movedItem = newDataSources.splice(dragItem.current, 1)[0];
    newDataSources.splice(dragOverItem.current, 0, movedItem);
    dragItem.current = null;
    dragOverItem.current = null;
    setPairData((prevState) => ({
      ...prevState,
      workflow: newDataSources,
    }));
  };
  const updatePair = useCallback(async (values) => {
    setLoading(true);
    const updatedPairExecution = (values?.pairExecution || []).map(
      (item, index) => {
        const isRule = item?.matchingType === "rule";
        const scriptBytes = isRule ? null : encodeBase64(item?.script);

        return {
          id: item?.id || null,
          name: item?.name,
          pairId: item?.pairId,
          reconType: item?.reconType,
          matchingRule: isRule ? JSON.stringify(item?.matchingRule) : null,
          matchingBucket: item?.matchingBucket,
          sequence: index,
          scriptBytes,
          invokeScript: !isRule,
          scriptId: isRule ? null : item?.scriptId || null,
          enabled: item?.enabled.toString() === "true",
        };
      }
    );
    let shouldExit = false;

    updatedPairExecution.forEach((item) => {
      if (
        (values?.matching === "1:1" && item?.reconType !== "1:1") ||
        (values?.matching === "1:M" &&
          item?.reconType !== "1:1" &&
          item?.reconType !== "1:M") ||
        (values?.matching === "M:1" &&
          item?.reconType !== "1:1" &&
          item?.reconType !== "1:M" &&
          item?.reconType !== "M:1") ||
        (values?.matching === "M:M" &&
          item?.reconType !== "1:1" &&
          item?.reconType !== "1:M" &&
          item?.reconType !== "M:1" &&
          item?.reconType !== "M:M") ||
        (values?.matching === "1:MAG" &&
          item?.reconType !== "1:1" &&
          item?.reconType !== "1:M")
      ) {
        Toast(
          `Please specify the appropriate rule type for ${item?.name} based on the selected relationship.`,
          "error"
        );
        shouldExit = true;
      }
    });

    if (shouldExit) {
      setLoading(false);
      return;
    }
    const updatedWorkflow = pairData?.workflow.map((step, index) => {
      if (step.id === selectedData?.id) {
        return {
          allowMMEntries:
            values?.allowMMEntries.toString() === "true" ? true : false,
          batchSize: values?.batchSize,
          chainId: values?.chainId ? JSON.stringify(values?.chainId) : null,
          dynamic: values?.dynamic.toString() === "true" ? true : false,
          enabled: values?.enabled.toString() === "true" ? true : false,
          fromDatasource: selectedData?.fromDatasource,
          fromDatasourceId: step?.fromDatasourceId,
          fromFetchCriteria: JSON.stringify(values?.fromFetchCriteria),
          id: selectedData?.id,
          matching: values?.matching,
          seq: index + 1,
          toDatasource: selectedData?.toDatasource,
          toDatasourceId: step?.toDatasourceId,
          toFetchCriteria: JSON.stringify(values?.toFetchCriteria),
          tolerance: values?.tolerance,
          pairExecution: updatedPairExecution,
          dsAInterfaceId: values?.dsAInterfaceId,
          dsAInterfaceFormatId: values?.dsAInterfaceFormatId,
          dsBInterfaceId: values?.dsBInterfaceId,
          dsBInterfaceFormatId: values?.dsBInterfaceFormatId,
        };
      }
      step.pairExecution = step?.pairExecution.map((item) =>
        item?.script
          ? (item = {
              ...item,
              script: null,
              scriptBytes: encodeBase64(item?.script),
              invokeScript: true,
            })
          : (item = { ...item, invokeScript: false })
      );
      step.seq = index + 1;
      return step;
    });
    const data = {
      data: {
        id: pairData?.id,
        reconName: pairData?.reconName,
        workflow: updatedWorkflow,
      },
    };

    try {
      const response = await axiosInstance.put(`/recon/pairs`, data);
      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
      }
      Toast(response?.message?.description, "success");
      const { id } = queryString.parse(window.location.search);
      getWorkflowsPairsData(id);
    } catch (err) {
      Toast(err?.response?.data?.message?.description, "error");
    }
    setLoading(false);
  });

  const deletePair = async (workflowIndex) => {
    setLoading(true);
    let updatedWorkflow = pairData?.workflow.filter(
      (item, index) => index !== workflowIndex
    );
    setPairData((prevState) => ({
      ...prevState,
      workflow: updatedWorkflow.map((step) => {
        step.pairExecution = step?.pairExecution.map((item) =>
          item?.script
            ? (item = {
                ...item,
                script: null,
                scriptBytes: encodeBase64(item?.script),
                invokeScript: true,
              })
            : (item = { ...item, invokeScript: false })
        );
        return step;
      }),
    }));
    const data = {
      data: {
        id: pairData?.id,
        reconName: pairData?.reconName,
        workflow: updatedWorkflow,
      },
    };
    try {
      const response = await axiosInstance.put(`/recon/pairs`, data);
      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
      }
      Toast(response?.message?.description, "success");
      const { id } = queryString.parse(window.location.search);
      setPairIndex(null);
      getWorkflowsPairsData(id);
    } catch (err) {
      Toast(err?.response?.data?.message?.description, "error");
    }
    setLoading(false);
  };

  const updateReconPair = async (
    dataSourceA,
    dataSourceB,
    dsaId,
    dsbId,
    pairId,
    dsAInterfaceFormatId,
    dsAInterfaceId,
    dsBInterfaceFormatId,
    dsBInterfaceId
  ) => {
    setLoading(true);
    const updatedWorkflow = pairData?.workflow.map((step) => {
      if (step.id === pairId) {
        step.fromDatasourceId = dsaId;
        step.toDatasourceId = dsbId;
        step.fromDatasource = dataSourceA;
        step.toDatasource = dataSourceB;
        step.dsAInterfaceFormatId = dsAInterfaceFormatId;
        step.dsAInterfaceId = dsAInterfaceId;
        step.dsBInterfaceFormatId = dsBInterfaceFormatId;
        step.dsBInterfaceId = dsBInterfaceId;
      }
      step.pairExecution = step?.pairExecution.map((item) =>
        item?.script
          ? (item = {
              ...item,
              script: null,
              scriptBytes: encodeBase64(item?.script),
              invokeScript: true,
            })
          : (item = { ...item, invokeScript: false })
      );
      return step;
    });
    setPairData((prevState) => ({
      ...prevState,
      workflow: updatedWorkflow,
    }));

    const data = {
      data: {
        id: pairData?.id,
        reconName: pairData?.reconName,
        workflow: updatedWorkflow,
      },
    };
    try {
      const response = await axiosInstance.put(`/recon/pairs`, data);
      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
      }
      Toast(response?.message?.description, "success");
      const { id } = queryString.parse(window.location.search);
      setReconPairId(null);
      getWorkflowsPairsData(id);
    } catch (err) {
      Toast(err?.response?.data?.message?.description, "error");
    }
    setLoading(false);
  };
  useEffect(() => {
    if (isRefresh) {
      getWorkflowsPairsData(pairData?.id);
      setIsRefresh(false);
    }
  }, [isRefresh, pairData?.id]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (formikFlag) {
        e.preventDefault();
        e.returnValue =
          "You have unsaved changes. Are you sure you want to leave?";
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formikFlag]);

  const fromDSFields = (fromDatasourceId, interfaceId, interfaceFormatId) => {
    axiosInstance
      .get(
        `/recon/wfm-config-fields?interfaceFormatId=${interfaceFormatId}&interfaceId=${interfaceId}&masterDatasourceId=${fromDatasourceId}`
      )
      .then((res) => {
        if (res?.message?.status == 200) {
          setDsaFields(res?.data?.fields?.map((x) => ({ value: x, label: x })));
        } else {
          setDsaFields([]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const toDSFields = (toDatasourceId, interfaceId, interfaceFormatId) => {
    axiosInstance
      .get(
        `/recon/wfm-config-fields?interfaceFormatId=${interfaceFormatId}&interfaceId=${interfaceId}&masterDatasourceId=${toDatasourceId}`
      )
      .then((res) => {
        if (res?.message?.status == 200) {
          setDsbFields(res?.data?.fields?.map((x) => ({ value: x, label: x })));
        } else {
          setDsbFields([]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    axiosInstance
      .get("/interfaces/datasources")
      .then((res) => {
        setMasterDataSource(
          res.data.datasources.map((x) => ({ ...x, label: x.datasource })) || []
        );
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
    axiosInstance
      .get(`/recon/wfm-config-metadata`)
      .then((res) => {
        if (res?.message?.status == 200) {
          setOperatorOptions({
            leftCriteriaOperators: res?.data?.leftCriteriaOperators?.map(
              (x) => ({ label: x.label, value: x.name, ...x })
            ),
            rightCriteriaOperators: res?.data?.rightCriteriaOperators?.map(
              (x) => ({ label: x.label, value: x.name, ...x })
            ),
            matchingRuleOperators: res?.data?.matchingRuleOperators?.map(
              (x) => ({ label: x.label, value: x.name, ...x })
            ),
            matchingRuleFunctions: res?.data?.matchingRuleFunctions?.map(
              (x) => ({ label: x.label, value: x.name, ...x })
            ),
            rightCriteriaAggregateOperators:
              res?.data?.rightCriteriaAggregateOperators?.map((x) => ({
                label: x.label,
                value: x.name,
                ...x,
              })),
          });
        } else {
          setOperatorOptions([]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  useEffect(() => {
    if (selectedData !== null && selectedData !== undefined) {
      setReconInitialValues({
        ...selectedData,
        fromFetchCriteria:
          selectedData?.fromFetchCriteria &&
          (JSON.parse(selectedData?.fromFetchCriteria) || ""),
        toFetchCriteria:
          selectedData?.toFetchCriteria &&
          (JSON.parse(selectedData?.toFetchCriteria) || ""),
        chainId:
          selectedData?.chainId &&
          (selectedData?.chainId === "{}"
            ? ""
            : JSON.parse(selectedData?.chainId) || ""),
        pairExecution: selectedData?.pairExecution?.map((item) => ({
          ...item,
          matchingRule: item?.matchingRule
            ? typeof item?.matchingRule === "string"
              ? JSON.parse(item?.matchingRule)
              : item?.matchingRule
            : null,
          script: item?.script ? item?.script : null,
          matchingType: item?.script ? "script" : "rule",
        })),
      });
      fromDSFields(
        selectedData?.fromDatasourceId,
        selectedData?.dsAInterfaceId ?? 0,
        selectedData?.dsAInterfaceFormatId ?? 0
      );
      toDSFields(
        selectedData?.toDatasourceId,
        selectedData?.dsBInterfaceId ?? 0,
        selectedData?.dsBInterfaceFormatId ?? 0
      );
      setAggregateFields(
        (selectedData?.toFetchCriteria &&
          JSON.parse(selectedData?.toFetchCriteria)?.aggregates?.map((x) => {
            return {
              label: "data." + x.resultset_col_name,
              value: "data." + x.resultset_col_name,
            };
          })) ||
          []
      );
    }
  }, [selectedData]);

  return (
    <>
      {loading && (
        <div
          className="spinner-center"
          style={{ top: "0%", left: "0%", zIndex: 2000 }}
        >
          <LoaderComponent />
        </div>
      )}
      <div className="d-flex justify-content-between">
        <Breadcrumbs data={breadCrumbState} history={props.history} />
      </div>
      <div
        className="row mt-3"
        style={{
          opacity: !loading ? "1" : "0.07",
        }}
      >
        <div
          style={{
            maxHeight: "none",
            height: !isMobile ? "60vh" : "",
            opacity: !loading ? "1" : "0.07",
          }}
          className="col-3 mt-0 info-tile value color-dark border-right-rounded"
        >
          <div>
            <div
              className="d-flex mt-2 mb-2 flex-row justify-content-between"
              style={{ width: "auto", marginLeft: "14px", marginRight: "80px" }}
            >
              <span className="font-weight-bold">FROM</span>
              <span className="font-weight-bold">TO</span>
            </div>
            {pairData?.workflow?.map((dataSource, index) => (
              <div className="container-flu" key={dataSource.id}>
                <div
                  className={`row reportList px-1 my-0 ${
                    isActive && selectedData?.id === dataSource.id
                      ? " background-secondary"
                      : ""
                  }`}
                  key={dataSource.id}
                  style={{
                    color:
                      isActive && selectedData?.id === dataSource.id
                        ? "#1196c2"
                        : "",
                    width: "auto",
                    paddingBlock: "10px",
                  }}
                  onClick={() => handleSelected(dataSource)}
                >
                  <div
                    className="col-sm-1 p-0"
                    key={dataSource.id}
                    draggable
                    onDragStart={(e) => (dragItem.current = index)}
                    onDragEnter={(e) => (dragOverItem.current = index)}
                    onDragEnd={handleMoveDataSource}
                    onDragOver={(e) => e.preventDefault()}
                  >
                    <i class="fa fa-sort" aria-hidden="true"></i>
                  </div>
                  <div className="col-sm-3 px-0">
                    {dataSource.fromDatasource}
                  </div>
                  <div className="col-sm-1">{">"}</div>
                  <div className="col-sm-4 px-0">{dataSource.toDatasource}</div>
                  <div className="col-sm-1 d-flex justify-content-center">
                    {" "}
                    <i
                      class="fa fa-pencil"
                      aria-hidden="true"
                      onClick={() => {
                        setShowEditPairModal(true);
                        setReconPairId(dataSource.id);
                      }}
                    ></i>
                    <i
                      class="fa fa-trash text-danger"
                      aria-hidden="true"
                      style={{ paddingInline: "7px" }}
                      onClick={() => {
                        setPairIndex(index);
                        setShowDeletePairModal(true);
                      }}
                    ></i>
                  </div>
                </div>
              </div>
            ))}
            <div className="d-flex justify-content-center mt-3">
              <ShowForPermission permission="clk_workflow_pair_add">
                <ButtonTransparent
                  onClick={() => setShowModal(true)}
                  title={"Add Pair"}
                  icon={AddIcon}
                />
              </ShowForPermission>
            </div>
          </div>
        </div>
        {pairData?.workflow?.length > 0 && (
          <div className="col-9">
            <div className="col-12">
              <Formik
                initialValues={reconInitialValues}
                enableReinitialize={true}
                validationSchema={ReconValidationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  updatePair(values);
                }}
              >
                {({ errors, touched, values, setFieldValue, handleSubmit }) => (
                  <Form id="add-recon" className="form" onSubmit={handleSubmit}>
                    <div className="d-flex flex-wrap align-items-center">
                      <div className="offset-10 col-2">
                        <ButtonBasic
                          title="Save Pair"
                          type="submit"
                          onClick={handleSubmit}
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-wrap align-items-center mt-3">
                      <div className="col-2">
                        <div className="workflowModalTitle">Relationship</div>
                        <Select
                          options={matchingOptions}
                          name={"relationship"}
                          value={
                            matchingOptions
                              ? matchingOptions.find(
                                  (option) => option.value === values.matching
                                )
                              : ""
                          }
                          onChange={(e) => {
                            if (
                              e.value !== "1:M" &&
                              e.value !== "M:M" &&
                              e.value !== "M:1"
                            ) {
                              setFieldValue("fromFetchCriteria", {
                                fields: values?.fromFetchCriteria?.fields,
                              });
                            } else {
                              setFieldValue("fromFetchCriteria", {
                                ...values?.fromFetchCriteria,
                                grouping_fields: [],
                              });
                            }
                            setFieldValue("matching", e.value);
                          }}
                          placeholder={"Select"}
                        />
                        <ErrorMessage
                          component={() => (
                            <div style={errorStyling}>{errors.matching}</div>
                          )}
                          name="matching"
                        />
                      </div>
                      <div className="col-2">
                        <div className="workflowModalTitle">
                          Missing Tolerance
                        </div>
                        <Field
                          className={`form-control rs-input`}
                          type="text"
                          placeholder="Tolerance"
                          name="tolerance"
                        />
                        <ErrorMessage
                          component={() => (
                            <div style={errorStyling}>{errors.tolerance}</div>
                          )}
                          name="tolerance"
                        />
                      </div>
                      <div className="col-2">
                        <div className="workflowModalTitle">Batch Size</div>
                        <Field
                          className={`form-control rs-input
                          }`}
                          type="text"
                          placeholder="Batch Size"
                          name="batchSize"
                        />
                        <ErrorMessage
                          component={() => (
                            <div style={errorStyling}>{errors.batchSize}</div>
                          )}
                          name="batchSize"
                        />
                      </div>
                      <div className="col-2">
                        <div className="workflowModalTitle">Dynamic</div>
                        <div className="form-check">
                          <Field
                            className="form-check-input"
                            type="radio"
                            name="dynamic"
                            value="true"
                            checked={
                              values?.dynamic === "true" ||
                              values?.dynamic === true
                            }
                          />

                          <label className="form-check-label">True</label>
                        </div>

                        <div className="form-check">
                          <Field
                            className="form-check-input"
                            type="radio"
                            name="dynamic"
                            value="false"
                            checked={
                              values?.dynamic === "false" ||
                              values?.dynamic === false
                            }
                          />
                          <label className="form-check-label">False</label>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="workflowModalTitle">
                          Allow MM Entries
                        </div>
                        <div className="form-check">
                          <Field
                            className="form-check-input"
                            type="radio"
                            name="allowMMEntries"
                            value="true"
                            checked={
                              values?.allowMMEntries === true ||
                              values?.allowMMEntries === "true"
                            }
                          />

                          <label className="form-check-label">True</label>
                        </div>

                        <div className="form-check">
                          <Field
                            className="form-check-input"
                            type="radio"
                            name="allowMMEntries"
                            value="false"
                            checked={
                              values?.allowMMEntries === false ||
                              values?.allowMMEntries === "false"
                            }
                          />
                          <label className="form-check-label">False</label>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="workflowModalTitle">Enabled</div>
                        <div className="form-check">
                          <Field
                            className="form-check-input"
                            type="radio"
                            name="enabled"
                            value="true"
                            checked={
                              values?.enabled === "true" ||
                              values?.enabled === true
                            }
                          />

                          <label className="form-check-label">True</label>
                        </div>

                        <div className="form-check">
                          <Field
                            className="form-check-input"
                            type="radio"
                            name="enabled"
                            value="false"
                            checked={
                              values?.enabled === "false" ||
                              values?.enabled === false
                            }
                          />
                          <label className="form-check-label">False</label>
                        </div>
                      </div>
                    </div>
                    <div
                      className="background-primary mt-3 p-3"
                      style={{ borderRadius: "10px" }}
                    >
                      <Tabs
                        defaultActiveKey="from"
                        id="uncontrolled-tab-example"
                      >
                        <Tab eventKey="from" title="DSA Fetch Criteria">
                          <DSA
                            values={values}
                            setFieldValue={setFieldValue}
                            dsaFields={dsaFields}
                            dsbFields={dsbFields}
                            operatorOptions={operatorOptions}
                          />
                        </Tab>

                        <Tab eventKey="to" title="DSB Fetch Criteria">
                          <DSB
                            values={values}
                            setFieldValue={setFieldValue}
                            dsaFields={dsaFields}
                            dsbFields={dsbFields}
                            aggregateFields={aggregateFields}
                            setAggregateFields={setAggregateFields}
                            operatorOptions={operatorOptions}
                          />
                        </Tab>
                        <Tab eventKey="chain" title="Chain ID">
                          <ChainID
                            values={values}
                            setFieldValue={setFieldValue}
                            dsaFields={dsaFields}
                            dsbFields={dsbFields}
                          />
                        </Tab>
                      </Tabs>
                    </div>
                    <div className="p-3">
                      <div className="mt-5 d-flex flex-wrap align-items-center">
                        <div className="col-3">
                          {/* Show title matching rules */}
                          <div className="text-bright">Matching Rules</div>
                        </div>
                        <div className="col-9">
                          <div className="float-right">
                            <ButtonTransparent
                              onClick={() => setShowPairModal(true)}
                              title={"Add Match Rule"}
                              icon={AddIcon}
                              border
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mb-5">
                        {values?.pairExecution?.length > 0 ? (
                          values?.pairExecution?.map((el, index) => (
                            <div key={index}>
                              <CustomAccordion
                                title={el.name}
                                onRemove={() => {
                                  setPairId(el.id);
                                  setShowDeleteModal(true);
                                }}
                                defaultActiveKey={false}
                                draggable
                                onDragStart={(e) => (dragItem.current = index)}
                                onDragEnter={(e) =>
                                  (dragOverItem.current = index)
                                }
                                //onDragEnd={handleDragSort}
                                onDragOver={(e) => e.preventDefault()}
                                headerStyle={{
                                  backgroundColor: "#138ab2",
                                  color: "white",
                                }}
                                bodyClasses="background-secondary"
                              >
                                <div className="d-flex flex-wrap align-items-center">
                                  <div className="col-3">
                                    <div className="workflowModalTitle">
                                      Rule Name
                                    </div>
                                    <Field
                                      className="form-control rs-input highlight"
                                      type="text"
                                      placeholder="Rule Name"
                                      name={`pairExecution[${index}].name`}
                                    />
                                    <ErrorMessage
                                      component={() => (
                                        <div style={errorStyling}>
                                          {errors.pairExecution &&
                                            errors.pairExecution[index] &&
                                            errors.pairExecution[index].name}
                                        </div>
                                      )}
                                      name={`pairExecution[${index}].name`}
                                    />
                                  </div>
                                  <div className="col-2">
                                    <div className="workflowModalTitle">
                                      Rule Type
                                    </div>
                                    <Select
                                      options={ruleTypeOptions(
                                        values?.matching
                                      )}
                                      name={"Rule Type"}
                                      value={
                                        ruleTypeOptions(values?.matching)
                                          ? ruleTypeOptions(
                                              values?.matching
                                            )?.find(
                                              (option) =>
                                                option.value ===
                                                values.pairExecution[index]
                                                  .reconType
                                            )
                                          : ""
                                      }
                                      onChange={(e) =>
                                        setFieldValue(
                                          `pairExecution[${index}].reconType`,
                                          e.value
                                        )
                                      }
                                      placeholder={"Select"}
                                    />
                                    <ErrorMessage
                                      component={() => (
                                        <div style={errorStyling}>
                                          {errors.pairExecution &&
                                            errors.pairExecution[index] &&
                                            errors.pairExecution[index]
                                              .reconType}
                                        </div>
                                      )}
                                      name={`pairExecution[${index}].reconType`}
                                    />
                                  </div>
                                  <div className="col-3">
                                    <div className="workflowModalTitle">
                                      Matching Bucket
                                    </div>
                                    <Select
                                      options={matchingBucketOptions}
                                      name={"Matching Bucket"}
                                      value={
                                        matchingBucketOptions
                                          ? matchingBucketOptions.find(
                                              (option) =>
                                                option.value ===
                                                values?.pairExecution[index]
                                                  ?.matchingBucket
                                            )
                                          : ""
                                      }
                                      onChange={(e) =>
                                        setFieldValue(
                                          `pairExecution[${index}].matchingBucket`,
                                          e.value
                                        )
                                      }
                                      placeholder={"Select"}
                                    />
                                    <ErrorMessage
                                      component={() => (
                                        <div style={errorStyling}>
                                          {errors.pairExecution &&
                                            errors.pairExecution[index] &&
                                            errors.pairExecution[index]
                                              .matchingBucket}
                                        </div>
                                      )}
                                      name={`pairExecution[${index}].matchingBucket`}
                                    />
                                  </div>
                                  <div className="col-2">
                                    <div className="workflowModalTitle">
                                      Enabled
                                    </div>
                                    <div className="form-check">
                                      <Field
                                        className="form-check-input"
                                        type="radio"
                                        name={`pairExecution[${index}].enabled`}
                                        value="true"
                                        checked={
                                          el?.enabled === true ||
                                          el?.enabled === "true"
                                        }
                                      />
                                      <label className="form-check-label">
                                        True
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <Field
                                        className="form-check-input"
                                        type="radio"
                                        name={`pairExecution[${index}].enabled`}
                                        value="false"
                                        checked={
                                          el?.enabled === false ||
                                          el?.enabled === "false"
                                        }
                                      />
                                      <label className="form-check-label">
                                        False
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="col-12 background-primary mt-3 p-3"
                                  style={{ borderRadius: "10px" }}
                                >
                                  <Tabs
                                    id="matching-rule-tab"
                                    activeKey={
                                      values.pairExecution[index].matchingType
                                    }
                                    onSelect={(k) => {
                                      setFieldValue(
                                        `pairExecution[${index}].matchingType`,
                                        k
                                      );
                                    }}
                                  >
                                    <Tab eventKey="rule" title="Matching Rule">
                                      <MatchingRule
                                        values={values}
                                        index={index}
                                        el={el}
                                        dsaFields={dsaFields}
                                        dsbFields={
                                          aggregateFields?.length > 0
                                            ? [...dsbFields, ...aggregateFields]
                                            : dsbFields
                                        }
                                        operatorOptions={operatorOptions}
                                        setFieldValue={setFieldValue}
                                      />
                                    </Tab>

                                    <Tab
                                      eventKey="script"
                                      title="Matching Script"
                                    >
                                      <div className="col-12">
                                        <Field
                                          name={`pairExecution[${index}].script`}
                                        >
                                          {({ field, form }) => (
                                            <div className="code-editor-container">
                                              <textarea
                                                {...field}
                                                className="code-editor-textarea"
                                                spellCheck="false"
                                                value={field.value || ""}
                                                onKeyDown={(e) => {
                                                  if (e.key === "Tab") {
                                                    e.preventDefault();
                                                    const {
                                                      selectionStart,
                                                      selectionEnd,
                                                      value,
                                                    } = e.target;
                                                    const newValue =
                                                      value.substring(
                                                        0,
                                                        selectionStart
                                                      ) +
                                                      "\t" +
                                                      value.substring(
                                                        selectionEnd
                                                      );
                                                    form.setFieldValue(
                                                      field.name,
                                                      newValue
                                                    );
                                                    setTimeout(() => {
                                                      e.target.selectionStart =
                                                        e.target.selectionEnd =
                                                          selectionStart + 1;
                                                    }, 0);
                                                  }
                                                }}
                                              />
                                            </div>
                                          )}
                                        </Field>
                                        <ErrorMessage
                                          component={() => (
                                            <div style={errorStyling}>
                                              {errors.pairExecution &&
                                                errors.pairExecution[index] &&
                                                errors.pairExecution[index]
                                                  .script}
                                            </div>
                                          )}
                                          name={`pairExecution[${index}].script`}
                                        />
                                      </div>
                                    </Tab>
                                  </Tabs>
                                </div>
                              </CustomAccordion>
                            </div>
                          ))
                        ) : (
                          <div className="text-center mt-3">
                            No Matching Rule Found
                          </div>
                        )}
                      </div>
                    </div>
                    <FormikConsumer>
                      {(formik) => {
                        setFormikFlag(formik.dirty);
                        return (
                          <LeavingModal
                            when={formik.dirty}
                            navigate={(path) => {
                              history.push(path);
                            }}
                            shouldBlockNavigation={() => {
                              if (formik.dirty) {
                                return true;
                              }
                              return false;
                            }}
                          />
                        );
                      }}
                    </FormikConsumer>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </div>
      <div className="col-md-12">
        <Modal
          backdrop={true}
          size="xl"
          show={showPairModal}
          onHide={() => setShowPairModal(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <h3 className="color-dark">ADD Match Rule</h3>
          </Modal.Header>
          <Modal.Body scrollable="true">
            <PairExecutionModal
              setShowPairModal={setShowPairModal}
              addExecutionPair={addExecutionPair}
              matching={selectedData?.matching}
              dsaFields={dsaFields}
              dsbFields={
                aggregateFields?.length > 0
                  ? [...dsbFields, ...aggregateFields]
                  : dsbFields
              }
              operatorOptions={operatorOptions}
            />
          </Modal.Body>
        </Modal>
      </div>
      <ConfirmationModal
        isShow={showDeleteModal}
        title={`Delete Match Rule`}
        message={`Are you sure you want to delete this Match Rule?`}
        hideModal={() => {
          setShowDeleteModal(false);
        }}
        confirmAction={() => {
          deleteExecutionPair(pairId);
          setShowDeleteModal(false);
        }}
      />
      <ConfirmationModal
        isShow={showDeletePairModal}
        title={`Delete Recon Pair`}
        message={`Are you sure you want to delete this Recon Pair?`}
        hideModal={() => {
          setShowDeletePairModal(false);
        }}
        confirmAction={() => {
          deletePair(pairIndex);
          setShowDeletePairModal(false);
        }}
      />
      <AddPairModal
        Recon={{ id: pairData?.id, name: pairData?.reconName }}
        showModal={showModal}
        setShowModal={setShowModal}
        setIsRefresh={setIsRefresh}
      />
      <Modal
        backdrop={true}
        size="md"
        show={showEditPairModal}
        onHide={() => setShowEditPairModal(false)}
      >
        <Modal.Header closeButton>
          <h3 className="color-dark">Edit Recon Pair</h3>
        </Modal.Header>
        <Modal.Body>
          <ReconModal
            setShowModal={setShowEditPairModal}
            masterDataSource={masterDataSource}
            pairId={reconPairId}
            updateReconPair={updateReconPair}
            dsA={{
              dsaId: pairData?.workflow?.find((step) => step.id === reconPairId)
                ?.fromDatasourceId,
              dsAInterfaceId: pairData?.workflow?.find(
                (step) => step.id === reconPairId
              )?.dsAInterfaceId,
              dsAInterfaceFormatId: pairData?.workflow?.find(
                (step) => step.id === reconPairId
              )?.dsAInterfaceFormatId,
            }}
            dsB={{
              dsbId: pairData?.workflow?.find((step) => step.id === reconPairId)
                ?.toDatasourceId,
              dsBInterfaceId: pairData?.workflow?.find(
                (step) => step.id === reconPairId
              )?.dsBInterfaceId,
              dsBInterfaceFormatId: pairData?.workflow?.find(
                (step) => step.id === reconPairId
              )?.dsBInterfaceFormatId,
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WorkflowManagerPairs;
