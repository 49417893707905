import React, { useState } from "react";
import { Accordion, Card, Button } from "react-bootstrap";

const CustomAccordion = ({
  title,
  children,
  defaultActiveKey,
  onRemove,
  onEdit,
  showRemoveIcon = true,
  showDragIcon = true,
  showEditIcon = false,
  draggable = false,
  onDragStart = () => {},
  onDrop = () => {},
  onDragOver = () => {},
  headerStyle,
  bodyClasses = "",
}) => {
  const [activeKey, setActiveKey] = useState(defaultActiveKey);

  const handleToggle = () => {
    setActiveKey(!activeKey);
  };

  return (
    <Accordion activeKey={activeKey} className="pt-3">
      <Card>
        <Card.Header
          draggable={draggable}
          onDragStart={onDragStart}
          onDrop={onDrop}
          onDragOver={onDragOver}
          style={{ ...headerStyle }}
          className={
            headerStyle?.hasOwnProperty("background") ||
            headerStyle?.hasOwnProperty("backgroundColor")
              ? ""
              : "background-secondary"
          }
        >
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey={activeKey}
            onClick={() => handleToggle()}
            className="p-0"
            style={{
              textDecoration: "none",
              boxShadow: "none",
              color: headerStyle?.color || "#1196c2",
            }}
          >
            {activeKey ? (
              <i className="fa fa-minus-circle" aria-hidden="true"></i>
            ) : (
              <i className="fa fa-plus-circle" aria-hidden="true"></i>
            )}
            {"  "}
            {title}
          </Accordion.Toggle>
          {showRemoveIcon && (
            <Button
              className="float-right p-0"
              style={{
                textDecoration: "none",
                boxShadow: "none",
                cursor: "pointer",
              }}
              onClick={onRemove}
              variant="link"
            >
              <i
                className="fa fa-trash"
                style={{
                  color:
                    "#ff0000",
                }}
                aria-hidden="true"
              ></i>
            </Button>
          )}
          {showDragIcon && (
            <Button
              className="float-right p-0 mr-2"
              style={{
                textDecoration: "none",
                boxShadow: "none",
                color: headerStyle?.color || "#1196c2",
              }}
              variant="link"
            >
              <i className="fa fa-arrows" aria-hidden="true"></i>
            </Button>
          )}
          {showEditIcon && (
            <Button
              className="float-right p-0 mr-2"
              style={{
                textDecoration: "none",
                boxShadow: "none",
                color: headerStyle?.color || "#1196c2",
              }}
              onClick={onEdit}
              variant="link"
            >
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </Button>
          )}
        </Card.Header>
        <Accordion.Collapse eventKey={activeKey} in={activeKey}>
          <Card.Body className={bodyClasses + " p-1 background-primary"}>
            {children}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default CustomAccordion;
