export default function gaugeChart({ theme, loader, chartData }) {
  let noData = true;
  if (noData) {
    noData = chartData?.data?.total == null;
  }
  const chart = {
    title: {
      text:
        !loader && noData
          ? `No Data available for ${chartData.title} `
          : chartData.title,
      left: noData ? "center" : "left",
      top: noData ? "center" : "top",
      textStyle: {
        color: "#777",
        fontSize: "13px",
        fontFamily: "Montserrat Bold",
        overflow: "break",
        width: noData ? 200 : 400,
      },
    },
    tooltip: {
      trigger: "item",
      formatter: `Touchpoint Download Status<br>
      <span style="
  height: 10px;
  width: 10px;
  background-color: #37a4e8;
  border-radius: 50%;
  display: inline-block;
"></span> Ej File Received  \t  <b>${chartData?.data?.downloaded}<b/>
      `,
      boxShadow: "0 0 3px rgba(0, 0, 0, 0.3)",
      borderColor: "rgba(0, 0, 0, 0)",
    },
    toolbox: {
      show: true,
      showTitle: false, // hide the default text so they don't overlap each other
      feature: {
        saveAsImage: {
          show: true,
          title: "Save As Image",
        },
      },
    },
    series: [
      {
        name: "devices",
        type: "gauge",
        radius: "75%",
        min: 0,
        max: chartData?.data?.total,
        splitNumber: chartData?.data?.total > 5 ? 5 : chartData?.data?.total,
        progress: {
          show: true,
          width: 18,
          itemStyle: {
            color: {
              type: "linear",
              x: 1,
              y: 0,
              x2: 0,
              y2: 0,
              colorStops: [
                {
                  offset: 0,
                  color: "rgba(0,175,145,100%)", // color at 0% position
                },
                {
                  offset: 0.2,
                  color: "rgba(0,175,145,80%)", // color at 0% position
                },
                {
                  offset: 0.4,
                  color: "rgba(255,209,102,100%)", // color at 0% position
                },
                {
                  offset: 0.6,
                  color: "rgba(255,209,102,80%)", // color at 0% position
                },
                {
                  offset: 0.8,
                  color: "rgba(239,71,111,100%)", // color at 0% position
                },
                {
                  offset: 1,
                  color: "rgba(239,71,111,80%)", // color at 0% position
                },
              ],
            },
          },
        },
        axisLine: {
          lineStyle: {
            width: 18,
          },
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          length: 10,
          lineStyle: {
            width: 0,
            // color: 'transparent'
          },
        },
        axisLabel: {
          distance: 8,
          color: "#777",
          fontSize: 12,
          formatter: function (value) {
            return parseInt(value).toString();
          },
        },
        pointer: {
          itemStyle: {
            color: "#ccc",
          },
        },
        anchor: {
          show: true,
          size: 15,
          itemStyle: {
            borderWidth: 2,
            borderColor: theme === "light" ? "#fcfcfc" : "#1d1d1d",
            color: "#ccc",
          },
        },
        title: {
          show: true,
          color: "#777",
          offsetCenter: ["0%", "100%"],
          fontFamily: "Montserrat Medium",
          fontSize: 14,
        },
        detail: {
          valueAnimation: noData ? false : true,
          offsetCenter: [0, "75%"],
          fontFamily: "Montserrat Bold",
          fontSize: 34,
          color: "#ccc",
          formatter: () => chartData?.data?.total,
        },
        data: [
          {
            value: chartData?.data?.downloaded,
            name: "Devices",
          },
        ],
        animationDelay: function (idx) {
          return idx * 100;
        },
      },
    ],
  };
  return chart;
}
