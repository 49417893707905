import React from "react";
import "../../App.css";
import { Spinner } from "react-bootstrap";
import ReactECharts from "echarts-for-react";
import SingleInfoCard from "../../components/SingleInfoCard/SingleInfoCard";
import $ from "jquery";
import GoogleMapComponent from "../../containers/GoogleMap/GoogleMapComponent";
import ReconSchedule from "./ReconSchedule/ReconSchedule";
import axiosInstance from "../../utils/axiosInstance";
import SummaryDashboardStyle from "./SummaryDashboardStyle";
import moment from "moment";
import LoaderComponent from "../../components/Spinner/LoaderComponent";
import renderChartFunction from "../../utils/helpers/renderChartFilter";
import buttonPerChecker from "../../utils/buttonsPermissionsChecker";
import pieChart from "../../components/Charts/pieChart";
import barChart from "../../components/Charts/barChart";
import twoLinesChart from "../../components/Charts/twoLinesChart";
import gaugeChart from "../../components/Charts/gaugeChart";
import barWithLineChart from "../../components/Charts/barWithLineChart";
import withDateTime from "../../utils/timedatehoc";
import { colorPalette, DASHBOARD_PALETTE } from "../../constants/ColorPalette";
class SummaryDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // colorPalette: ["#00AF91", "#EF476F", "#FFD166", "#118AB2"],
      // DASHBOARD_PALETTE: ["#9A77B2", "#0077A2", "#FF6F61","#7F8C8D","#FFC300"],
      // YELLOW_PALETTE:["#FFCA08","#F8931D","#CE8D3E","#EC7016","#9C6A6A"],
      // PositiveColorPalette: ["#00AF91", "#FFD166", "#118AB2", "#FF8C00"],
      // negativeColorPalette: ["#EF476F", "#52575D", "#845ec2", "#142D4C"],
      previousDaySumOptionLoading: true,
      previousDaySplitOptionsLoading: true,
      unmatchedCategorizationLoading: true,
      peakTimeLoading: true,
      downloadStatusLoading: true,
      weeklyReconciliationStatusLoading: true,
      reconScheduleLoader: true,
      debouncedClickHandler: null,
      interfaceArrayNew: [],
      previousDaySumData: {
        title: "",
        data: [],
      },

      unmatchedCategorizationData: {
        title: "",
        data: [],
        label: [],
      },

      previousDaySplitData: {
        title: "",
        xAxis: {
          data: [],
        },
        series: [
          {
            data: [],
          },
          {
            data: [],
          },
          {
            data: [],
          },
          {
            data: [],
          },
        ],
      },

      weeklyReconciliationStatusData: {
        title: "",
        xAxis: {
          data: [],
        },
        series: [
          {
            data: [],
          },
          {
            data: [],
          },
          {
            data: [],
          },
          {
            data: [],
          },
        ],
      },

      gaugeData: {
        title: "",
        data: [],
      },

      peakTimeData: {
        title: "",
        xAxis: {
          data: [],
        },
        series: [
          {
            data: [],
          },
          {
            data: [],
          },
        ],
      },

      summaryKpiTiles: [],
      region1: true,
      region2: true,
      region3: true,
      region4: true,
      region5: true,
      region6: true,
      region7: true,
      region8: true,
      region9: true,

      regions: [
        {
          region: "Qatar",
          subRegion: [
            {
              region: "Doha",
              subRegion: {
                region: ["Ain Khalid", "Al Bida"],
              },
            },
          ],
        },
      ],
      businessDays: [
        {
          label: "T",
          value: "T",
          checked: false,
          isSearch: true,
        },
        {
          label: "T1",
          value: "T1",
          checked: true,
          isSearch: true,
        },
        {
          label: "T2",
          value: "T2",
          checked: false,
          isSearch: true,
        },
        {
          label: "T3",
          value: "T3",
          checked: false,
          isSearch: true,
        },
        {
          label: "T4",
          value: "T4",
          checked: false,
          isSearch: true,
        },
        {
          label: "T5",
          value: "T5",
          checked: false,
          isSearch: true,
        },
      ],
      reconScheduleData: [],
      exceptionSummaryMap: {
        title: "",
        chart: [],
      },
      interfaceGroupByDate: {},
      yesterdayDateCharts: moment().subtract(1, "days").format("YYYY-MM-DD"), //"2022-04-18" ;
    }; //state end
  }
  getData() {
    this.setState(
      {
        previousDaySumOptionLoading: true,
        previousDaySplitOptionsLoading: true,
        unmatchedCategorizationLoading: true,
        peakTimeLoading: true,
        downloadStatusLoading: true,
        weeklyReconciliationStatusLoading: true,
        reconScheduleLoader: true,
        interfaceGroupByDate: {},
      },
      async () => {
        await this.getPreviousDaySummary();
        await this.getSummaryKpiTiles();
        await this.getPeakTime();
        await this.getDownloadStatus();
        await this.getpastDayReconStatus();
        await this.getReconSchedule();
        await this.getExceptionMapData();
      }
    );
  }

  componentDidMount() {
    // Todo: get record by id
    // console.log(queryString.parse(this.props.location.search));
    $(".count-num").each(function () {
      var $this = $(this);
      $({ Counter: 0 }).animate(
        { Counter: $this.text() },
        {
          duration: 1000,
          easing: "swing",
          step: function () {
            $this.text(Math.ceil(this.Counter));
          },
        }
      );
    });

    this.getData();
    this.props.dateTime.setRefresh(() => () => {
      this.getData();
    });

    const debouncedClickHandler = this.debounce(this.clickHandler);
    this.setState({ debouncedClickHandler });
  }

  componentWillUnmount() {
    this.props.dateTime.setRefresh(() => () => {});
  }

  async getPreviousDaySummary() {
    try {
      const previousDaySumData = { data: [] },
        unmatchedCategorizationData = { label: [], data: [] },
        previousDaySplitData = {
          xAxis: { data: [] },
          series: [{ data: [] }, { data: [] }, { data: [] }, { data: [] }],
        },
        yesterdayDateCharts = this.state.yesterdayDateCharts;
      // matching summaryy
      try {
        const response = await axiosInstance.get(`charts/matching-summary`);
        const responseData = response.data;
        const formattedData = [
          { value: responseData.chart.matchedCount, name: "Matched" },
          { value: responseData.chart.unmatchedCount, name: "Unmatched" },
          { value: responseData.chart.missingCount, name: "Missing" },
          { value: responseData.chart.errorCount, name: "Error" },
        ];
        previousDaySumData.data = formattedData;
        previousDaySumData.title = responseData.header.title;
      } catch (error) {
        console.log("matching summary chart error", error);
      }

      // unmatched status
      try {
        const response = await axiosInstance.get(`charts/unmatched-status`);
        const previousDayunmatchedResponse = response.data;
        unmatchedCategorizationData.title =
          previousDayunmatchedResponse.header.title;

        previousDayunmatchedResponse.chart.map((chart) => {
          unmatchedCategorizationData.label.push(chart.label);
          if (chart.count === 0) {
            unmatchedCategorizationData.data.push({
              value: "",
              name: chart.label,
            });
          } else {
            unmatchedCategorizationData.data.push({
              value: chart.count,
              name: chart.label,
            });
          }
          return true;
        });
      } catch (error) {
        console.log("unmatched status chart error", error);
      }

      // previuous day split
      try {
        const response = await axiosInstance.get(`charts/matching-split`);
        const responseDataPreviousDaySplit = response.data;
        previousDaySplitData.title = responseDataPreviousDaySplit.header.title;

        for (let i = 0; i < responseDataPreviousDaySplit?.chart?.length; i++) {
          previousDaySplitData.xAxis.data.push(
            responseDataPreviousDaySplit.chart[i]?.channel
          );
          previousDaySplitData.series[0].data.push({
            value: responseDataPreviousDaySplit.chart[i]?.matchedCount,
          });
          previousDaySplitData.series[1].data.push({
            value: responseDataPreviousDaySplit.chart[i]?.unmatchedCount,
          });
          previousDaySplitData.series[2].data.push({
            value: responseDataPreviousDaySplit.chart[i]?.missingCount,
          });
          previousDaySplitData.series[3].data.push({
            value: responseDataPreviousDaySplit.chart[i]?.errorCount,
          });
        }
      } catch (error) {
        console.log("previousDaySplit error", error);
      }

      this.setState({
        previousDaySumData,
        previousDaySumOptionLoading: false,
        unmatchedCategorizationData,
        unmatchedCategorizationLoading: false,
        previousDaySplitData,
        previousDaySplitOptionsLoading: false,
      });
    } catch (err) {
      this.setState({
        previousDaySumOptionLoading: false,
        unmatchedCategorizationLoading: false,
        previousDaySplitOptionsLoading: false,
      });
    }
  }

  async getSummaryKpiTiles() {
    const yesterdayDateCharts = this.state.yesterdayDateCharts;
    try {
      let kpiTilesResponse = await axiosInstance.get(
        `/charts/summary-kpi-tiles`
      );
      this.setState({
        summaryKpiTiles: kpiTilesResponse.data.summaryKPITiles.chart,
      });
    } catch (err) {
      console.log("getSummaryKpiTiles err", err);
    }
  }

  async getPeakTime() {
    try {
      const peakTimeData = {
          xAxis: { data: [] },
          series: [{ data: [] }, { data: [] }],
        },
        yesterdayDateCharts = this.state.yesterdayDateCharts;
      const response = await axiosInstance.get(`/charts/peak-times`);
      peakTimeData.title = response.data.atmPeakTimes.header.title;

      const chartData = response.data.atmPeakTimes.chart;
      for (let i = 0; i < chartData.length; i++) {
        peakTimeData.xAxis.data.push(chartData[i].time);
        peakTimeData.series[0].data.push(chartData[i].totalCount);
        peakTimeData.series[1].data.push(chartData[i].totalCount);
      }
      this.setState({ peakTimeData, peakTimeLoading: false });
    } catch (err) {
      console.log("getPeakTime err ", err);
      this.setState({ peakTimeLoading: false });
    }
  }

  async getDownloadStatus() {
    try {
      const { yesterdayDateCharts } = this.state;
      const gaugeData = { data: {} };
      const response = await axiosInstance.get(
        `/charts/device-report-count?business_date=${yesterdayDateCharts}`
      );
      gaugeData.title = response.data.downloadStatus.header.title;
      gaugeData.data.total =
        response.data.downloadStatus?.chart?.numberOfDevices;
      gaugeData.data.downloaded =
        response.data.downloadStatus?.chart?.numberOfEjFileReceived;
      this.setState({ gaugeData, downloadStatusLoading: false });
    } catch (err) {
      console.log("getDownloadStatus err ", err);
      this.setState({ downloadStatusLoading: false });
    }
  }

  async getpastDayReconStatus() {
    try {
      let weeklyReconciliationStatusData = {
        title: "",
        xAxis: {
          data: [],
        },
        series: [
          {
            data: [],
          },
          {
            data: [],
          },
          {
            data: [],
          },
          {
            data: [],
          },
        ],
      };
      const { yesterdayDateCharts } = this.state;
      const response = await axiosInstance.get(
        `/charts/past-days-recon-status?selector=week`
      );
      weeklyReconciliationStatusData.title =
        response.data.pastDaysReconStatus.header.title;
      const chartData = response.data.pastDaysReconStatus.chart;
      for (let i = 0; i < chartData.length; i++) {
        weeklyReconciliationStatusData.xAxis.data.push(chartData[i].date);
        weeklyReconciliationStatusData.series[0].data.push(
          chartData[i].matchedCount
        );
        weeklyReconciliationStatusData.series[1].data.push(
          chartData[i].unmatchedCount
        );
        weeklyReconciliationStatusData.series[2].data.push(
          chartData[i].missingCount
        );
        weeklyReconciliationStatusData.series[3].data.push(
          chartData[i].errorCount
        );
      }
      this.setState({
        weeklyReconciliationStatusData,
        weeklyReconciliationStatusLoading: false,
      });
    } catch (err) {
      console.log("getpastDayReconStatus err ", err);
      this.setState({ weeklyReconciliationStatusLoading: false });
    }
  }

  async getExceptionMapData() {
    const yesterdayDateCharts = this.state.yesterdayDateCharts;
    try {
      let exceptionSummaryMap = { chart: [] };
      const response = await axiosInstance.get(`/charts/exceptions-map`);
      exceptionSummaryMap.title = response.data.exceptionsMap.header.title;
      exceptionSummaryMap.chart.push(response.data.exceptionsMap.chart);
      this.setState({ exceptionSummaryMap });
    } catch (err) {
      console.log("err exception summary map ", err);
    }
  }

  async getReconSchedule() {
    const yesterdayDateCharts = this.state.yesterdayDateCharts;
    try {
      const response = await axiosInstance.get(`/interfaces`);
      this.setState({
        ...this.state,
        interfaceArrayNew: response?.data?.interfaces,
      });

      this.setState({
        // reconScheduleData: recondScheduleFormatted,
        // interfaceGroupByDate: interfaceListed,
        reconScheduleLoader: false,
      });
    } catch (err) {
      console.log("err recon schedule api ", err);
      this.setState({ reconScheduleLoader: false });
    }
  }

  onRegionChangeHandler(e) {
    this.setState({ [e.target.name]: !e.target.value });
  }

  onBusinessDaysChange(e) {
    const { businessDays } = this.state;
    for (let i = 0; i < businessDays.length; i++) {
      if (businessDays[i].value === e.target.value) {
        businessDays[i].checked = !businessDays[i].checked;
        break;
      }
    }
    this.setState({ businessDays });
  }

  searchBusinessDays(e) {
    const { businessDays } = this.state;
    for (let i = 0; i < businessDays.length; i++) {
      if (businessDays[i].value.includes(e.target.value)) {
        businessDays[i].isSearch = true;
      } else {
        businessDays[i].isSearch = false;
      }
    }
    this.setState({ businessDays });
  }

  getLoader() {
    return (
      <div className="chart-loader">
        <Spinner animation="grow" variant="info" />
      </div>
    );
  }

  onChartClick = {
    click: (param) => {
      buttonPerChecker("clk_sum_dash_chart") &&
        renderChartFunction(param, this.props);
    },
  };

  debounce = (func) => {
    return (...args) => {
      func.apply(this, args);
    };
  };

  clickHandler = (param) => {
    if (!buttonPerChecker("clk_sum_dash_chart")) {
      return;
    }
    const seriesToId = {
      matched: 140001,
      unmatched: 140002,
      missing: 140003,
      error: 140008,
    };

    const channelToId = {
      atm: 160001,
      pos: 160002,
      digital: 160003,
      "1link": 160005,
    };

    const seriesId = seriesToId[param.seriesName.toLowerCase()];
    const channelId = channelToId[param.name.toLowerCase()];

    if (seriesId !== undefined && channelId !== undefined) {
      this.props.history.push({
        pathname: "/transaction-log",
        state: { data: { id: seriesId, channel: channelId } },
      });
    }
  };

  weeklyReconciliationEvent = (param) => {
    if (buttonPerChecker("clk_sum_dash_chart")) {
      const data = {};
      data.date = param.name;
      if (param.seriesName === "Matched") {
        data.id = 140001;
      } else if (param.seriesName === "Unmatched") {
        data.id = 140002;
      }
      this.props.history.push({
        pathname: "/transaction-log",
        state: { data },
      });
    }
  };

  guageChartEvent = (param) => {
    buttonPerChecker("clk_sum_dash_chart") &&
      this.props.history.push("/operations");
  };

  render() {
    var { theme } = this.props;
    const isMobile = this.props?.dateTime?.isMobile;
    const systemSettings = this.props?.dateTime?.settings?.find(
      (setting) => setting.id === "systemSettings"
    );
    const zoomLevel = systemSettings?.subType?.find(
      (setting) => setting.id === "mapDefaultZoomLevel"
    );
    const defaultLat = systemSettings?.subType?.find(
      (setting) => setting.id === "mapDefaultCentreLatitude"
    );
    const defaultLng = systemSettings?.subType?.find(
      (setting) => setting.id === "mapDefaultCentreLongitude"
    );

    const {
      // colorPalette,
      summaryKpiTiles,
      previousDaySumData,
      previousDaySumOptionLoading,
      previousDaySplitData,
      previousDaySplitOptionsLoading,
      unmatchedCategorizationData,
      unmatchedCategorizationLoading,
      weeklyReconciliationStatusData,
      weeklyReconciliationStatusLoading,
      gaugeData,
      exceptionSummaryMap,
      peakTimeData,
      downloadStatusLoading,
      peakTimeLoading,
      debouncedClickHandler,
    } = this.state;
    return (
      <>
        <SummaryDashboardStyle>
          <ReconSchedule
            interfaceGroupByDate={this.state.interfaceArrayNew}
            loading={this.state.reconScheduleLoader}
          />
          {/* Previous Day Summary Chart */}
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
              <div className="mt-3">
                <ReactECharts
                  className="matching-summary"
                  onEvents={this.onChartClick}
                  option={pieChart({
                    theme: theme,
                    colorPalette: colorPalette,
                    loader: previousDaySumOptionLoading,
                    chartData: previousDaySumData,
                    label: ["Matched", "Unmatched", "Missing", "Error"],
                    radius: "70%",
                  })}
                  style={{
                    textTransform: "capitalize",
                    height: "279px",
                    width: "100%",
                    background: theme === "light" ? "#fff" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  // loadingOption={this.getLoader}
                  loadingOption={{
                    text: "loading",
                    color: "#c23531",
                    textColor: "#000",
                    maskColor: `${
                      theme === "light"
                        ? "rgba(7, 6, 6, 0.1)"
                        : "rgba(7, 6, 6, 0.8)"
                    }`,
                    zlevel: 0,
                    fontSize: 12,
                    showSpinner: true,
                    spinnerRadius: 10,
                    lineWidth: 5,
                    fontWeight: "normal",
                    fontStyle: "normal",
                    fontFamily: "sans-serif",
                  }}
                  lazyUpdate={true}
                  showLoading={previousDaySumOptionLoading}
                  opts={{ renderer: "svg" }}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
              <div className="mt-3">
                <ReactECharts
                  className="matching-split"
                  onEvents={
                    debouncedClickHandler && {
                      click: debouncedClickHandler,
                    }
                  }
                  option={barChart({
                    theme: theme,
                    colorPalette: colorPalette,
                    loader: previousDaySplitOptionsLoading,
                    chartData: previousDaySplitData,
                    label: ["Matched", "Unmatched", "Missing", "Error"],
                  })}
                  style={{
                    textTransform: "capitalize",
                    height: "279px",
                    width: "100%",
                    background: theme === "light" ? "#fff" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  lazyUpdate={true}
                  showLoading={previousDaySplitOptionsLoading}
                  loadingOption={{
                    text: "loading",
                    color: "#c23531",
                    textColor: "#000",
                    maskColor: `${
                      theme === "light"
                        ? "rgba(7, 6, 6, 0.1)"
                        : "rgba(7, 6, 6, 0.8)"
                    }`,
                    zlevel: 0,

                    // Font size. Available since `v4.8.0`.
                    fontSize: 12,
                    // Show an animated "spinner" or not. Available since `v4.8.0`.
                    showSpinner: true,
                    // Radius of the "spinner". Available since `v4.8.0`.
                    spinnerRadius: 10,
                    // Line width of the "spinner". Available since `v4.8.0`.
                    lineWidth: 5,
                    // Font thick weight. Available since `v5.0.1`.
                    fontWeight: "normal",
                    // Font style. Available since `v5.0.1`.
                    fontStyle: "normal",
                    // Font family. Available since `v5.0.1`.
                    fontFamily: "sans-serif",
                  }}
                  opts={{ renderer: "svg" }}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
              <div className="mt-3">
                <ReactECharts
                  className="unmatched-status"
                  onEvents={this.onChartClick}
                  option={pieChart({
                    theme: theme,
                    colorPalette: DASHBOARD_PALETTE,
                    loader: unmatchedCategorizationLoading,
                    chartData: unmatchedCategorizationData,
                    label: unmatchedCategorizationData.label,
                    radius: "70%",
                  })}
                  style={{
                    textTransform: "capitalize",
                    height: "279px",
                    width: "100%",
                    background: theme === "light" ? "#fff" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  lazyUpdate={true}
                  showLoading={this.state.unmatchedCategorizationLoading}
                  loadingOption={{
                    text: "loading",
                    color: "#c23531",
                    textColor: "#000",
                    maskColor: `${
                      theme === "light"
                        ? "rgba(7, 6, 6, 0.1)"
                        : "rgba(7, 6, 6, 0.8)"
                    }`,
                    zlevel: 0,
                    fontSize: 12,
                    showSpinner: true,
                    spinnerRadius: 10,
                    lineWidth: 5,
                    fontWeight: "normal",
                    fontStyle: "normal",
                    fontFamily: "sans-serif",
                  }}
                  opts={{ renderer: "svg" }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            {summaryKpiTiles?.map((kpi, i) => (
              <div className="col-sm-6 col-lg-3" key={i}>
                <SingleInfoCard
                  id={kpi.title}
                  title={kpi.title === null ? "" : kpi.title}
                  value={kpi.totalCount === null ? "0" : kpi.totalCount}
                  per={
                    kpi.trendPercentage === null
                      ? "0%"
                      : kpi.trendPercentage + "%"
                  }
                  perDown={kpi.trendType === "decrease" ? true : false}
                />
              </div>
            ))}
          </div>
          <div className="row">
            <div className="col-12">
              <div className="mt-3">
                <ReactECharts
                  className="weekly-reconciliation-status"
                  key={weeklyReconciliationStatusLoading}
                  onEvents={{
                    click: this.weeklyReconciliationEvent,
                  }}
                  option={twoLinesChart({
                    theme: theme,
                    colorPalette: colorPalette,
                    loader: weeklyReconciliationStatusLoading,
                    chartData: weeklyReconciliationStatusData,
                    label: ["Matched", "Unmatched", "Missing", "Error"],
                    isMobile: isMobile,
                  })}
                  style={{
                    textTransform: "capitalize",
                    height: "300px",
                    width: "100%",
                    background: theme === "light" ? "#fff" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  lazyUpdate={true}
                  showLoading={this.state.weeklyReconciliationStatusLoading}
                  loadingOption={{
                    text: "loading",
                    color: "#c23531",
                    textColor: "#000",
                    maskColor: `${
                      theme === "light"
                        ? "rgba(7, 6, 6, 0.1)"
                        : "rgba(7, 6, 6, 0.8)"
                    }`,
                    zlevel: 0,

                    // Font size. Available since `v4.8.0`.
                    fontSize: 12,
                    // Show an animated "spinner" or not. Available since `v4.8.0`.
                    showSpinner: true,
                    // Radius of the "spinner". Available since `v4.8.0`.
                    spinnerRadius: 10,
                    // Line width of the "spinner". Available since `v4.8.0`.
                    lineWidth: 5,
                    // Font thick weight. Available since `v5.0.1`.
                    fontWeight: "normal",
                    // Font style. Available since `v5.0.1`.
                    fontStyle: "normal",
                    // Font family. Available since `v5.0.1`.
                    fontFamily: "sans-serif",
                  }}
                  opts={{ renderer: "svg" }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-lg-6">
              <div className="mt-3">
                <ReactECharts
                  className="touchpoint-guage-chart"
                  onEvents={{ click: this.guageChartEvent }}
                  option={gaugeChart({
                    theme: theme,
                    chartData: gaugeData,
                    loader: downloadStatusLoading,
                  })}
                  lazyUpdate={true}
                  showLoading={this.state.downloadStatusLoading}
                  style={{
                    textTransform: "capitalize",
                    height: "310px",
                    width: "100%",
                    background: theme === "light" ? "#fff" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  loadingOption={{
                    text: "loading",
                    color: "#c23531",
                    textColor: "#000",
                    maskColor: `${
                      theme === "light"
                        ? "rgba(7, 6, 6, 0.1)"
                        : "rgba(7, 6, 6, 0.8)"
                    }`,
                    zlevel: 0,

                    // Font size. Available since `v4.8.0`.
                    fontSize: 12,
                    // Show an animated "spinner" or not. Available since `v4.8.0`.
                    showSpinner: true,
                    // Radius of the "spinner". Available since `v4.8.0`.
                    spinnerRadius: 10,
                    // Line width of the "spinner". Available since `v4.8.0`.
                    lineWidth: 5,
                    // Font thick weight. Available since `v5.0.1`.
                    fontWeight: "normal",
                    // Font style. Available since `v5.0.1`.
                    fontStyle: "normal",
                    // Font family. Available since `v5.0.1`.
                    fontFamily: "sans-serif",
                  }}
                  opts={{ renderer: "svg" }}
                />
              </div>
            </div>
            <div className="col-sm-12 col-lg-6">
              <div className="mt-3" style={{ borderRadius: "17px" }}>
                {exceptionSummaryMap && exceptionSummaryMap.chart.length > 0 ? (
                  <div
                    style={{
                      height: "310px",
                      width: "100%",
                      background: theme === "light" ? "#fff" : "#1d1d1d",
                      padding: "15px",
                      borderRadius: "17px",
                      color: "#ccc",
                    }}
                  >
                    <h5
                      style={{
                        color: "#777",
                        fontSize: "14px",
                        fontFamily: "Montserrat Bold",
                      }}
                    >
                      {exceptionSummaryMap.title}
                    </h5>
                    <GoogleMapComponent
                      isMarkerShown={true}
                      zoom={parseInt(zoomLevel?.selectedValue)}
                      lat={parseFloat(defaultLat?.selectedValue)}
                      lng={parseFloat(defaultLng?.selectedValue)}
                      exceptionSummaryMap={exceptionSummaryMap?.chart}
                      settings={this.props?.dateTime?.systemSettings}
                      infoWindowToggle={true}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      height: "310px",
                      width: "100%",
                    }}
                  >
                    <div className="spinner-center">
                      <LoaderComponent />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* Peak Time */}
          <div className="row">
            <div className="col-12">
              <div className="mt-3">
                <ReactECharts
                  className="peak-time-summary"
                  onEvents={this.onChartClick}
                  option={barWithLineChart({
                    theme: theme,
                    chartData: peakTimeData,
                    loader: peakTimeLoading,
                  })}
                  lazyUpdate={true}
                  showLoading={peakTimeLoading}
                  style={{
                    textTransform: "capitalize",
                    height: "279px",
                    width: "100%",
                    background: theme === "light" ? "#fff" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  loadingOption={{
                    text: "loading",
                    color: "#c23531",
                    textColor: "#000",
                    maskColor: `${
                      theme === "light"
                        ? "rgba(7, 6, 6, 0.1)"
                        : "rgba(7, 6, 6, 0.8)"
                    }`,
                    zlevel: 0,

                    // Font size. Available since `v4.8.0`.
                    fontSize: 12,
                    // Show an animated "spinner" or not. Available since `v4.8.0`.
                    showSpinner: true,
                    // Radius of the "spinner". Available since `v4.8.0`.
                    spinnerRadius: 10,
                    // Line width of the "spinner". Available since `v4.8.0`.
                    lineWidth: 5,
                    // Font thick weight. Available since `v5.0.1`.
                    fontWeight: "normal",
                    // Font style. Available since `v5.0.1`.
                    fontStyle: "normal",
                    // Font family. Available since `v5.0.1`.
                    fontFamily: "sans-serif",
                  }}
                  opts={{ renderer: "svg" }}
                />
              </div>
            </div>
          </div>
        </SummaryDashboardStyle>
      </>
    );
  }
}

export default withDateTime(SummaryDashboard);
