import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import { Button } from "react-bootstrap";
import LoaderComponent from "../../../components/Spinner/LoaderComponent";
import moment from "moment";
import ButtonBasic from "../../../components/Buttons/ButtonBasic";
import CurrencyFormat from "react-currency-format";
import axiosInstance from "../../../utils/axiosInstance";
import { ATMBalanceStyle } from "./ATMBalanceStyle";
import { useDateTime } from "../../../components/Helper/DateTime";
import Toast from "../../../components/Toast/Toast";
import BadgeRounded from "../../../components/BadgeRounded/BadgeRounded";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { CustomTooltip } from "../../../utils/helpers/custom-tooltip";
const formSchema = Yup.object().shape({
  comment: Yup.string().min(2, "Too Short!").required("Notes are Required*"),
});

const ATMBalanceModal = ({ selectedAllRows, setShowBalanceModal, rowData }) => {
  const { date, currency } = useDateTime();
  const [ATMBalanceNotes, setATMBalanceNotes] = useState({});
  const [loading, setLoading] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [adjustmentEntries, setAdjustmentEntries] = useState(null);
  const [selectedDebitAccount, setSelectedDebitAccount] = useState("");
  const [selectedCreditAccount, setSelectedCreditAccount] = useState("");
  const [categories, setCategories] = useState([]);
  const [debitAccounts, setDebitAccounts] = useState([]);
  const [creditAccounts, setCreditAccounts] = useState([]);

  const [data] = useState([]);
  const adjustmentEntriesColumns = [
    {
      dataField: "id",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "transactionDetails",
      text: "Transaction Details",
      formatter: (cell, row, rowIndex) => (
        <div>
          <BootstrapTable
            bordered={false}
            keyField="id"
            wrapperClasses="overflow-y-hidden overflow-x-hidden"
            classes="rs-table table-layout-auto mb-0 table-custom-style"
            columns={[
              {
                dataField: "glAccount",
                text: "GL Account",
                style: { minWidth: "100px" },
                formatter: (cell) => (cell ? cell : "-"),
              },
              {
                dataField: "postingDate",
                text: "Posting Date",
                formatter: (cell) => (cell ? cell : "-"),
              },
              {
                dataField: "description",
                text: "Description",
                style: {
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  minWidth: "342px",
                  lineHeight: "1.5",
                },
                formatter: (cell) => (cell ? cell : "-"),
              },
              {
                dataField: "creditAmount",
                text: "Credit Amount",
                formatter: (cell, row) => formatAmount(cell, row),
              },
              {
                dataField: "debitAmount",
                text: "Debit Amount",
                formatter: (cell, row) => formatAmount(cell, row),
              },
              {
                dataField: "entryReference",
                text: "Entry Reference",
                formatter: (cell) => (cell ? cell : "-"),
              },
            ]}
            data={cell || []}
            headerClasses={rowIndex === 0 ? null : "visibility-collapse"}
          />
          {adjustmentEntries?.length - 1 !== rowIndex && <hr />}
        </div>
      ),
    },
  ];
  const username =
    JSON.parse(localStorage.getItem("userData"))?.firstName +
    " " +
    JSON.parse(localStorage.getItem("userData"))?.lastName;
  const dataForBalanceAPI = selectedAllRows?.map((item) => {
    return {
      ...data,
      terminalId: item?.terminalId,
      deviceName: item?.deviceName,
      reconStatus: item?.reconStatus,
      adjustmentCategory: selectedCategory,
      adjustmentCategoryName: selectedCategory?.label,
      creditAccount: selectedCreditAccount,
      debitAccount: selectedDebitAccount,
      postingDate: item?.date,
      balancingSummaryId: item?.balancingSummaryId,
      customData: JSON.stringify(adjustmentEntries),
    };
  });
  const manualReconAccounts = useCallback(async () => {
    setLoading(true);
    let status = "";
    if (
      selectedAllRows?.every((item) => item.reconStatus === "SHORTAGE") ||
      rowData?.reconStatus === "SHORTAGE"
    ) {
      status = 100002;
    } else if (
      selectedAllRows?.every((item) => item?.reconStatus === "SURPLUS") ||
      rowData?.reconStatus === "SURPLUS"
    ) {
      status = 100001;
    }
    try {
      const response =
        selectedAllRows?.length > 0
          ? await axiosInstance.get(
              // `/accounts/manual-recon-accounts?manual_recon_flow=ATM-Balancing&adjustment_category=${status}&account_required=${selectedAllRows
              //   .map((item) => item?.deviceId)
              //   .join(",")}`
              `/accounts/manual-recon-accounts-v2?batch_id=${selectedAllRows[0]?.batchId}&recon_account_ids=${selectedAllRows[0]?.reconAccountId}`
            )
          : await axiosInstance.get(
              `/accounts/manual-recon-accounts-v2?batch_id=${rowData?.batchId}&recon_account_ids=${rowData?.reconAccountId}`
            );
      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
        setLoading(false);
      } else {
        if (response?.data) {
          setLoading(false);
          populateOptions(response?.data);
        }
      }
    } catch (err) {
      Toast(err.response.data.message.description, "error");
    }
  }, []);

  const populateOptions = (data) => {
    // Transforming data for Select components
    const transformedCategories = data.categories.map((item) => ({
      value: item.id,
      label: item.categoryName,
    }));

    const transformedDebitAccounts = data.debitAccounts.map((item) => ({
      value: item.reconAccountId,
      label: item.name,
    }));

    const transformedCreditAccounts = data.creditAccounts.map((item) => ({
      value: item.reconAccountId,
      label: item.name,
    }));

    // Set state variables
    setCategories(transformedCategories);
    setDebitAccounts(transformedDebitAccounts);
    setCreditAccounts(transformedCreditAccounts);
  };

  const handleShowAdjustment = async () => {
    try {
      let requestPayload = {
        data: {
          matchingData: selectedAllRows?.map((item) => {
            return {
              id: item?.balancingSummaryId,
              atmBalancingFlowType: "flow1",
            };
          }),
          creditAccount: selectedCreditAccount,
          debitAccount: selectedDebitAccount,
          category: selectedCategory,
        },
      };
      let response = await axiosInstance.post(
        "/matching/adjustment-entries",
        requestPayload
      );
      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
      } else {
        setAdjustmentEntries(response?.data);
      }
    } catch (err) {
      Toast(err.response.data.message.description, "error");
    }
  };

  const handleSubmit = async (values) => {
    try {
      setLoading(true);

      const data = {
        data: {
          balancingData: dataForBalanceAPI,
          notes: ATMBalanceNotes?.notes,
        },
      };

      const response = await axiosInstance.post(`/devices/atm-balancing`, data);

      if (response.message.status === "200") {
        Toast(response.message.description, "success");
        setShowBalanceModal(false);
      } else {
        Toast(response.message.description, "error");
      }
    } catch (error) {
      setLoading(false);
      Toast(error.response?.data?.message?.description, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    manualReconAccounts();
  }, [manualReconAccounts]);

  useEffect(() => {
    handleShowAdjustment();
  }, [selectedCategory, selectedCreditAccount, selectedDebitAccount]);
  const handleAddNotes = (values) => {
    let now = moment();
    let note = {
      comment: values?.comment,
      author: username,
      date: now.format(date),
      time: now.hour() + ":" + now.minute(),
    };
    // setComment("");
    let _notes = ATMBalanceNotes?.notes || [];
    _notes.push(note);
    setATMBalanceNotes({ ...ATMBalanceNotes, notes: _notes });
  };

  const formatNumber = (number) => {
    if (isNaN(Number(number))) {
      return number;
    } else {
      number = Number(number);
      return number.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        useGrouping: true,
      });
    }
  };

  const formatAmount = (cell) => {
    const isNegative = cell < 0;
    const formattedValue = isNegative ? `${Math.abs(cell)}` : `${cell}`;
    return (
      <>
        <div className="d-flex justify-content-end">
          <span className="item px-2">{currency}</span>
          <span className="item">
            <CurrencyFormat
              value={Math.abs(cell)}
              displayType={"text"}
              thousandSeparator={true}
              renderText={() => {
                return <span>{formatNumber(formattedValue)}</span>;
              }}
            />
          </span>
        </div>
      </>
    );
  };

  const reconStatus = (cell) =>
    cell ? (
      <BadgeRounded
        color={cell.toLowerCase() === "balanced" ? "success" : "danger"}
        title={cell}
      />
    ) : (
      "-"
    );

  const columns = [
    { hidden: true, dataField: "deviceId", text: "deviceId" },
    {
      dataField: "terminalId",
      dataAlign: "left",
      text: "Terminal ID",
      // sort: true,
    },
    {
      dataField: "deviceName",
      dataAlign: "left",
      text: "Device Name",
      // sort: true,
    },
    {
      dataField: "reconStatus",
      dataAlign: "center",
      formatter: reconStatus,
      text: "Recon Status",
      // sort: false,
    },
    {
      dataField: "cashUnloadDiff",
      text: "Difference Amount",
      formatter: formatAmount,
      // sort: true,
      headerAlign: "right",
    },
  ];

  return (
    <>
      {loading && (
        <div
          className="spinner-center"
          style={{ top: "0%", left: "0%", zIndex: 2000 }}
        >
          <LoaderComponent />
        </div>
      )}

      <ATMBalanceStyle>
        <div className="row">
          <div className="col-8">
            <div className="container">
              <div className="row">
                <div className="col-4">
                  <label style={{ fontSize: "14px" }}>
                    Adjustment Category*
                  </label>
                  <Select
                    placeholder="Select Category"
                    options={categories}
                    value={categories.find(
                      (item) => item.value === selectedCategory
                    )}
                    onChange={(e) => setSelectedCategory(e.value)}
                    isSearchable
                    className="no-border mt-2"
                  />
                </div>

                {/* Debit Account Select */}
                <div className="col-4">
                  <label style={{ fontSize: "14px" }}>Debit Account*</label>
                  <Select
                    placeholder=" Debit Account"
                    isDisabled={debitAccounts.length > 0 ? false : true}
                    options={debitAccounts}
                    value={debitAccounts.find(
                      (item) => item.value === selectedDebitAccount
                    )}
                    onChange={(e) => setSelectedDebitAccount(e.value)}
                    isSearchable
                    className="no-border mt-2"
                  />
                </div>

                {/* Credit Account Select */}
                <div className="col-4">
                  <label style={{ fontSize: "14px" }}>Credit Account*</label>
                  <Select
                    placeholder="Credit Account"
                    isDisabled={creditAccounts.length > 0 ? false : true}
                    options={creditAccounts}
                    value={creditAccounts.find(
                      (item) => item.value === selectedCreditAccount
                    )}
                    onChange={(e) => setSelectedCreditAccount(e.value)}
                    isSearchable
                    className="no-border mt-2"
                  />
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-12 overflow-auto">
                    <BootstrapTable
                      columns={columns}
                      bootstrap4={true}
                      keyField="terminalId"
                      bordered={false}
                      classes="rs-table table-layout-auto table-custom-style"
                      wrapperClasses="overflow-y-auto fancy-scroll"
                      data={selectedAllRows}
                      noDataIndication={"No data found!"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="notes-wrapper">
              <h5 className="mb-3 color-gray-dim">Notes</h5>
              <div className="overflow-auto" style={{ height: "15em" }}>
                {rowData?.notes?.map(({ author, comment, date, time }, i) => (
                  <ul className="rs-notes-container" key={i}>
                    <li className="rs-notes-item">
                      <h6>{author}</h6>
                      <div className="note-comment">{comment}</div>
                      <sub>
                        {date} {time}
                      </sub>
                    </li>
                  </ul>
                ))}
                {ATMBalanceNotes?.notes?.map(
                  ({ author, comment, date, time }, i) => (
                    <ul className="rs-notes-container" key={i}>
                      <li className="rs-notes-item">
                        <h6>{author}</h6>
                        <div className="note-comment">{comment}</div>
                        <sub>
                          {date} {time}
                        </sub>
                      </li>
                    </ul>
                  )
                )}
              </div>

              <Formik
                initialValues={{
                  comment: "",
                }}
                enableReinitialize={true}
                validationSchema={formSchema}
                onSubmit={(values, { resetForm }) => {
                  handleAddNotes(values);
                  resetForm({ values: "" });
                }}
              >
                {({ errors, touched, handleSubmit, handleReset }) => (
                  <Form
                    id="add-notes"
                    className="form"
                    onSubmit={handleSubmit}
                    onReset={handleReset}
                  >
                    <div className="row mt-3">
                      <div className="col-12 mt-3">
                        <div className="d-flex flex-column">
                          <Field
                            as="textarea"
                            className="rs-input"
                            placeholder="Write a Note"
                            rows="3"
                            name="comment"
                          />
                          {errors.comment && touched.comment ? (
                            <div className="field-error">{errors.comment}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="w-100 mt-2">
                      <ButtonBasic
                        type="submit"
                        classes={"primary px-4 w-100"}
                        title={"Add Note"}
                        onClick={handleSubmit}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        {adjustmentEntries?.length > 0 && (
          <div className="row mt-4 w-100">
            <div className="col-12">
              <div className="mt-4 color-success mb-2">
                Manual Adjustment Entries
              </div>
              <BootstrapTable
                keyField="id"
                data={adjustmentEntries}
                columns={adjustmentEntriesColumns}
                bordered={false}
                classes="rs-table table-layout-auto table-custom-style"
                headerClasses="d-none"
                noDataIndication="No data found!"
                wrapperClasses="overflow-y-auto fancy-scroll"
              ></BootstrapTable>
            </div>
          </div>
        )}
      </ATMBalanceStyle>
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-end align-items-center mt-5">
            <div>
              <Button
                className="btn btn-secondary "
                onClick={() => {
                  setShowBalanceModal(false);
                }}
              >
                Cancel
              </Button>
            </div>
            <div>
              <CustomTooltip
                placement="top"
                tooltipId="button-tooltip-2"
                disabled={
                  selectedCategory &&
                  selectedDebitAccount &&
                  selectedCreditAccount &&
                  ATMBalanceNotes?.notes
                    ? false
                    : true
                }
                disabledMessage={
                  "Select any category, debit, credit and add Notes"
                }
                onClick={handleSubmit}
                buttonText="Balance"
                style={{ marginLeft: "10px" }}
              >
                Balance
              </CustomTooltip>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ATMBalanceModal;
