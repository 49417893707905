import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import axiosInstance from "../../../utils/axiosInstance";
import Pagination from "../../../components/pagination/pagination";
import LoaderComponent from "../../../components/Spinner/LoaderComponent";
import ButtonBasic from "../../../components/Buttons/ButtonBasic";
import FilterIcon from "../../../assets/images/filter.png";
import CSVIcon from "../../../assets/images/csv1.png";
import Filter from "../../../components/FilterDropdown/GenericFilter";
import { Modal } from "react-bootstrap";
import AtmListModal from "../DeviceDetailModal";
import Toast from "../../../components/Toast/Toast";
import BadgeRounded from "../../../components/BadgeRounded/BadgeRounded";
import CurrencyFormat from "react-currency-format";
import queryString from "query-string";
import { CSVLink } from "react-csv";
import "react-datepicker/dist/react-datepicker.css";
import { useDateTime } from "../../../components/Helper/DateTime";
import { regionCheck, mapdata } from "../../../utils/regionHelper";
import FilterDropdown from "../../../components/FilterDropdown/FilterDropdown";
import ATMBalanceModal from "./ATMBalanceModal";
import moment from "moment";
import {
  filtersParams,
  mapFilters,
  handleFilterChange,
  handleNewFilter,
} from "../../../utils/filtersHelper";
import { DateFormat, TimeFormat } from "../../../utils/formatDateTime";

const BranchList = (props) => {
  const { currency, time, date, presistState, setRefresh, setPresistState } =
    useDateTime();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState({});
  const [regions, setRegions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(
    presistState?.route ? presistState?.pagination?.page : 1
  );
  const [pageSize] = useState(
    presistState?.route ? presistState?.pagination?.pageSize : 10
  );
  const [totalPages, setTotalPages] = useState(
    presistState?.route ? presistState?.pagination?.totalPages : 0
  );
  const [totalElements, setTotalElements] = useState(
    presistState?.route ? presistState?.pagination?.totalElements : 0
  );
  const [showModal, setShowModal] = useState(false);
  const [sort, setSort] = useState({ sort_by: "", sort_order: "" });
  const [filter, setFilter] = useState({});
  const [id, setId] = useState("");
  const [rowData, setRowData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [showFilters, setShowFilters] = useState([]);
  const regionFilter = useRef("");
  const [selectedAllRows, setSelectedAllRows] = useState(
    presistState?.route ? presistState?.selectedAllRows ?? [] : []
  );
  const [showBalanceModal, setShowBalanceModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [singleSuspiciousClick, setSingleSuspiciousClick] = useState(false);

  useEffect(() => {
    if (warning === true) Toast(warningMessage, "error");
  }, [warning, warningMessage, selectedAllRows?.length]);

  const ATMBalanceInRow = (cell, row) =>
    cell ? (
      <>
        <div className="d-flex justify-content-between align-item-center">
          {allData?.isFlow1Enabled === true && (
            <div className="item">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="button-tooltip-2">Click to balance ATM</Tooltip>
                }
              >
                <i
                  id="balance-atm"
                  className="fa fa-balance-scale"
                  aria-hidden="true"
                  style={{
                    fontSize: "18px",
                    cursor: "pointer",
                  }}
                />
              </OverlayTrigger>
            </div>
          )}
          {allData?.isFlow2Enabled === true && (
            <div className="item ml-2">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="button-tooltip-2">
                    Suspicious Transaction
                  </Tooltip>
                }
              >
                <i
                  id="suspicious-transaction"
                  className="fa fa-money"
                  aria-hidden="true"
                  style={{ fontSize: "18px", cursor: "pointer" }}
                  onClick={() => setSingleSuspiciousClick(true)}
                />
              </OverlayTrigger>
            </div>
          )}
        </div>
      </>
    ) : (
      ""
    );

  const rowEvent = {
    onClick: (e, row) => {
      setId(row.deviceId);
      setRowData(row);
      setShowModal(true);
    },
  };

  const rowTableEvent = {
    onClick: (e, row) => {
      setId(row.deviceId);
      setRowData(row);
      setSelectedAllRows([row]);
      // setShowModal(true);
    },
  };

  const rowBalancingEvent = {
    onClick: (e, row) => {
      e.target.id === "balance-atm"
        ? setShowBalanceModal(true)
        : setShowBalanceModal(false);
    },
  };

  // const handleOnSelectRow = (row, isSelect) => {
  //   let newSelection;
  //   if (isSelect) {
  //     newSelection = [...selectedAllRows, row];
  //     setShowButtons(true);
  //   } else {
  //     setShowButtons(false);
  //     newSelection = selectedAllRows.filter((x) => x.deviceId !== row.deviceId);
  //   }

  //   const uniqueStatusValues = [
  //     ...new Set(newSelection.map((item) => item.reconStatus)),
  //   ];
  //   const hasMultipleStatusValues = uniqueStatusValues.length > 1;

  //   if (isSelect) {
  //     const hasShortageStatus = uniqueStatusValues.includes("SHORTAGE");
  //     const hasSurplusStatus = uniqueStatusValues.includes("SURPLUS");
  //     setWarning(
  //       (!hasShortageStatus && !hasSurplusStatus) || hasMultipleStatusValues
  //     );
  //     if (hasMultipleStatusValues === true) {
  //       setWarningMessage("Multiple Balancing status selection is not allowed");
  //     }
  //     if (hasShortageStatus === false || hasMultipleStatusValues === false) {
  //       setWarningMessage(
  //         "Balancing is allowed only for Shortage/Surplus scenario"
  //       );
  //     }

  //     setSelectedAllRows(newSelection);
  //   } else {
  //     const hasShortageStatus = newSelection.every(
  //       (item) => item.reconStatus === "SHORTAGE"
  //     );
  //     const hasSurplusStatus = newSelection.every(
  //       (item) => item.reconStatus === "SURPLUS"
  //     );
  //     setWarning(
  //       (!hasShortageStatus && !hasSurplusStatus) || hasMultipleStatusValues
  //     );
  //     setSelectedAllRows(newSelection);
  //   }
  // };

  const handleOnSelectRow = (row, isSelect, rowIndex, e) => {
    if (
      e?.target?.type === "checkbox" &&
      e.target.id !== "balance-atm" &&
      e.target.id !== "suspicious-transaction"
    ) {
      setShowButtons(false);
      if (isSelect) {
        setSelectedAllRows([]);
        setSelectedAllRows([row]);
      } else {
        setSelectedAllRows([]);
      }
      isSelect &&
      row?.reconStatus !== "SHORTAGE" &&
      row?.reconStatus !== "SURPLUS"
        ? Toast(
            "Balancing is allowed only for Shortage/Surplus scenario",
            "error"
          )
        : setShowButtons(true);
    }
  };

  // const handleOnSelectAllRows = (isSelect, rows) => {
  //   if (isSelect) {
  //     setShowButtons(true);
  //     setSelectedAllRows((selectedAllRows) =>
  //       [...selectedAllRows, rows]?.flatMap((innerArr) => innerArr)
  //     );
  //     if (
  //       rows?.every(
  //         (data) =>
  //           data.reconStatus === "SHORTAGE" || data.reconStatus === "SURPLUS"
  //       )
  //     ) {
  //       setWarning(false);
  //     } else {
  //       setWarning(true);
  //       setWarningMessage(
  //         "Multiple Balancing status selection are not allowed"
  //       );
  //     }
  //   } else {
  //     setShowButtons(false);
  //     setSelectedAllRows([]);
  //     setWarning(false);
  //   }
  // };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    // selected: selectedAllRows,
    onSelect: handleOnSelectRow,
    selected:
      selectedAllRows?.length > 0 &&
      selectedAllRows?.map((item) => item?.balancingSummaryId),
    // onSelectAll: data?.length > 0 && handleOnSelectAllRows,
    hideSelectAll: true,
  };

  const onSortTable = (type, newState) => {
    setPage(1);
    setSort({ sort_by: newState.sortField, sort_order: newState.sortOrder });
  };

  const fetchData = useCallback(
    (filter, flag, regionFilters) => {
      setLoading(true);
      setWarning(false);
      let allFilters = filtersParams(filter, regionFilters);
      const order = `&sort_by=${sort.sort_by}&sort_order=${sort.sort_order}`;
      axiosInstance
        .get(
          `/devices/balancing?number=${
            flag ? 0 : page - 1
          }&size=${pageSize}${allFilters}${
            sort.sort_by && sort.sort_order ? order : ""
          }`
        )
        .then((res) => {
          if (res.message.status !== "200") {
            // Toast(res.message.description, "error");
            setData([]);
            setLoading(false);
            setTotalElements(0);
          } else {
            setLoading(false);
            setData(res?.data?.devices || []);
            setAllData(res?.data || {});
            setPage(res?.page?.number + 1 || 1);
            setTotalPages(res?.page?.totalPages || 0);
            setTotalElements(res?.page?.totalElements);
          }
        })
        .catch((err) => {
          console.log("err", err);
          setData([]);
          setLoading(false);
          setTotalElements(0);
        });
    },
    [page, pageSize, sort]
  );

  //getting all filters
  const getFilters = useCallback(() => {
    setLoading(true);
    let data;
    //data receiving from props when someone click on atm dashboard cash summary chart or somewhere else
    if (props?.location?.state?.data) {
      data = props.location.state.data;
    }
    axiosInstance
      .get("/hierarchical/filters?screen_id=atm_list")
      .then((res) => {
        if (res?.message?.status !== "200") {
          Toast(res.message.description, "error");
          fetchData();
          setLoading(false);
        } else {
          //Region filter is unique and have different logic. so keeping this logic here
          let regions = res?.data?.filters.filter(
            (item) => item.filterName === "region"
          );
          //other filters on page - same kind of filters with different types like date-picker, radio, single check etc.
          let otherFilters = res?.data?.filters.filter(
            (x) => x.filterName !== "region"
          );

          //check other filters to show in plus button along with filters on screen and whether to show/not
          const filteredFilters = otherFilters?.filter((x) => x.showOnScreen); //filters that are showing on screen
          const showFiltersData = otherFilters?.filter((x) => !x.showOnScreen); //plus button filters

          //two states to keep plus button states and normal filters
          setShowFilters(showFiltersData);
          setFilterData(filteredFilters);

          //it maps filters and check types and which will be checked by default
          let obj = mapFilters(
            res?.data?.filters.filter((x) => x.showOnScreen)
          );

          //logic:: if we are on this page coming from any other page e.g atm-dashboard -> click on cash summary and land on this page
          if (data) {
            //if we are receiving date in props then we have to set that date in the filter
            if (data.date) {
              const [year, month, day] = data.date.split("-");
              obj.business_date.data = new Date(year, month - 1, day); //date selected by default
              obj.business_date.filter = [data.date]; //date passing in filter api as param.
            }

            //if we are receiving atm_type from props then setting that atm_type as default
            if (data.atm_type) {
              obj.atm_type.filter = data.atm_type; //passing in filters params
            }

            //always set atm id in balancing
            if (data.id) {
              obj.balancing.filter = [data.id];
            }
          }

          let filter = "";

          //logic for region select, if receiving in props then will set props regions and will send those ids in filter api as params
          if (regions.length > 0) {
            const region_atm_dash = data?.region_atm_dash ?? null;
            regions[0].filterData =
              region_atm_dash ?? mapdata(regions[0].filterData);
            !region_atm_dash &&
              regions[0].filterData.forEach((x) => {
                x.checked = x.param1 === "1" ? true : false;
              });
            if (region_atm_dash) {
              filter = `&region_id=${region_atm_dash
                .filter((item) => item.checked)
                .map(({ codeValue }) => codeValue)
                .join(",")}`;
              regionFilter.current = filter;
            } else {
              filter = `&region_id=${regions[0]?.filterData
                .map(({ codeValue }) => codeValue)
                .join(",")}`;
              regionFilter.current = filter;
            }
          }
          if (presistState?.route) {
            const obj = {
              ...presistState?.filter,
              business_date: {
                data: new Date(
                  presistState?.filter?.business_date?.filter?.[0]?.split(
                    "-"
                  )?.[0],
                  presistState?.filter?.business_date?.filter?.[0]?.split(
                    "-"
                  )?.[1] - 1,
                  presistState?.filter?.business_date?.filter?.[0]?.split(
                    "-"
                  )?.[2]
                ),
                filter: [presistState?.filter?.business_date?.filter?.[0]],
              },
            };
            fetchData(obj, false, presistState?.regionFilter);
            setRegions(presistState?.regions);
            setFilter(obj);
            setFilterData(presistState?.filterData);
            setShowFilters(presistState?.showFilters);
            setPresistState(null);
          } else {
            fetchData(obj, true, filter); //object includes params for api, true is for flag(if true we have to pass 0), filter is for regionFilter
            setRegions(regions); // to keep track of the region filters (if refresh then it does not loss values/checks)
            setFilter(obj); //sets all  filters execpt region filter and plus sign filters.
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("getting filters error...", err);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [props.location.state]);

  const onRegionDropdownChange = (value) => {
    if (value?.length > 0) {
      regionFilter.current = `&region_id=${value
        .map((node) => node.codeValue)
        .join(",")}`;
    } else {
      regionFilter.current = "";
    }
    const regionData = [...regions];
    regionData[0].filterData = regionCheck(regions[0].filterData, value);
    setRegions(regionData);
  };

  const formatNumber = (number) => {
    if (isNaN(Number(number))) {
      return number;
    } else {
      number = Number(number);
      return number.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        useGrouping: true,
      });
    }
  };

  const formatAmount = (cell) => {
    const isNull =
      cell === null || cell === "null" || cell === "" ? true : false;
    const isNegative = cell < 0;
    const formattedValue = isNull
      ? "-"
      : isNegative
      ? `${Math.abs(cell)}`
      : `${cell}`;

    if (cell) {
      return (
        <>
          <div className="d-flex justify-content-between">
            <span className="item px-3">{currency}</span>
            <span className="item">
              <CurrencyFormat
                value={Math.abs(cell)}
                displayType={"text"}
                thousandSeparator={true}
                renderText={() => {
                  return <span>{formatNumber(formattedValue)}</span>;
                }}
              />
            </span>
          </div>
        </>
      );
    } else {
      return "-";
    }
  };
  const reconStatus = (cell) =>
    cell ? (
      <BadgeRounded
        color={
          cell.toLowerCase() === "balanced"
            ? "success"
            : cell.toLowerCase() === "missing" ||
              cell.toLowerCase() === "error" ||
              cell.toLowerCase() === "load-mismatch" ||
              cell.toLowerCase() === "shortage"
            ? "danger"
            : ""
        }
        customStyle={
          cell.toLowerCase() === "surplus"
            ? { backgroundColor: "#007bff" }
            : cell.toLowerCase() === "in-staging"
            ? { backgroundColor: "#c6a788" }
            : null
        }
        title={cell}
      />
    ) : (
      "-"
    );

  const formatAmountGLColor = (cell) => {
    if (cell) {
      if (Math.sign(cell) === -1) {
        return { color: "#ef476f" };
      }
    }
  };
  const formatAmountGL = (cell) => {
    return cell ? (
      <CurrencyFormat
        value={cell}
        displayType={"text"}
        thousandSeparator={true}
        prefix={`${currency} `}
      />
    ) : (
      "-"
    );
  };

  const columns = [
    {
      hidden: true,
      dataField: "balancingSummaryId",
      text: "balancingSummaryId",
    },

    {
      dataField: "date",
      dataAlign: "left",
      text: "Date",
      events: rowEvent,
      formatter: (cell) => (cell ? DateFormat(date, cell) : "-"),
    },
    {
      dataField: "time",
      dataAlign: "left",
      text: "Time",
      formatter: (cell) => (cell ? TimeFormat(time, cell) : "-"),
      events: rowEvent,
    },
    {
      dataField: "terminalId",
      dataAlign: "left",
      text: "Terminal ID",
      sort: true,
      events: rowEvent,
    },
    {
      dataField: "deviceName",
      dataAlign: "left",
      text: "Device Name",
      sort: true,
      events: rowEvent,
    },
    {
      dataField: "reconStatus",
      dataAlign: "center",
      formatter: reconStatus,
      text: "Recon Status",
      sort: true,
      events: rowEvent,
    },
    {
      dataField: "displayBalancingButton",
      dataAlign: "center",
      formatter: ATMBalanceInRow,
      text: "Action",
      events: rowBalancingEvent,
      sort: false,
    },
    {
      dataAlign: "left",
      dataField: "difference",
      formatter: formatAmount,
      text: "Difference",
      sort: true,
      events: rowEvent,
      headerAlign: "center",
      align: "center",
    },

    {
      dataField: "ejPreviousReplenishmentAmount",
      text: "Previous Repln",
      formatter: formatAmount,
      sort: true,
      events: rowEvent,
      headerAlign: "right",
      align: "right",
    },
    {
      dataAlign: "left",
      dataField: "ejCashDisbursementAmount",
      formatter: formatAmount,
      text: "Cash Disbursed",
      sort: true,
      events: rowEvent,
      headerAlign: "right",
      align: "right",
    },
    {
      dataAlign: "left",
      dataField: "ejBnaDepositAmount",
      formatter: formatAmount,
      text: "Cash Deposited",
      sort: true,
      events: rowEvent,
      headerAlign: "right",
      align: "right",
    },
    {
      dataAlign: "left",
      dataField: "ejResidualCashAmount",
      formatter: formatAmount,
      text: "Cash Remaining",
      sort: true,
      events: rowEvent,
      headerAlign: "right",
      align: "right",
    },
    {
      dataField: "cashUtilization",
      dataAlign: "left",
      // eslint-disable-next-line
      formatter: (cell) => (cell && cell != "null" ? `${cell}%` : "-"),
      text: "Utilization",
      sort: true,
      events: rowEvent,
    },
    {
      dataAlign: "left",
      dataField: "ejReplenishmentAmount",
      formatter: formatAmount,
      text: "Repln Amount",
      sort: true,
      events: rowEvent,
      headerAlign: "right",
      align: "right",
    },
    {
      dataField: "lastSeq",
      dataAlign: "left",
      text: "TSN",
      // eslint-disable-next-line
      formatter: (cell) => (cell && cell != "null" ? cell : "-"),
      sort: true,
      events: rowEvent,
    },
    {
      dataField: "atmGLBalance",
      style: formatAmountGLColor,
      formatter: formatAmountGL,
      text: "ATM GL Balance",
      sort: true,
      events: rowEvent,
    },
  ];
  const headers = [
    {
      label: "Device Name",
      key: "terminalId",
    },
    {
      label: "Date",
      key: "dateTime",
    },
    {
      label: "Cash Disburse Count",
      key: "cashDisbursementCount",
    },
    {
      label: "Cash Disburse Amount",
      key: "cashDisbursementAmount",
    },
    {
      label: "Remaining Cash Amount",
      key: "remainingCashAmount",
    },
    {
      label: "Cash Utilization",
      key: "cashUtilization",
    },
    {
      label: "Rep Count",
      key: "replenishmentEventCount",
    },
    {
      label: "Recon Status",
      key: "reconStatus",
    },
    {
      label: "Total Rep Amount",
      key: "totalReplenishmentAmount",
    },
    {
      label: "ATM GL Balance",
      key: "atmGLBalance",
    },
    {
      label: "CIT GL Balance",
      key: "citGLBalance",
    },
  ];

  useEffect(() => {
    if (filterData.length > 0) fetchData(filter);
  }, [fetchData]);

  useEffect(() => {
    getFilters();
  }, [getFilters]);
  useEffect(() => {
    const { id } = queryString.parse(props.location.search);
    if (id) {
      setId(id);
      setShowModal(true);
    }
  }, [props.location.search]);
  useEffect(() => {
    setRefresh(() => () => {
      fetchData(filter, true, regionFilter.current);
    });
    return () => {
      setRefresh(() => () => {});
    };
    // eslint-disable-next-line
  }, [fetchData]);

  useEffect(() => {
    if (singleSuspiciousClick) {
      setPresistState({
        filter: filter,
        regionFilter: regionFilter.current,
        regions: regions,
        filterData: filterData,
        showFilters: showFilters,
        pagination: {
          page: page,
          pageSize: pageSize,
          totalPages: totalPages,
          totalElements: totalElements,
        },
      });
      history.push({
        pathname: "/transaction-log",
        state: {
          suspeciousData: {
            deviceId: rowData?.deviceId,
            balancingSummaryId: rowData?.balancingSummaryId,
            terminalId: rowData?.terminalId,
            business_date: filter?.business_date?.filter[0],
            channel: "ATM",
            status: rowData?.reconStatus,
            batchId: rowData?.batchId,
            reconAccountId: rowData?.reconAccountId,
          },
        },
      });
    }
  }, [singleSuspiciousClick]);

  return (
    <>
      {loading && (
        <div
          className="spinner-center"
          style={{ top: "0%", left: "0%", zIndex: 2000 }}
        >
          <LoaderComponent />
        </div>
      )}

      <div style={{ opacity: !loading ? "1" : "0.07" }}>
        <div className="row mb-2 align-items-center d-xs-none d-lg-flex">
          {regions &&
            regions?.map((region) => {
              return (
                <div
                  className="filter-item mt-2 d-flex"
                  // style={{ minWidth: "210px" }}
                >
                  <FilterDropdown
                    title={region.filterLabel}
                    type="checkbox"
                    data={region.filterData}
                    onDropdownChange={(value) => onRegionDropdownChange(value)}
                  />
                </div>
              );
            })}
          {filterData.map(
            ({
              filterName,
              filterType,
              filterLabel,
              showOnScreen,
              filterId,
            }) => (
              <div
                className="filter-item mt-2 ml-3 d-flex"
                // style={{ minWidth: "210px" }}
              >
                <Filter
                  id={filterLabel ? filterLabel?.replace(/\s/g, "") : ""}
                  title={filterLabel}
                  filterId={filterId}
                  type={filterType}
                  data={filter[filterName]?.data}
                  filter={filter[filterName]?.filter}
                  removable={!showOnScreen}
                  onChange={(e) =>
                    handleFilterChange(e, filterName, filter, setFilter)
                  }
                  onRemove={(e) =>
                    handleNewFilter(
                      e,
                      filterData,
                      showFilters,
                      setFilter,
                      setFilterData
                    )
                  }
                />
              </div>
            )
          )}
          {/* <div className="filter-item mt-2 ml-3">
            <Filter
              title={""}
              type={1006}
              data={showFilters}
              filter={filterData.map((x) => x.filterId)}
              field="filterId"
              onChange={(e) =>
                handleNewFilter(
                  e,
                  filterData,
                  showFilters,
                  setFilter,
                  setFilterData
                )
              }
            />
          </div> */}
          <div className="col d-flex justify-content-start mt-2">
            <ButtonBasic
              wrapperClass={"d-flex justify-content-end mr-0"}
              icon={FilterIcon}
              title="Filter"
              onClick={() => {
                setSelectedAllRows([]);
                fetchData(filter, true, regionFilter.current);
              }}
            />
          </div>
        </div>

        <div className="row page-header d-flex justify-content-between mt-5">
          <div className="col-sm-12 col-lg-6 page-header d-flex justify-content-between m-0">
            <div className="title">ATM List</div>
          </div>
          {selectedAllRows &&
            (selectedAllRows[0]?.reconStatus === "SHORTAGE" ||
              selectedAllRows[0]?.reconStatus === "SURPLUS") &&
            showButtons === true && (
              <div>
                {allData?.isFlow1Enabled === true && (
                  <button
                    className="btn btn-info mr-3"
                    disabled={allData?.isFlow1Enabled === false ? true : false}
                    onClick={() => setShowBalanceModal(true)}
                  >
                    Balance ATM
                  </button>
                )}

                {allData?.isFlow2Enabled === true && (
                  <button
                    className="btn btn-info"
                    disabled={allData?.isFlow2Enabled === false ? true : false}
                    onClick={() => {
                      setPresistState({
                        filter: filter,
                        regionFilter: regionFilter.current,
                        regions: regions,
                        filterData: filterData,
                        showFilters: showFilters,
                        selectedAllRows: selectedAllRows,
                        pagination: {
                          page: page,
                          pageSize: pageSize,
                          totalPages: totalPages,
                          totalElements: totalElements,
                        },
                      });
                      history.push({
                        pathname: "/transaction-log",
                        state: {
                          suspeciousData: {
                            deviceId: selectedAllRows[0]?.deviceId,
                            balancingSummaryId:
                              selectedAllRows[0]?.balancingSummaryId,
                            terminalId: selectedAllRows[0]?.terminalId,
                            business_date: filter?.business_date?.filter[0],
                            channel: "ATM",
                            status: selectedAllRows[0]?.reconStatus,
                            batchId: selectedAllRows[0]?.batchId,
                            reconAccountId: selectedAllRows[0]?.reconAccountId,
                          },
                        },
                      });
                    }}
                  >
                    Suspicious Transactions
                  </button>
                )}
              </div>
            )}
        </div>
        <BootstrapTable
          columns={columns}
          bootstrap4={true}
          keyField="balancingSummaryId"
          bordered={false}
          classes="rs-table table-layout-auto table-custom-style"
          wrapperClasses="overflow-y-auto fancy-scroll"
          data={data}
          rowEvents={rowTableEvent}
          selectRow={selectRow}
          remote={{ sort: true }}
          onTableChange={onSortTable}
          noDataIndication={"No data found!"}
        />
        <div className="row">
          <div className="col-2">
            {data?.length > 0 && (
              <CSVLink
                data={data}
                filename={"Atm list"}
                headers={headers}
                target="_blank"
                className="mr-2"
              >
                <img
                  width={25}
                  height={25}
                  src={CSVIcon}
                  style={{ backgroundColor: "white" }}
                  className="right-btn mr-2"
                  alt="rightbtn"
                />
              </CSVLink>
            )}
          </div>
          <div className="col-10">
            <Pagination
              setPage={setPage}
              page={page}
              totalPages={totalPages}
              totalElements={totalElements}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Modal
              backdrop={true}
              size="xl"
              show={showModal}
              onHide={() => {
                setShowModal(false);
              }}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <div className="color-dark">
                  <span> Balancing Details </span> <br />
                  <strong
                    className="color-dark font-normal"
                    style={{ fontSize: "14px" }}
                  >
                    Terminal ID: <small>{rowData?.terminalId}</small>
                  </strong>
                </div>
              </Modal.Header>
              <Modal.Body scrollable="true">
                <div className="row">
                  <AtmListModal rowData={rowData}></AtmListModal>
                </div>
              </Modal.Body>
            </Modal>
          </div>
          {showBalanceModal && (
            <div className="col-md-12">
              <Modal
                backdrop={true}
                size="xl"
                show={showBalanceModal}
                onHide={() => {
                  setShowBalanceModal(false);
                }}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header closeButton>
                  <div className="color-dark">
                    {" "}
                    Balance using suspense account
                  </div>
                </Modal.Header>
                <Modal.Body scrollable="true">
                  <ATMBalanceModal
                    selectedAllRows={selectedAllRows}
                    setSelectedAllRows={setSelectedAllRows}
                    setShowBalanceModal={setShowBalanceModal}
                    rowData={rowData}
                  ></ATMBalanceModal>
                </Modal.Body>
              </Modal>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default BranchList;
