import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Component,
} from "react";
import { Modal } from "react-bootstrap";

import { Formik, Form, Field } from "formik";
import Select from "react-select";
import ButtonBasic from "../../../../components/Buttons/ButtonBasic";
import axiosInstance from "../../../../utils/axiosInstance";
import Toast from "../../../../components/Toast/Toast";
import * as Yup from "yup";
import LoaderComponent from "../../../../components/Spinner/LoaderComponent";
import {
  mapFilters,
  handleFilterChange,
  handleNewFilter,
  filtersParams,
} from "../../../../utils/filtersHelper";
import { regionCheck, mapdata } from "../../../../utils/regionHelper";
import Filter from "../../../../components/FilterDropdown/GenericFilter";
import FilterDropdown from "../../../../components/FilterDropdown/FilterDropdown";
import FilterDropdownStyle from "./FilterDropdownStyle";
import chevronDown from "../../../../assets/images/chevron-down.png";
import DropdownTreeSelect from "react-dropdown-tree-select";
const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{7}$/;
const FormSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required*"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required*"),
  username: Yup.string().email("Invalid email").required("Required*"),
  confirmEmail: Yup.string()
    .when("username", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref("username")], "Email does not match!"),
    })
    .required("Required*"),
  mobileNo: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Required*"),
  officePhone: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Required*"),
});

const AddUserModal = ({ showAddModal, setShowAddModal, roles, getUsers }) => {
  const [selectedValue, setSelectedValue] = useState([]);
  const [addUserLoader, setAddUserLoader] = useState(false);
  const [filter, setFilter] = useState({});
  const [filterData, setFilterData] = useState([]);
  const [resourcesData, setResourcesData] = useState([]);
  const [regionResourcesData, setRegionResourcesData] = useState([]);
  const [selectedResourceTypes, setSelectedResourceTypes] = useState([]);
  const [reconAccountIds, setReconAccountIds] = useState([]);
  const [showFilters, setShowFilters] = useState([]);
  const [regions, setRegions] = useState([]);
  const regionFilter = useRef("");
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isTeamLoading, setIsTeamLoading] = useState(false);
  const [restrictedUser, setRestrictedUser] = useState(false);
  const [teamOptions, setTeamOptions] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const handleSelectServices = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const handleTeamOptions = (e) => {
    setSelectedTeam(e?.value);
  };

  const handleSelectResourceTypes = (e) => {
    setSelectedResourceTypes(
      Array.isArray(e) ? e.map((item) => item.value) : []
    );
  };

  const createUser = async (values, resetForm) => {
    setAddUserLoader(true);
    const data = {
      user: {
        username: values.username,
        firstName: values.firstName,
        middleName: values.middleName,
        lastName: values.lastName,
        empCode: values.empCode,
        mobileNo: values.mobileNo,
        officePhone: values.officePhone,
        officePhoneExt: values.officePhoneExt,
        roleIds: selectedValue,
        userLoginTypeId: 0,
        restrictedUser: restrictedUser,
        regionIds: restrictedUser ? selectedRegions : [],
        resourceType: restrictedUser ? filter?.resource_type?.filter : [],
        reconAccountIds: Object.values(reconAccountIds).flat(),
        teamId: selectedTeam,
      },
    };
    axiosInstance
      .post("/users", { data })
      .then((res) => {
        if (res.message.status !== "200") {
          Toast(res.message.description, "error");
        } else {
          setShowAddModal(false);
          getUsers();
          setAddUserLoader(false);
          resetForm();
          Toast(res.message.description, "success");
        }
      })
      .catch((error) => {
        setAddUserLoader(false);
        Toast(error.response.data.message.description, "error");
      });
  };

  const resourceTypeOptions = (item) =>
    item &&
    item.map(
      (item) =>
        ({
          label: item.accountLabel,
          value: item.id,
        } || [])
    );

  const getFilters = useCallback(() => {
    axiosInstance
      .get("users/user-region-resources")
      .then((res) => {
        if (res.message.status !== "200") {
          Toast(res.message.description, "error");
        } else {
          let regions = res?.data?.filters?.filter(
            ({ filterName }) => filterName === "region"
          );
          let otherFilters = res?.data?.filters?.filter(
            ({ filterName }) => filterName !== "region"
          );
          // debugger;
          let obj = mapFilters(res.data.filters.filter((x) => x.showOnScreen));
          if (regions.length > 0) {
            regions[0].filterData = mapdata(regions[0].filterData);

            regionFilter.current = `&region_id=${regions[0].filterData
              .map(({ codeValue }) => codeValue)
              .join(",")}`;
            // setSelectedRegions([
            //   regions[0].filterData.map(({ codeValue }) => codeValue).join(","),
            // ]);
          }
          setFilter(obj);
          // getResourcesData(obj);
          setRegions(regions);
          const filteredFilters = otherFilters?.filter((x) => x.showOnScreen);
          const showFiltersData = otherFilters?.filter((x) => !x.showOnScreen);
          setFilterData(filteredFilters);
          setShowFilters(showFiltersData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  const getResourcesData = useCallback((filter, regionFilters) => {
    setLoading(true);
    let allFilters = filtersParams(filter, regionFilters, true);

    axiosInstance
      .get(`/users/resources?${allFilters}`)
      .then((res) => {
        if (res.message.status !== "200") {
          setResourcesData([]);
          setLoading(false);
          // Toast(res.message.description, "error");
        } else {
          setLoading(false);
          setResourcesData(res?.data || []);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setResourcesData([]);
        setLoading(false);
        // Toast(err.response.data.message.description, "error");
      });
  }, []);

  const getUserRegionResources = async () => {
    try {
      let response = await axiosInstance.get("/users/user-region-resources");

      if (response?.message?.status !== "200") {
        setRegionResourcesData([]);
        setLoading(false);
        Toast(response.message.description, "error");
      } else {
        setRegionResourcesData(response?.data?.filters || []);
        setLoading(false);
      }
    } catch (err) {
      Toast(err.response.data.message.description, "error");
    }
  };

  const getTeams = () => {
    setIsTeamLoading(true);
    axiosInstance
      .get("/team/dropdown")
      .then((res) => {
        const transformedData = res?.data?.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setTeamOptions(transformedData);
        setIsTeamLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsTeamLoading(false);
      });
  };

  useEffect(() => {
    getFilters();
  }, [getFilters]);

  useEffect(() => {
    getTeams();
  }, []);

  useEffect(() => {
    if (filterData.length > 0 && restrictedUser === true)
      getResourcesData(filter, regionFilter?.current);
    // eslint-disable-next-line
  }, [getResourcesData, filter, selectedRegions, regionFilter?.current]);
  useEffect(() => {
    getUserRegionResources();
  }, []);

  const handleResourceOptions = (selectedOptions, resourceType) => {
    setReconAccountIds((prevState) => ({
      ...prevState,
      [resourceType]: selectedOptions?.map((option) => option.value),
    }));
  };

  const onChange = (currentNode, selectedNodes) => {
    currentNode = [currentNode];
    if (selectedNodes?.length > 0) {
      regionFilter.current = `&region_id=${selectedNodes
        .map((node) => node.codeValue)
        .join(",")}`;
      // setSelectedRegions([value?.map((item) => item.codeValue).join()]);
      setSelectedRegions(selectedNodes?.map((item) => Number(item.codeValue)));
    } else {
      regionFilter.current = "";
      setSelectedRegions([...selectedRegions]);
    }
    const regionData = [...regions];
    regionData[0].filterData = regionCheck(regions[0].filterData, currentNode);
    setRegions(regionData);
  };

  const labeledRoles = roles?.map((role) => ({
    ...role,
    value: role.id,
    label: role.name,
  }));
  function formatData(data) {
    return data?.map((item) => {
      return {
        ...item,
        children: formatData(item?.children) ?? [],
      };
    });
  }
  const formattedData = formatData(regions[0]?.filterData);
  return (
    <div className="col-md-12">
      <Formik
        initialValues={{
          firstName: "",
          middleName: "",
          lastName: "",
          username: "",
          empCode: "",
          mobileNo: "",
          officePhone: "",
          officePhoneExt: "",
          restrictedUser: false,
        }}
        validationSchema={FormSchema}
        onSubmit={(values, { resetForm }) => {
          if (
            !restrictedUser ||
            (restrictedUser &&
              filter?.resource_type?.filter?.length > 0 &&
              selectedRegions?.length > 0)
          ) {
            if (selectedTeam && selectedValue?.length > 0) {
              createUser(values, resetForm);
            }
          }
        }}
      >
        {({ values, errors, touched, handleSubmit, handleReset }) => (
          <Form
            id="add-user"
            className="form"
            onSubmit={handleSubmit}
            onReset={handleReset}
          >
            <Modal
              backdrop={true}
              size="lg"
              show={showAddModal}
              onHide={() => setShowAddModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>Add User</Modal.Header>
              {addUserLoader && (
                <div
                  className="spinner-center"
                  style={{
                    top: "0%",
                    left: "0%",
                    zIndex: 2000,
                  }}
                >
                  <LoaderComponent />
                </div>
              )}
              <Modal.Body
                scrollable="true"
                style={{ opacity: addUserLoader ? "0.07" : "1" }}
              >
                <div className="row mt-3">
                  <div className="col-md-4 col-sm-12">
                    <div className="d-flex flex-column">
                      <label className="form-label">First Name</label>
                      <Field
                        id="firstName"
                        className="rs-input"
                        type="text"
                        placeholder="Enter First Name"
                        name="firstName"
                      />
                      {errors.firstName && touched.firstName ? (
                        <div className="field-error">{errors.firstName}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <div className="d-flex flex-column">
                      <label className="form-label">Middle Name</label>
                      <Field
                        id="middleName"
                        className="rs-input"
                        type="text"
                        placeholder="Enter Middle Name"
                        name="middleName"
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <div className="d-flex flex-column">
                      <label className="form-label">Last Name</label>
                      <Field
                        id="lastName"
                        className="rs-input"
                        type="text"
                        placeholder="Enter Last Name"
                        name="lastName"
                      />
                      {errors.lastName && touched.lastName ? (
                        <div className="field-error">{errors.lastName}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-6">
                    <div className="d-flex flex-column">
                      <label className="form-label">Email</label>

                      <Field
                        id="email"
                        className="rs-input"
                        type="email"
                        placeholder="Enter Email"
                        name="username"
                      />
                      {errors.username && touched.username ? (
                        <div className="field-error">{errors.username}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex flex-column">
                      <label className="form-label">Confirm Email</label>

                      <Field
                        id="confirmEmail"
                        className="rs-input"
                        type="email"
                        placeholder="Confirm Email"
                        name="confirmEmail"
                      />
                      {errors.confirmEmail && touched.confirmEmail ? (
                        <div className="field-error">{errors.confirmEmail}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-6">
                    <div className="d-flex flex-column">
                      <label className="form-label">Mobile Number</label>
                      <Field
                        id="mobileNo"
                        className="rs-input"
                        type="text"
                        placeholder="Enter Mobile Number"
                        name="mobileNo"
                      />
                      {errors.mobileNo && touched.mobileNo ? (
                        <div className="field-error">{errors.mobileNo}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex flex-column">
                      <label className="form-label">Office Number</label>
                      <Field
                        id="officePhone"
                        className="rs-input"
                        type="text"
                        placeholder="Enter Office Phone"
                        name="officePhone"
                      />
                      {errors.officePhone && touched.officePhone ? (
                        <div className="field-error">{errors.officePhone}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-6">
                    <div className="d-flex flex-column">
                      <label className="form-label">Office Extention</label>
                      <Field
                        id="officePhoneExt"
                        className="rs-input"
                        type="text"
                        placeholder="Enter Office Extension"
                        name="officePhoneExt"
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex flex-column">
                      <label className="form-label">Employee Code</label>
                      <Field
                        id="empCode"
                        className="rs-input"
                        type="text"
                        placeholder="Employee Code"
                        name="empCode"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 mt-3">
                    <div className="d-flex flex-column">
                      <Select
                        id="team"
                        placeholder="Select Team"
                        options={teamOptions}
                        onChange={handleTeamOptions}
                        isClearable
                        isSearchable
                        classNamePrefix={"team"}
                      />
                      {!selectedTeam ? (
                        <div className="field-error">Select Team!</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12">
                    <Select
                      id="role"
                      placeholder="Select Role"
                      value={labeledRoles.filter((obj) =>
                        selectedValue.includes(obj.value)
                      )}
                      options={labeledRoles}
                      onChange={handleSelectServices}
                      isMulti
                      isClearable
                      isSearchable
                      classNamePrefix={"role"}
                    />
                    {selectedValue.length === 0 ? (
                      <div className="field-error">
                        Select atleast one role!
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12">
                    <label className="form-label">User Access</label> <br />
                    <Field
                      id="restrictedUser"
                      type="checkbox"
                      name="restrictedUser"
                      checked={restrictedUser}
                      onChange={(e) => setRestrictedUser(e.target.checked)}
                    />
                    <label className="pl-2">Restricted</label>
                  </div>
                  {restrictedUser && (
                    <>
                      <div className="col-6 filter-item mt-2">
                        {formattedData && (
                          <RegionDropDown
                            onChange={onChange}
                            data={formattedData}
                          />
                        )}
                        {selectedRegions?.length === 0 ? (
                          <div className="field-error">Region is required*</div>
                        ) : null}
                      </div>
                      <div className="col-6 mt-1">
                        {filterData.map(
                          ({
                            filterName,
                            filterType,
                            filterLabel,
                            showOnScreen,
                            filterId,
                          }) => (
                            <div className="filter-item mt-1">
                              <Filter
                                id="resource_type"
                                title={filterLabel}
                                filterId={filterId}
                                type={filterType}
                                data={filter[filterName]?.data}
                                filter={filter[filterName]?.filter}
                                removable={!showOnScreen}
                                onChange={(e) =>
                                  handleFilterChange(
                                    e,
                                    filterName,
                                    filter,
                                    setFilter
                                  )
                                }
                                onRemove={(e) =>
                                  handleNewFilter(
                                    e,
                                    filterData,
                                    showFilters,
                                    setFilter,
                                    setFilterData
                                  )
                                }
                              />
                            </div>
                          )
                        )}
                        {filter?.resource_type?.filter?.length === 0 ? (
                          <div className="field-error">
                            Select atleast one Resource type!
                          </div>
                        ) : null}
                      </div>

                      {resourcesData &&
                        resourcesData?.map((item) => (
                          <>
                            {item.resourceData !== null && (
                              <div
                                className="col-12 mt-3"
                                key={item.resourceType}
                              >
                                <label className="form-label">
                                  Select {item?.resourceType}
                                </label>
                                <Select
                                  id="resourceType"
                                  placeholder="Select Option"
                                  options={resourceTypeOptions(
                                    item?.resourceData
                                  )}
                                  value={resourceTypeOptions(
                                    item?.resourceData
                                  )?.filter((obj) => {
                                    return reconAccountIds[
                                      item.resourceType
                                    ]?.includes(obj.value);
                                  })}
                                  classNamePrefix={"resourceType"}
                                  isMulti
                                  isSearchable
                                  isClearable={true}
                                  className="no-border"
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderRadius: "10px",
                                    }),
                                  }}
                                  onChange={(selectedOptions) =>
                                    handleResourceOptions(
                                      selectedOptions,
                                      item.resourceType
                                    )
                                  }
                                />
                              </div>
                            )}
                          </>
                        ))}
                    </>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex">
                  <ButtonBasic
                    id="cancel"
                    title="Cancel"
                    onClick={() => {
                      setShowAddModal(false);
                      setSelectedValue([]);
                      handleReset();
                    }}
                  />
                  <button
                    id="submit"
                    type="submit"
                    name="submit"
                    className={"btn btn-info btn-md mr-2"}
                    onClick={handleSubmit}
                  >
                    Add
                  </button>
                </div>
              </Modal.Footer>
            </Modal>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddUserModal;

class RegionDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = { data: props.data };
    this.arraysAreEqual = this.arraysAreEqual.bind(this);
  }

  arraysAreEqual(ary1, ary2) {
    return ary1.join("") === ary2.join("");
  }
  componentWillReceiveProps = (nextProps) => {
    if (!(nextProps.data, this.state.data)) {
      this.setState({ data: nextProps.data });
    }
  };

  shouldComponentUpdate = (nextProps) => {
    return !this.arraysAreEqual(nextProps.data, this.state.data);
  };

  render() {
    const { data, ...rest } = this.props;

    return (
      <FilterDropdownStyle className={`dropdown `}>
        <button
          className={`d-flex align-items-center justify-content-between btn tsg-dropdown w-100 py-1 px-2`}
          type="button"
          id="dropdownMenuButtonRegion"
          data-toggle={"dropdown"}
        >
          <span>Select Region</span>
          <img className="dropdown-icon" src={chevronDown} alt={"chev"} />
        </button>

        <div className={`dropdown-menu fancy-scroll`}>
          <DropdownTreeSelect
            id="dropdownMenuButtonRegion"
            onChange={this.props.onChange}
            className="bootstrap-demo"
            expanded="true"
            showDropdown="always"
            keepOpenOnSelect={true}
            clearSearchOnChange={true}
            inlineSearchInput={true}
            data={this.state.data || []}
            isDefaultValue={true}
            {...rest}
            radioSelect={true}
          />
        </div>
      </FilterDropdownStyle>
    );
  }
}
