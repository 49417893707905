import React, { useState, useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import axiosInstance from "../../../utils/axiosInstance";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import ShowForPermission from "../../../utils/permissionsWrapper";
import Toast from "../../../components/Toast/Toast";
import LoaderComponent from "../../../components/Spinner/LoaderComponent";
import NewFilter from "../../../components/FilterDropdown/NewFilter";
import GoogleMap from "../../../containers/GoogleMap/NewMap";
import AddCassettesConfiguration from "./AddCassettesConfiguration";

const AtmSchema = Yup.object().shape({
  terminalId: Yup.string().required("Terminal Id is required"),
  //ipPort: Yup.string().required("IP Port is required"),
  deviceLocation: Yup.string().required("Device Location is required"),
});

const AtmManagement = (props) => {
  const [regions, setRegions] = useState([]);
  const [atmType, setAtmType] = useState([]);
  const [atmId, setAtmId] = useState("");
  const [atmLoader, setAtmLoader] = useState(true);
  const [regionId, setRegionId] = useState("");
  const [regionsLoader, setRegionsLoader] = useState(true);
  const [cit, setCit] = useState([]);
  const [citId, setCitId] = useState("");
  const [citLoader, setCitLoader] = useState(true);
  const [branch, setBranch] = useState([]);
  const [branchId, setBranchId] = useState("");
  const [branchLoader, setBranchLoader] = useState(true);
  const [pos, setPos] = useState({ lat: "", lng: "" });
  const [vendorType, setVendorType] = useState("");
  const [rowsData, setRowsData] = useState([]);
  const [glAccounts, setGlAccounts] = useState([]);
  const [value, setValue] = useState(null);

  const addTableRows = () => {
    const rowsInput = {
      type: "",
      denom: "",
      currency: "",
      casUnit: "",
    };

    setRowsData([...rowsData, rowsInput]);
  };
  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  };

  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    setRowsData(rowsInput);
  };

  const [vendor] = useState([
    {
      id: 51,
      label: "NCR",
    },
    {
      id: 52,
      label: "Diebold-Nixdorf",
    },
  ]);
  const [breadCrumbState] = useState([
    {
      id: 0,
      title: "ATM Management",
      isActive: false,
      routeTo: "/atmlist",
    },
    {
      id: 1,
      title: "Add ATM",
      isActive: true,
      routeTo: "/atm-management",
    },
  ]);
  const ATMInitialValues = {
    terminalId: "",
    citTerminalId: "",
    ipPort: "",
    atm: false,
    cashDepositModule: false,
    chequeDepositModule: false,
    sdm: false,
    deviceName: "",
    deviceLocation: "",
    longitude: "",
    latitude: "",
    replenishmentType: "",
    swap: "",
    add: "",
    status: "active",
    atmCashGlAccount: "",
  };

  const mapRegions = (regions) => {
    const arr = [];
    // eslint-disable-next-line
    regions.map((region) => {
      arr.push({
        id: region.codeValue,
        label: region.label,
      });
      if (region.subClass) {
        arr.push(...mapRegions(region.subClass));
      }
    });
    return arr;
  };

  const getGlAccounts = async () => {
    axiosInstance
      .get(`/accounts/account-list?type_indicator=gl`)
      .then((res) => {
        setGlAccounts(
          res?.data?.accounts?.map((i) => {
            return {
              label: i?.name,
              value: i?.id,
            };
          })
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getRegions = async () => {
    try {
      let response = await axiosInstance.get(
        "/hierarchical/filters?filter_name=region"
      );
      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
        setRegionsLoader(false);
      } else {
        setRegions(mapRegions(response.data?.filters[0].filterData));
        setRegionsLoader(false);
      }
    } catch (error) {
      console.log(error);
      setRegionsLoader(false);
    }
  };

  const getCit = async () => {
    try {
      let response = await axiosInstance.get("/cits");
      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
        setCitLoader(false);
      } else {
        let cit = response.data.cits.map((cit) => {
          return {
            id: cit.id,
            label: cit.name,
          };
        });
        setCit(cit);
        setCitLoader(false);
      }
    } catch (error) {
      console.log(error);
      setCitLoader(false);
    }
  };

  const getBranches = async () => {
    try {
      let response = await axiosInstance.get("/branches/concise");
      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
        setBranchLoader(false);
      } else {
        let branches = response.data.branch.map((branch) => {
          return {
            id: branch.id,
            label: branch.name,
          };
        });
        console.log("branches...", branches);
        setBranch(branches);
        setBranchLoader(false);
      }
    } catch (error) {
      console.log(error);
      setBranchLoader(false);
    }
  };

  const getAtmType = async () => {
    try {
      let response = await axiosInstance.get(
        "/hierarchical/filters?filter_name=atm_type"
      );
      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
        setAtmLoader(false);
      } else {
        setAtmType(mapRegions(response.data?.filters[0]?.filterData));
        setAtmLoader(false);
      }
    } catch (error) {
      console.log(error);
      setAtmLoader(false);
    }
  };

  const saveAtm = async (values) => {
    try {
      const data = {
        device: {
          terminalId: values.terminalId,
          citTerminalId: values.citTerminalId,
          ipPort: values.ipPort,
          vendorType: vendorType,
          deviceType: {
            atm: values.atm,
            cashDepositModule: values.cashDepositModule,
            chequeDepositModule: values.chequeDepositModule,
            sdm: values.sdm,
          },
          deviceName: values.deviceName,
          deviceLocation: values.deviceLocation,
          deviceRegion: regionId,
          longitude: pos.lng,
          latitude: pos.lat,
          replenishmentType:
            values.swap === true ? "SWAP" : values.add === true ? "ADD" : "",
          status: values.status,
          atmCashGlAccount:
            values.atmCashGlAccount === "" ? null : values.atmCashGlAccount,
          citVendor: citId === "" ? null : citId,
          branchId: branchId === "" ? null : branchId,
          atmCassetteConfig: rowsData,
          cashDepositModuleCassetteConfig: {},
          chequeDepositModuleCassetteConfig: {},
          atmType: atmId,
        },
      };
      let response = await axiosInstance.post("/devices", { data });
      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
      } else {
        Toast("ATM added successfully!", "success");
        props.history.push("/atmlist");
      }
    } catch (err) {
      Toast(err.response.data.message.description, "error");
    }
  };

  useEffect(() => {
    getRegions();
    getCit();
    getBranches();
    getAtmType();
    getGlAccounts();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {citLoader && regionsLoader && atmLoader && branchLoader && (
        <div
          className="spinner-center"
          style={{ top: "0%", left: "0%", zIndex: 2000 }}
        >
          <LoaderComponent />
        </div>
      )}
      <div
        style={{
          opacity:
            !citLoader || !regionsLoader || !atmLoader || !branchLoader
              ? "1"
              : "0.07",
        }}
        className="rs-basic-card"
      >
        <div className="row">
          <div className="col-sm-12 col-lg-6">
            <Breadcrumbs
              data={breadCrumbState}
              history={props.history}
              onClick={(e) => {}}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="d-flex align-items-center mt-3">
              <div
                style={{
                  paddingRight: "10px",
                  marginRight: "10px",
                  fontFamily: "Montserrat SemiBold",
                }}
              >
                ATM Records
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <Formik
              initialValues={ATMInitialValues}
              validationSchema={AtmSchema}
              onSubmit={(values) => {
                if (vendorType !== "" && regionId !== "") {
                  saveAtm(values);
                }
              }}
            >
              {({ errors, touched, handleSubmit, values, setFieldValue }) => (
                <Form id="add-atm" className="form" onSubmit={handleSubmit}>
                  <Tabs
                    defaultActiveKey="user"
                    transition={false}
                    id="noanim-tab-example"
                    className="mb-3"
                  >
                    {/* //#region Form1 */}
                    <Tab
                      className="mb-sm-2 mb-md-0"
                      eventKey="user"
                      title="General Configuration"
                    >
                      <div className="container">
                        <div className="row mt-5">
                          <div className="col-sm-12 col-md-3 col-lg-3">
                            <label className="form-label">TERMINAL ID</label>
                            <Field
                              id="terminalId"
                              className="form-control rs-input"
                              type="text"
                              placeholder="Enter Terminal Id"
                              name="terminalId"
                            />
                            {touched.terminalId && errors.terminalId ? (
                              <div className="field-error">
                                {errors.terminalId}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-sm-12 col-md-3 col-lg-3">
                            <label className="form-label">
                              CIT DEFINED TERMINAL ID
                            </label>
                            <Field
                              id="citTerminalId"
                              className="form-control rs-input"
                              type="text"
                              placeholder="Enter CIT Terminal ID"
                              name="citTerminalId"
                            />
                          </div>
                          <div className="col-sm-12 col-md-3 col-lg-3">
                            <label className="form-label">
                              DEVICE LOCATION
                            </label>
                            <Field
                              id="deviceLocation"
                              className="form-control rs-input"
                              type="text"
                              placeholder="Enter Device Location"
                              name="deviceLocation"
                            />
                            {touched.deviceLocation && errors.deviceLocation ? (
                              <div className="field-error">
                                {errors.deviceLocation}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-sm-12 col-md-3 col-lg-3">
                            <label className="form-label">DEVICE REGION</label>
                            <NewFilter
                              id="deviceRegion"
                              title={
                                regions.find((r) => r.id === regionId)?.label ??
                                "Select Region"
                              }
                              type={"checkbox"}
                              data={regions}
                              field={"id"}
                              filter={regionId}
                              onChange={(e) => setRegionId(e)}
                              isSearchable
                              searchField="label"
                            />
                            {regionId === "" && (
                              <div className="field-error">
                                Region is required
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="row mt-3">
                          <div className="col-sm-12 col-md-3 col-lg-3">
                            <label className="form-label">
                              IP ADDRESS : PORT
                            </label>
                            <Field
                              id="ipPort"
                              className="form-control rs-input"
                              type="text"
                              placeholder="Enter IP Address:Port"
                              name="ipPort"
                            />
                            {touched.ipPort && errors.ipPort ? (
                              <div className="field-error">{errors.ipPort}</div>
                            ) : null}
                          </div>
                          <div className="col-sm-12 col-md-3 col-lg-3">
                            <label className="form-label">DEVICE NAME</label>
                            <Field
                              id="deviceName"
                              className="form-control rs-input"
                              type="text"
                              placeholder="Enter Device Name"
                              name="deviceName"
                            />
                          </div>

                          <div className="col-sm-12 col-md-3 col-lg-3">
                            <label className="form-label">LATITUDE</label>
                            <input
                              id="latitude"
                              className="form-control rs-input"
                              type="text"
                              placeholder="Enter Latitude"
                              name="latitude"
                              value={pos?.lat}
                              onChange={(e) =>
                                setPos({ ...pos, lat: e.target.value })
                              }
                            />
                            {pos?.lat === "" ? (
                              <div className="field-error">Required field*</div>
                            ) : null}
                          </div>
                          <div className="col-sm-12 col-md-3 col-lg-3">
                            <label className="form-label">LONGITUDE</label>
                            <input
                              id="longitude"
                              className="form-control rs-input"
                              type="text"
                              placeholder="Enter Longitude"
                              name="longitude"
                              value={pos.lng}
                              onChange={(e) =>
                                setPos({ ...pos, lng: e.target.value })
                              }
                            />
                            {pos?.lng === "" ? (
                              <div className="field-error">Required field*</div>
                            ) : null}
                          </div>
                        </div>

                        <div className="row mt-3">
                          <div className="container col-sm-12 col-md-6 col-lg-6">
                            <div className="row">
                              <div className="col-sm-12 col-md-6 col-lg-6">
                                <label className="form-label">VENDOR</label>
                                <NewFilter
                                  id="AtmVendor"
                                  title={
                                    vendor.find((i) => i.label === vendorType)
                                      ?.label ?? "Select Vendor"
                                  }
                                  type={"checkbox"}
                                  field={"label"}
                                  data={vendor}
                                  filter={vendorType}
                                  onChange={(e) => setVendorType(e)}
                                  isSearchable
                                  searchField="label"
                                />
                                {vendorType === "" && (
                                  <div className="field-error">
                                    Vender Type is required
                                  </div>
                                )}
                              </div>
                              <div className="col-sm-12 col-md-6 col-lg-6">
                                <label className="form-label">CIT</label>
                                <NewFilter
                                  id="AtmCIT"
                                  title={
                                    cit.find((i) => i.id === citId)?.label ??
                                    "Select CIT"
                                  }
                                  type={"checkbox"}
                                  field={"id"}
                                  data={cit}
                                  filter={citId}
                                  onChange={(e) => setCitId(e)}
                                  isSearchable
                                  searchField="label"
                                />
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-sm-12 col-md-12 col-lg-6">
                                <label className="form-label">
                                  DEVICE TYPE
                                </label>
                                <div className="d-flex flex-row flex-wrap">
                                  <div className="custom-control custom-checkbox mr-2">
                                    <Field
                                      id="atm"
                                      type="checkbox"
                                      className="custom-control-input"
                                      name="atm"
                                    />
                                    <label className="custom-control-label">
                                      ATM
                                    </label>
                                  </div>
                                  <div className="custom-control custom-checkbox mr-2">
                                    <Field
                                      id="cashDepositModule"
                                      type="checkbox"
                                      className="custom-control-input"
                                      name="cashDepositModule"
                                    />
                                    <label className="custom-control-label">
                                      Cash Deposit Module
                                    </label>
                                  </div>
                                  <div className="custom-control custom-checkbox mr-2">
                                    <Field
                                      id="chequeDepositModule"
                                      type="checkbox"
                                      className="custom-control-input"
                                      name="chequeDepositModule"
                                    />
                                    <label className="custom-control-label">
                                      Cheque Deposit Module
                                    </label>
                                  </div>
                                  <div className="custom-control custom-checkbox mr-2">
                                    <Field
                                      id="sdm"
                                      type="checkbox"
                                      className="custom-control-input"
                                      name="sdm"
                                    />
                                    <label className="custom-control-label">
                                      SDM
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-sm-12 col-md-6 col-lg-6">
                                <label className="form-label">
                                  ATM CASH GL ACCOUNT
                                </label>
                                {/* <Field
                                  className="form-control rs-input"
                                  type="text"
                                  placeholder="Enter GL Account"
                                  name="atmCashGlAccount"
                                /> */}
                                <NewFilter
                                  id="AtmCashGL"
                                  title={
                                    glAccounts.find(
                                      (i) =>
                                        i?.value?.toString() ===
                                        values?.atmCashGlAccount?.toString()
                                    )?.label ?? "Select GL Account"
                                  }
                                  type={"checkbox"}
                                  field={"value"}
                                  data={glAccounts}
                                  filter={parseInt(values?.atmCashGlAccount)}
                                  onChange={(e) =>
                                    setFieldValue("atmCashGlAccount", e)
                                  }
                                  isSearchable
                                  searchField="label"
                                />
                              </div>
                              <div className="col-sm-12 col-md-6 col-lg-6">
                                <label className="form-label">BRANCH</label>
                                <NewFilter
                                  id="AtmBranch"
                                  title={
                                    branch.find((i) => i.id === branchId)
                                      ?.label ?? "Select Branch"
                                  }
                                  type={"checkbox"}
                                  field={"id"}
                                  data={branch}
                                  filter={branchId}
                                  onChange={(e) => setBranchId(e)}
                                  isSearchable
                                  searchField="label"
                                />
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="col-sm-6 col-md-6 col-lg-6">
                                <label className="form-label">
                                  REPLENISHMENT TYPE
                                </label>
                                <div className="d-flex flex-row flex-wrap">
                                  <div className="custom-control custom-checkbox mr-2">
                                    <Field
                                      id="swap"
                                      type="checkbox"
                                      className="custom-control-input"
                                      name="swap"
                                      onClick={() => {
                                        values.swap = true;
                                        values.add = false;
                                      }}
                                    />
                                    <label className="custom-control-label">
                                      Swap
                                    </label>
                                  </div>
                                  <div className="custom-control custom-checkbox mr-2">
                                    <Field
                                      id="add"
                                      type="checkbox"
                                      className="custom-control-input"
                                      name="add"
                                      onClick={() => {
                                        values.add = true;
                                        values.swap = false;
                                      }}
                                    />
                                    <label className="custom-control-label">
                                      Add
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6">
                                <label className="form-label">ATM TYPE</label>
                                <NewFilter
                                  id="AtmType"
                                  title={
                                    atmType.find(
                                      (r) =>
                                        r?.id?.toString() === atmId?.toString()
                                    )?.label ?? "Select ATM Type"
                                  }
                                  type={"checkbox"}
                                  data={atmType}
                                  field={"id"}
                                  filter={atmId ?? ""}
                                  onChange={(e) => setAtmId(e)}
                                  isSearchable
                                  searchField="label"
                                />
                                {atmId === "" && (
                                  <div className="field-error">
                                    ATM Type is required
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="col-sm-12 col-md-12 col-lg-12">
                                <label className="form-label">STATUS</label>
                                <Field
                                  id="status"
                                  name="status"
                                  render={({ field }) => (
                                    <div
                                      className="d-flex"
                                      style={{ marginLeft: "-20px" }}
                                    >
                                      <div className="form-check">
                                        <input
                                          {...field}
                                          id="active"
                                          value="active"
                                          checked={field.value === "active"}
                                          name="status"
                                          type="radio"
                                        />
                                        <label
                                          className="form-check-label ml-1"
                                          htmlFor="Active"
                                        >
                                          Active
                                        </label>
                                      </div>

                                      <div className="form-check">
                                        <input
                                          {...field}
                                          id="inActive"
                                          value="inActive"
                                          name="status"
                                          checked={field.value === "inActive"}
                                          type="radio"
                                        />
                                        <label
                                          className="form-check-label ml-1"
                                          htmlFor="inActive"
                                        >
                                          In Active
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-12 col-md-6 col-lg-6">
                            <GoogleMap
                              className="ml-4"
                              mapStyle={{ width: "100%", height: "35vh" }}
                              onClick={(lat, lng) => {
                                setPos({ lat, lng });
                              }}
                              pos={pos}
                              center={{ lat: pos.lat, lng: pos.lng }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="d-flex mt-5 ml-3">
                          <ShowForPermission permission="clk_atm_edit">
                            <button
                              id="addAtm"
                              type="submit"
                              name="submit"
                              className={"btn btn-info btn-md"}
                              onClick={handleSubmit}
                            >
                              Add ATM
                            </button>
                          </ShowForPermission>
                        </div>
                      </div>
                      {/* #endregion */}
                    </Tab>

                    <Tab
                      className="mb-sm-2 mb-md-0"
                      eventKey="permissions2"
                      title="ATM Cassette Configuration"
                    >
                      <div className="row mt-5 d-flex justify-content-center  align-items-center">
                        <div className="col-2">
                          <div className="text-center">CASSETTE UNIT</div>
                        </div>
                        <div className="col-2">
                          <div className="text-center">TYPE</div>
                        </div>
                        <div className="col-2">
                          <div className="text-center">CURRENCY</div>
                        </div>
                        <div className="col-2">
                          <div className="text-center">DENOMINATION</div>
                        </div>
                        <div className="col-2">
                          <button
                            id="addBtn"
                            className="btn btn-outline-success"
                            type="button"
                            onClick={addTableRows}
                          >
                            +
                          </button>
                        </div>
                      </div>

                      <AddCassettesConfiguration
                        rowsData={rowsData}
                        deleteTableRows={deleteTableRows}
                        handleChange={handleChange}
                      />
                    </Tab>
                  </Tabs>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default AtmManagement;
