import React, { useState, useEffect } from "react";
import Select from "react-select";
import NewFilter from "../../../components/FilterDropdown/NewFilter";
import axiosInstance from "../../../utils/axiosInstance";
import ButtonBasic from "../../../components/Buttons/ButtonBasic";
import Toast from "../../../components/Toast/Toast";

const ReconModal = ({
  setShowModal,
  dsA,
  dsB,
  pairId,
  updateReconPair,
  masterDataSource,
}) => {
  const [datasourceA, setDatasourceA] = useState(dsA?.dsaId);
  const [datasourceB, setDatasourceB] = useState(dsB?.dsbId);
  const [toRecordTypes, setToRecordTypes] = useState([]);
  const [fromRecordTypes, setFromRecordTypes] = useState([]);
  const [selectedToRecordTypes, setSelectedToRecordTypes] = useState(null);
  const [selectedFromRecordTypes, setSelectedFromRecordTypes] = useState(null);
  const toDatasourceRecordTypes = (toDatasourceId) => {
    axiosInstance
      .get("recon/wfm-config-fields?masterDatasourceId=" + toDatasourceId)
      .then((res) => {
        if (res?.message?.status == 200) {
          setToRecordTypes(
            res?.data?.interfaceRecordTypes?.map((x) => ({
              label: x.recordType,
              value: x.recordType,
              ...x,
            }))
          );
          setSelectedToRecordTypes(
            res?.data?.interfaceRecordTypes?.find(
              (option) =>
                option?.interfaceId == dsB?.dsBInterfaceId &&
                option?.interfaceFormatId == dsB?.dsBInterfaceFormatId
            )?.recordType
          );
        } else {
          setToRecordTypes([]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fromDatasourceRecordTypes = (fromDatasourceId) => {
    axiosInstance
      .get("recon/wfm-config-fields?masterDatasourceId=" + fromDatasourceId)
      .then((res) => {
        if (res?.message?.status == 200) {
          setFromRecordTypes(
            res?.data?.interfaceRecordTypes?.map((x) => ({
              label: x.recordType,
              value: x.recordType,
              ...x,
            }))
          );
          setSelectedFromRecordTypes(
            res?.data?.interfaceRecordTypes?.find(
              (option) =>
                option?.interfaceId == dsA?.dsAInterfaceId &&
                option?.interfaceFormatId == dsA?.dsAInterfaceFormatId
            )?.recordType
          );
        } else {
          setFromRecordTypes([]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (
      masterDataSource?.find((r) => r.id === datasourceA)?.isPostStagingTable ==
      false
    ) {
      setSelectedFromRecordTypes(null);
      fromDatasourceRecordTypes(datasourceA);
    }
    if (
      masterDataSource?.find((r) => r.id === datasourceB)?.isPostStagingTable ==
      false
    ) {
      setSelectedToRecordTypes(null);
      toDatasourceRecordTypes(datasourceB);
    }
  }, [masterDataSource, datasourceA, datasourceB]);

  return (
    <>
      <div className="col-12">
        <div>
          <div className="row">
            <div className="col-12">
              <div className="workflowModalTitle">Data Source A</div>
              <NewFilter
                title={
                  masterDataSource.find((r) => r.id === datasourceA)
                    ?.datasource ?? "Data Source A"
                }
                type={"checkbox"}
                data={masterDataSource}
                field={"id"}
                filter={datasourceA}
                isSearchable
                searchField="datasource"
                onChange={(e) => setDatasourceA(e)}
              />
            </div>
            <div className="col-12 mt-3">
              <div className="workflowModalTitle">Data Source B</div>
              <NewFilter
                title={
                  masterDataSource.find((r) => r.id === datasourceB)
                    ?.datasource ?? "Data Source B"
                }
                type={"checkbox"}
                data={masterDataSource}
                field={"id"}
                filter={datasourceB}
                isSearchable
                searchField="datasource"
                onChange={(e) => setDatasourceB(e)}
              />
            </div>
          </div>
          <div className="row mt-3">
            {masterDataSource?.find((r) => r.id === datasourceA)
              ?.isPostStagingTable == false && (
              <div className="col-12">
                <div className="workflowModalTitle">DSA Record Type</div>
                <Select
                  options={fromRecordTypes}
                  value={fromRecordTypes?.find((option) =>
                    option?.label == selectedFromRecordTypes
                      ? true
                      : option?.interfaceId == dsA?.dsAInterfaceId &&
                        option?.interfaceFormatId == dsA?.dsAInterfaceFormatId
                      ? true
                      : false
                  )}
                  onChange={(e) => {
                    setSelectedFromRecordTypes(e.value);
                  }}
                  placeholder={"Select Record Type"}
                />
              </div>
            )}
            {masterDataSource?.find((r) => r.id === datasourceB)
              ?.isPostStagingTable == false && (
              <div className="col-12 mt-3">
                <div className="workflowModalTitle">DSB Record Type</div>
                <Select
                  options={toRecordTypes}
                  value={toRecordTypes?.find((option) =>
                    option?.label == selectedToRecordTypes
                      ? true
                      : option?.interfaceId == dsB?.dsBInterfaceId &&
                        option?.interfaceFormatId == dsB?.dsBInterfaceFormatId
                      ? true
                      : false
                  )}
                  onChange={(e) => {
                    setSelectedToRecordTypes(e.value);
                  }}
                  placeholder={"Select Record Type"}
                />
              </div>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-end mt-4">
          <ButtonBasic title="Cancel" onClick={() => setShowModal(false)} />
          <ButtonBasic
            title="Save"
            onClick={() => {
              if (
                masterDataSource?.find((r) => r.id === datasourceA)
                  ?.isPostStagingTable == false &&
                !selectedFromRecordTypes
              ) {
                Toast(
                  "Please select DSA Record Type",
                  "error"
                );
                return;
              }
              if (
                masterDataSource?.find((r) => r.id === datasourceB)
                  ?.isPostStagingTable == false &&
                !selectedToRecordTypes
              ) {
                Toast(
                  "Please select DSB Record Type",
                  "error"
                );
                return;
              }
              updateReconPair(
                masterDataSource.find((r) => r.id === datasourceA)?.datasource,
                masterDataSource.find((r) => r.id === datasourceB)?.datasource,
                datasourceA,
                datasourceB,
                pairId,
                fromRecordTypes?.find(
                  (option) => option?.label == selectedFromRecordTypes
                )?.interfaceFormatId ?? 0,
                fromRecordTypes?.find(
                  (option) => option?.label == selectedFromRecordTypes
                )?.interfaceId ?? 0,
                toRecordTypes?.find(
                  (option) => option?.label == selectedToRecordTypes
                )?.interfaceFormatId ?? 0,
                toRecordTypes?.find(
                  (option) => option?.label == selectedToRecordTypes
                )?.interfaceId ?? 0
              );
              setShowModal(false);
            }}
          />
        </div>
      </div>
    </>
  );
};
export default ReconModal;
