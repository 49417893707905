import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Collapse, Modal } from "react-bootstrap";
import AddIcon from "../../../assets/images/add-icon.png";
import Toast from "../../../components/Toast/Toast";
import ButtonBasic from "../../../components/Buttons/ButtonBasic";
import ButtonTransparent from "../../../components/Buttons/ButtonTransparent";
import {
  createInterface,
  getInterfaces,
  getDataSource,
  updateInterface,
  deleteInterface,
} from "../../../services/interfaceService";
import BadgeRounded from "../../../components/BadgeRounded/BadgeRounded";
import TrashIcon from "../../../assets/images/trash.png";
import EditIcon from "../../../assets/images/edit.png";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import LoaderComponent from "../../../components/Spinner/LoaderComponent";
import ShowForPermission from "../../../utils/permissionsWrapper";
import withDateTime from "../../../utils/timedatehoc";
import NewFilter from "../../../components/FilterDropdown/NewFilter";
import axiosInstance from "../../../utils/axiosInstance";
import Pagination from "../../../components/pagination/pagination";
const cronParser = require("cron-parser");
const InterfaceSchema = Yup.object().shape({
  interfaceName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required*"),
  dataSource: Yup.string().required("Required*"),
  format: Yup.string().required("Required*"),
  outboundFolder: Yup.string().required("Required*"),
  filenamePrefix: Yup.string().required("Required*"),
  skipRecordsFromStart: Yup.number().typeError("Must be a number").nullable(),
  skipRecordsFromEnd: Yup.number().typeError("Must be a number").nullable(),
  skipSpecificLineNumber: Yup.string()
    .nullable()
    .test(
      "commaSeparatedNumbers",
      "Invalid format. Only Comma Separated Values are allowed.",
      (value) => {
        if (!value) return true;
        const values = value
          .split(",")
          .map(Number)
          .filter((num) => !isNaN(num) && num >= 0);
        return values.length > 0;
      }
    )
    .transform((value, originalValue) => {
      if (typeof originalValue === "string") {
        return originalValue.replace(/(^\s*,)|(,\s*$)/g, "");
      }
      return value;
    }),
  inboundFolder: Yup.string().required("Required*"),
  rawKafkaTopic: Yup.string().required("Required*"),
  processedKafkaTopic: Yup.string().required("Required*"),
  schedule: Yup.string()
    .required("Required*")
    .test("isValidCron", "Invalid cron expression", (value) => {
      try {
        const options = {
          currentDate: new Date(),
          endDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
        };
        cronParser.parseExpression(value, options);
        return true;
      } catch (error) {
        return false;
      }
    }),
  exceptionFolder: Yup.string().required("Required*"),
  errorKafkaTopic: Yup.string().required("Required*"),
  stagingDataProcessorClass: Yup.string()
    .required("Required*")
    .typeError("Required*"),
  expectedFilesCount: Yup.number()
    .required("Required*")
    .typeError("Must be a number"),
  // isEncryptedFile: Yup.string().nullable().required("Required*"),
  // secretKeyFilePath: Yup.string().when("isEncryptedFile", {
  //   is: (isEncryptedFile) => isEncryptedFile?.toString() === "true",
  //   then: Yup.string().required("Required*"),
  // }),
  // secretKeyPassword: Yup.string().when("isEncryptedFile", {
  //   is: (isEncryptedFile) => isEncryptedFile?.toString() === "true",
  //   then: Yup.string().required("Required*"),
  // }),
});
class InterfaceConfigration extends React.Component {
  constructor(props) {
    super(props);
    this.getActions = this.getActions.bind(this);
    this.onSortTable = this.onSortTable.bind(this);
    this.state = {
      modalShow: false,
      interfaceListLoading: true,
      dataSourcesLoading: true,
      interfaceList: [],
      dataSources: [],
      stagingData: [],
      formatData: [],
      interfaceStatus: false,
      isEdit: false,
      isShowInterface: false,
      isExpand: false,
      isView: false,
      interfaceData: {
        interfaceName: "",
        dataSource: "",
        format: "",
        active: true,
        inboundFolder: "",
        outboundFolder: "",
        exceptionFolder: "",
        filenamePrefix: "",
        skipRecordsFromStart: "",
        skipRecordsFromEnd: "",
        skipSpecificLineNumber: "",
        allowSpecificLines: "",
        rawKafkaTopic: "",
        processedKafkaTopic: "",
        errorKafkaTopic: "",
        schedule: "",
        streamProcessorMethod: "",
        masterDataSourceId: "",
        stagingDataProcessorClass: "",
        expectedFilesCount: 1,
        checkFileNameDuplicate: false,
        checkFileContentDuplicate: false,
       // isEncryptedFile: false,
        secretKeyFilePath: "",
        secretKeyPassword: "",
      },
      sort: { sort_by: "", sort_order: "" },
      pagination: {
        page: 1,
        pageSize: 15,
        totalPages: 0,
        totalElements: 0,
      },
      editInterfaceId: -1,
      statusData: [
        {
          id: true,
          param1: "1",
          label: "Active",
        },
        {
          id: false,
          param1: "0",
          label: "Inactive",
        },
      ],
      colorData: [
        {
          id: 1,
          param1: "1",
          label: "#fff",
        },
        {
          id: 2,
          param1: "0",
          label: "#000",
        },
        {
          id: 3,
          param1: "0",
          label: "#331",
        },
        {
          id: 4,
          param1: "0",
          label: "#90A",
        },
      ],
    };
  }

  componentDidMount() {
    this.getInteracesData();
    this.getResouces();
    this.props.dateTime.setRefresh(() => () => {
      this.setState(
        { interfaceListLoading: true, dataSourcesLoading: true },
        () => {
          this.getInteracesData();
          this.getResouces();
        }
      );
    });
    axiosInstance
      .get("/common/code?codeTypeId=1900")
      .then((res) => {
        this.setState({
          stagingData: res.data.map((x) => ({
            ...x,
            label: x.description,
          })),
        });
      })
      .catch((err) => {
        console.log(err);
      });
    axiosInstance
      .get("/common/code?codeTypeId=1800")
      .then((res) => {
        this.setState({
          formatData: res.data.map((x) => ({
            ...x,
            label: x.description,
          })),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentWillUnmount() {
    this.props.dateTime.setRefresh(() => () => {});
  }
  onSortTable = (type, newState) => {
    this.setState({
      pagination: {
        ...this.state.pagination,
        page: 1,
      },
    });
    this.setState({
      sort: {
        sort_by: newState.sortField,
        sort_order: newState.sortOrder,
      },
    });
    this.getInteracesData(
      1,
      this.state.pagination.pageSize,
      newState.sortField,
      newState.sortOrder
    );
  };
  async getInteracesData(page = 1, limit = 15, sort_by = "", sort_order = "") {
    try {
      this.setState({
        interfaceListLoading: true,
        dataSourcesLoading: true,
      });
      const response = await getInterfaces(page, limit, sort_by, sort_order);
      this.setState({
        interfaceList: response.data.interfaces,
        interfaceListLoading: false,
        dataSourcesLoading: false,
        pagination: {
          page: response?.page?.number + 1,
          pageSize: response?.page?.size,
          totalPages: response?.page?.totalPages,
          totalElements: response?.page?.totalElements,
        },
      });
    } catch (err) {
      this.setState({
        interfaceList: [],
        interfaceListLoading: false,
        dataSourcesLoading: false,
        pagination: {
          page: 1,
          pageSize: 15,
          totalPages: 0,
          totalElements: 0,
        },
      });
    }
  }

  async getResouces() {
    try {
      const response = await getDataSource();
      this.setState({
        dataSources:
          response.data.datasources.map((x) => ({
            ...x,
            label: x.datasource,
          })) || [],
        dataSourcesLoading: false,
      });
    } catch (err) {
      this.setState({
        dataSources: [],
        dataSourcesLoading: false,
      });
    }
  }

  async createOrUpdateInterface(data, isEditInterface, resetForm) {
    try {
      const { interfaceList } = this.state;
      const payload = {
        interfaces: data,
      };
      if (isEditInterface) {
        const response = await updateInterface(
          payload,
          this.state.editInterfaceId
        );
        if (response.message.status === "200") {
          const _interfaceList = interfaceList.map((interfaceData) => {
            if (interfaceData.interfaceId === this.state.editInterfaceId) {
              return {
                ...response.data,
              };
            }
            return interfaceData;
          });
          this.setState({
            modalShow: false,
            isEditInterface: false,
            isEdit: false,
            interfaceList: _interfaceList,
          });
          this.getInteracesData();
          Toast("Record updated successfully!", "success");
          resetForm();
        } else {
          Toast(response.message.description, "error");
        }
      } else {
        const response = await createInterface(payload);
        if (response.message.status === "200") {
          this.setState({
            modalShow: false,
            interfaceList: [...interfaceList, { ...response.interface }],
          });
          this.getInteracesData();
          Toast("Record added!", "success");
          resetForm();
        } else {
          Toast(response?.message?.description, "error");
        }
      }
    } catch (error) {
      Toast(error?.response?.data?.message?.description, "error");
    }
  }

  getInterfaceStatus(cell, row) {
    return (
      <BadgeRounded
        color={row.active ? "success" : "danger"}
        title={row.active ? "Active" : "Inactive"}
      />
    );
  }

  editInterfaceModal(interfaceId) {
    const { interfaceList } = this.state;
    const interfaceData = interfaceList.find(
      (inter) => inter.interfaceId === interfaceId
    );
    this.setState({
      modalShow: true,
      interfaceData: {
        ...interfaceData,
        active: interfaceData.active ? "true" : "false",
      },
      isEdit: true,
      editInterfaceId: interfaceId,
    });
  }

  viewInterfaceModal(interfaceId) {
    const { interfaceList } = this.state;
    const interfaceData = interfaceList.find(
      (inter) => inter.interfaceId === interfaceId
    );
    this.setState({
      modalShow: true,
      interfaceData: {
        ...interfaceData,
        active: interfaceData.active ? "true" : "false",
      },
      isView: true,
      editInterfaceId: interfaceId,
    });
  }

  getActions(cell, row) {
    return (
      <div className="d-flex" id="actions">
        <ShowForPermission permission="clk_interface_edit">
          <img
            id="actions"
            width={24}
            height={24}
            src={EditIcon}
            className="right-btn mr-2"
            alt="rightbtn"
            onClick={() => this.editInterfaceModal(row.interfaceId)}
          />
        </ShowForPermission>
        <ShowForPermission permission="clk_interface_del">
          <img
            id="actions"
            width={24}
            height={24}
            src={TrashIcon}
            className="right-btn mr-3"
            alt="rightbtn"
            onClick={() =>
              this.setState({
                isShowInterface: true,
                deleteInterfaceId: row.interfaceId,
              })
            }
          />
        </ShowForPermission>
      </div>
    );
  }

  onColorChange(e) {
    const { colorData } = this.state;
    const colorInterface = colorData.map((status) => {
      if (status.id.toString() === e.target.value.toString()) {
        return {
          ...status,
          param1: parseInt(status.param1) ? "0" : "1",
        };
      } else {
        return {
          ...status,
          param1: "0",
        };
      }
    });

    this.setState({ colorData: colorInterface });
  }

  async confirmDeleteInterface() {
    try {
      const { interfaceList } = this.state;
      const response = await deleteInterface(this.state.deleteInterfaceId);
      if (response?.message?.status === "200") {
        const interfaceListFilter = interfaceList.filter(
          (interfaceData) =>
            interfaceData.interfaceId !== this.state.deleteInterfaceId
        );
        this.setState({
          interfaceList: interfaceListFilter,
          isShowInterface: false,
        });
      }
      Toast("Record deleted successfully!", "success");
    } catch (err) {
      Toast("Error: Record not deleted!", "error");
    }
  }

  render() {
    const { pagination } = this.state;
    this.columns2 = [
      { hidden: true, dataField: "interfaceId", text: "ID" },
      {
        dataField: "interfaceName",
        text: "Interface Name",
        style: { cursor: "default" },
      },
      {
        dataField: "dataSource",
        text: "Data Source",
        style: { cursor: "default" },
      },
      {
        dataField: "format",
        text: "Format",
        style: { cursor: "default" },
      },
      {
        dataField: "active",
        text: "Status",
        formatter: this.getInterfaceStatus,
        style: { cursor: "default" },
      },
      {
        dataField: "inboundFolder",
        text: "Inbound Folder",
        style: { cursor: "default" },
      },
      {
        dataField: "rawKafkaTopic",
        text: "Raw Kafka Topic",
        style: { cursor: "default" },
      },
      {
        dataField: "processedKafkaTopic",
        text: "Processed Kafka Topic",
        style: { cursor: "default" },
      },
      {
        dataField: "schedule",
        text: "Schedule",
        style: { cursor: "default" },
      },
      {
        dataField: "amount",
        text: "Action",
        formatter: this.getActions,
      },
    ];

    this.columns1 = [
      { hidden: true, dataField: "id", text: "ID" },

      {
        dataField: "datasource",
        text: "Name",
        sort: true,
        style: { cursor: "default" },
      },
      {
        dataField: "description",
        text: "Description",
        sort: true,
        style: { cursor: "default" },
      },
    ];
    return (
      <>
        {(this.state.interfaceListLoading || this.state.dataSourcesLoading) && (
          <div
            className="spinner-center"
            style={{ top: "0%", left: "0%", zIndex: 2000 }}
          >
            <LoaderComponent />
          </div>
        )}
        <div
          style={{
            opacity:
              !this.state.interfaceListLoading || !this.state.dataSourcesLoading
                ? "1"
                : "0.07",
          }}
          className="row mt-sm-0"
        >
          <div className="col-12">
            <div className="d-flex align-items-center justify-content-between">
              <div
                style={{ fontSize: "18px" }}
                className="text-bright ml-3 mb-4"
              >
                Interfaces
              </div>
              <div className="mb-4">
                <ShowForPermission permission="clk_interface_add">
                  <ButtonTransparent
                    onClick={() =>
                      this.setState({
                        modalShow: true,
                        isEdit: false,
                        isExpand: false,
                        interfaceData: {
                          interfaceName: "",
                          dataSource: "",
                          format: "",
                          active: true,
                          inboundFolder: "",
                          outboundFolder: "",
                          exceptionFolder: "",
                          filenamePrefix: "",
                          skipRecordsFromStart: "",
                          skipRecordsFromEnd: "",
                          skipSpecificLineNumber: "",
                          allowSpecificLines: "",
                          rawKafkaTopic: "",
                          processedKafkaTopic: "",
                          errorKafkaTopic: "",
                          schedule: "",
                          streamProcessorMethod: "",
                          masterDataSourceId: "",
                          stagingDataProcessorClass: "",
                          expectedFilesCount: 1,
                          checkFileNameDuplicate: false,
                          checkFileContentDuplicate: false,
                         // isEncryptedFile: false,
                          secretKeyFilePath: "",
                          secretKeyPassword: "",
                        },
                      })
                    }
                    title={"Add Interface"}
                    icon={AddIcon}
                  />
                </ShowForPermission>
              </div>
            </div>
            <div className="col-12 ">
              <div className="overflow-y-auto fancy-scroll">
                <div>
                  <BootstrapTable
                    bordered={false}
                    bootstrap4={true}
                    classes="rs-table table-layout-auto mb-0 table-custom-style"
                    wrapperClasses="overflow-y-auto mb-2 fancy-scroll"
                    keyField="interfaceId"
                    data={this.state.interfaceList}
                    columns={this.columns2}
                    noDataIndication="No data found!"
                    remote={{ sort: true }}
                    onTableChange={this.onSortTable}
                    rowEvents={{
                      onClick: (e, row) => {
                        if (e.target.id !== "actions") {
                          this.viewInterfaceModal(row.interfaceId);
                        }
                      },
                    }}
                  ></BootstrapTable>
                  <Pagination
                    setPage={(page) => {
                      this.getInteracesData(page, pagination?.pageSize);
                    }}
                    page={pagination?.page}
                    pageSize={pagination?.pageSize}
                    setPageSize={(pageSize) => {
                      this.getInteracesData(pagination?.page, pageSize);
                    }}
                    totalPages={pagination?.totalPages}
                    totalElements={pagination?.totalElements}
                    data={this.state.interfaceList || []}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-lg-6 mb-4">
            <div
              style={{ fontSize: "18px", marginTop: "20px" }}
              className="text-bright ml-3 mb-4"
            >
              Master Datasources
            </div>
            <div className="col-12">
              <div className="overflow-y-auto fancy-scroll">
                <div className="color-danger">
                  <BootstrapTable
                    bordered={false}
                    classes="rs-table table-layout-auto mb-0 table-custom-style"
                    keyField="atmId"
                    data={this.state.dataSources}
                    columns={this.columns1}
                    noDataIndication="No data found!"
                  ></BootstrapTable>
                </div>
              </div>
            </div>
          </div>
          <Formik
            initialValues={this.state.interfaceData}
            enableReinitialize={true}
            validationSchema={InterfaceSchema}
            onSubmit={(values, { resetForm }) => {
              if (!values.skipRecordsFromStart) {
                values.skipRecordsFromStart = 0;
              }
              if (!values.skipRecordsFromEnd) {
                values.skipRecordsFromEnd = 0;
              }
              // if (values?.isEncryptedFile.toString() === "false" || !values?.isEncryptedFile) {
              //   values.secretKeyFilePath = "";
              //   values.secretKeyPassword = "";
              // }
              this.createOrUpdateInterface(
                values,
                this.state.isEdit,
                resetForm
              );
            }}
          >
            {({
              errors,
              touched,
              handleSubmit,
              values,
              setFieldValue,
              resetForm,
            }) => (
              <Form id="new-vendor" className="form">
                <Modal
                  backdrop={true}
                  size="xl"
                  show={this.state.modalShow}
                  onHide={() => {
                    this.setState({ modalShow: false, isView: false });
                    resetForm();
                  }}
                  aria-labelledby="example-modal-sizes-title-lg"
                >
                  <Modal.Header closeButton>
                    {this.state.isView
                      ? "View Interface"
                      : this.state.isEdit
                      ? "Update Interface"
                      : "Add New Interface"}
                  </Modal.Header>
                  <Modal.Body scrollable="true">
                    <div className="d-flex align-items-center justify-content-between">
                      <div
                        style={{ fontSize: "18px" }}
                        className="text-info mb-1 font-weight-bold"
                      >
                        Interface Configuration{" "}
                      </div>
                      {this.state.isView || this.state.isEdit ? (
                        <div
                          style={{ fontSize: "18px" }}
                          className="text-info mb-1 font-weight-bold"
                        >
                          {"Interface ID : " + this.state.editInterfaceId}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row border m-2 border-secondary rounded p-2">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-6">
                            <div className="d-flex flex-column">
                              <label className="formTitle">
                                {" "}
                                Interface Name
                              </label>
                              <Field
                                type="text"
                                name="interfaceName"
                                className={`rs-input `}
                                placeholder="Enter Interface Name"
                                disabled={this.state.isView}
                              />
                              {errors.interfaceName && touched.interfaceName ? (
                                <div className="field-error">
                                  {errors.interfaceName}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div
                            className="col-6"
                            style={{
                              pointerEvents: this.state.isView
                                ? "none"
                                : "auto",
                            }}
                          >
                            <div className="workflowModalTitle">
                              Data Source
                            </div>
                            <NewFilter
                              title={
                                this.state.dataSources?.find(
                                  (r) => r.id === values.masterDataSourceId
                                )?.datasource ?? "Data Source"
                              }
                              type={"checkbox"}
                              data={this.state.dataSources}
                              field={"id"}
                              filter={
                                this.state.dataSources?.find(
                                  (r) => r.id === values.masterDataSourceId
                                )?.id
                              }
                              classes="disabled"
                              isSearchable
                              searchField="datasource"
                              onChange={(e) => {
                                setFieldValue(
                                  "dataSource",
                                  this.state.dataSources?.find(
                                    (r) => r.id === e
                                  )?.datasource
                                );
                                setFieldValue("masterDataSourceId", e);
                              }}
                            />
                            {errors.dataSource && touched.dataSource ? (
                              <div className="field-error">
                                {errors.dataSource}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6 mt-2">
                            <div className="d-flex flex-column">
                              <label className="formTitle">
                                {" "}
                                File Name Prefix
                              </label>
                              <Field
                                type="text"
                                name="filenamePrefix"
                                className={`rs-input `}
                                placeholder="Enter File Name Prefix"
                                disabled={this.state.isView}
                              />
                              {errors.filenamePrefix &&
                              touched.filenamePrefix ? (
                                <div className="field-error">
                                  {errors.filenamePrefix}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-3 mt-2">
                            <div className="d-flex flex-column">
                              <label className="formTitle">
                                {" "}
                                Skip Record From Start
                              </label>
                              <Field
                                type="text"
                                name="skipRecordsFromStart"
                                className={`rs-input `}
                                placeholder="Skip Record From Start"
                                disabled={this.state.isView}
                              />
                              {errors.skipRecordsFromStart &&
                              touched.skipRecordsFromStart ? (
                                <div className="field-error">
                                  {errors.skipRecordsFromStart}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-3 mt-2">
                            <div className="d-flex flex-column">
                              <label className="formTitle">
                                {" "}
                                Skip Record From End
                              </label>
                              <Field
                                type="text"
                                name="skipRecordsFromEnd"
                                className={`rs-input `}
                                placeholder="Skip Record From End"
                                disabled={this.state.isView}
                              />
                              {errors.skipRecordsFromEnd &&
                              touched.skipRecordsFromEnd ? (
                                <div className="field-error">
                                  {errors.skipRecordsFromEnd}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6 mt-2">
                            <div
                              className="d-flex flex-column"
                              style={{
                                pointerEvents: this.state.isView
                                  ? "none"
                                  : "auto",
                              }}
                            >
                              <label className="formTitle"> Format</label>
                              <NewFilter
                                title={
                                  this.state.formatData?.find(
                                    (r) => r.name === values.format
                                  )?.description ?? "Format"
                                }
                                type={"checkbox"}
                                data={this.state.formatData}
                                field={"id"}
                                filter={
                                  this.state.formatData?.find(
                                    (r) => r.name === values.format
                                  )?.id
                                }
                                isSearchable
                                searchField="description"
                                onChange={(e) => {
                                  setFieldValue(
                                    "format",
                                    this.state.formatData?.find(
                                      (r) => r.id === e
                                    )?.name
                                  );
                                }}
                              />
                              {errors.format && touched.format ? (
                                <div className="field-error">
                                  {errors.format}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-6 mt-2">
                            <div className="d-flex flex-column">
                              <label className="formTitle">
                                {" "}
                                Skip Specific Lines Number
                              </label>
                              <Field
                                type="text"
                                name="skipSpecificLineNumber"
                                className={`rs-input `}
                                placeholder="Enter Numbers Separated by Commas (e.g. 1,2,3,4,5)"
                                disabled={this.state.isView}
                              />
                              {errors.skipSpecificLineNumber &&
                              touched.skipSpecificLineNumber ? (
                                <div className="field-error">
                                  {errors.skipSpecificLineNumber}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6 mt-2">
                            <div className="d-flex flex-column">
                              <label className="formTitle">
                                No. of Files Expected
                              </label>
                              <Field
                                type="text"
                                name="expectedFilesCount"
                                className={`rs-input `}
                                placeholder="Enter Expected Files Count"
                                disabled={this.state.isView}
                              />
                              {errors.expectedFilesCount &&
                              touched.expectedFilesCount ? (
                                <div className="field-error">
                                  {errors.expectedFilesCount}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-6 mt-2">
                            <div className="d-flex flex-column">
                              <label className="formTitle">
                                File Arrival Time
                              </label>
                              <Field
                                type="text"
                                name="schedule"
                                className={`rs-input `}
                                placeholder="0 0 20 ? * *"
                                disabled={this.state.isView}
                              />
                              {errors.schedule && touched.schedule ? (
                                <div className="field-error">
                                  {errors.schedule}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-6">
                        <div className="d-flex align-items-center justify-content-between">
                          <div
                            style={{ fontSize: "18px" }}
                            className="text-info mb-1 font-weight-bold"
                          >
                            Folder Configuration{" "}
                          </div>
                        </div>
                        <div className="row border m-2 border-secondary rounded p-2">
                          <div className="col-12">
                            <div className="row">
                              <div className="col-12">
                                <div className="d-flex flex-column">
                                  <label className="formTitle">
                                    {" "}
                                    Inbound Folder
                                  </label>
                                  <Field
                                    type="text"
                                    name="inboundFolder"
                                    className={`rs-input `}
                                    placeholder="Enter Inbound Folder"
                                    disabled={this.state.isView}
                                  />
                                  {errors.inboundFolder &&
                                  touched.inboundFolder ? (
                                    <div className="field-error">
                                      {errors.inboundFolder}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-12 mt-2">
                                <div className="d-flex flex-column">
                                  <label className="formTitle">
                                    {" "}
                                    Outbound Folder
                                  </label>
                                  <Field
                                    type="text"
                                    name="outboundFolder"
                                    className={`rs-input `}
                                    placeholder="Enter Outbound Folder"
                                    disabled={this.state.isView}
                                  />
                                  {errors.outboundFolder &&
                                  touched.outboundFolder ? (
                                    <div className="field-error">
                                      {errors.outboundFolder}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-12 mt-2">
                                <div className="d-flex flex-column">
                                  <label className="formTitle">
                                    {" "}
                                    Exception Folder
                                  </label>
                                  <Field
                                    type="text"
                                    name="exceptionFolder"
                                    className={`rs-input `}
                                    placeholder="Enter Exception Folder"
                                    disabled={this.state.isView}
                                  />
                                  {errors.exceptionFolder &&
                                  touched.exceptionFolder ? (
                                    <div className="field-error">
                                      {errors.exceptionFolder}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="d-flex align-items-center justify-content-between">
                          <div
                            style={{ fontSize: "18px" }}
                            className="text-info mb-1 font-weight-bold"
                          >
                            Kafka Configuration{" "}
                          </div>
                        </div>
                        <div className="row border m-2 border-secondary rounded p-2">
                          <div className="col-12">
                            <div className="row">
                              <div className="col-12">
                                <div className="d-flex flex-column">
                                  <label className="formTitle">
                                    {" "}
                                    Raw Kafka Topic
                                  </label>
                                  <Field
                                    type="text"
                                    name="rawKafkaTopic"
                                    className={`rs-input `}
                                    placeholder="Enter Raw Kafka Topic"
                                    disabled={this.state.isView}
                                  />
                                  {errors.rawKafkaTopic &&
                                  touched.rawKafkaTopic ? (
                                    <div className="field-error">
                                      {errors.rawKafkaTopic}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-12 mt-2">
                                <div className="d-flex flex-column">
                                  <label className="formTitle">
                                    {" "}
                                    Processed Kafka Topic
                                  </label>
                                  <Field
                                    type="text"
                                    name="processedKafkaTopic"
                                    className={`rs-input `}
                                    placeholder="Enter Processed Kafka Topic"
                                    disabled={this.state.isView}
                                  />
                                  {errors.processedKafkaTopic &&
                                  touched.processedKafkaTopic ? (
                                    <div className="field-error">
                                      {errors.processedKafkaTopic}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-12 mt-2">
                                <div className="d-flex flex-column">
                                  <label className="formTitle">
                                    {" "}
                                    Error Kafka Topic
                                  </label>
                                  <Field
                                    type="text"
                                    name="errorKafkaTopic"
                                    className={`rs-input `}
                                    placeholder="Enter Error Kafka Topic"
                                    disabled={this.state.isView}
                                  />
                                  {errors.errorKafkaTopic &&
                                  touched.errorKafkaTopic ? (
                                    <div className="field-error">
                                      {errors.errorKafkaTopic}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-6 pl-4">
                        <label className="formTitle">Select Status</label>
                        <div className="d-flex flex-row flex-wrap">
                          <div className="form-check">
                            <Field
                              className="form-check-input"
                              type="radio"
                              name="active"
                              value={"true"}
                              checked={
                                values?.active === true ||
                                values?.active === "true"
                              }
                              disabled={this.state.isView}
                            />
                            <label className="form-check-label">Active</label>
                          </div>
                          <div className="form-check ml-4">
                            <Field
                              className="form-check-input"
                              type="radio"
                              name="active"
                              value={"false"}
                              checked={
                                values?.active === false ||
                                values?.active === "false"
                              }
                              disabled={this.state.isView}
                            />
                            <label className="form-check-label">InActive</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="d-flex align-items-center justify-content-end">
                          <ButtonBasic
                            onClick={() =>
                              this.setState({ isExpand: !this.state.isExpand })
                            }
                            title={"Advance Configuration"}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <Collapse
                        in={
                          this.state.isExpand ||
                          this.state.isView ||
                          this.state.isEdit
                        }
                      >
                        <div className="mt-3">
                          <div className="d-flex align-items-center justify-content-between">
                            <div
                              style={{ fontSize: "18px" }}
                              className="text-info mb-1 font-weight-bold"
                            >
                              Advanced Interface Configuration{" "}
                            </div>
                          </div>
                          <div className="row border m-2 border-secondary rounded p-2">
                            <div className="col-6">
                              <div className="row">
                                <div className="col-12 mt-2">
                                  <label className="formTitle">
                                    Discard Duplicate File Name
                                  </label>
                                  <div className="d-flex flex-row flex-wrap">
                                    <div className="form-check">
                                      <Field
                                        className="form-check-input"
                                        type="radio"
                                        name="checkFileNameDuplicate"
                                        value={"true"}
                                        checked={
                                          values?.checkFileNameDuplicate ===
                                            true ||
                                          values?.checkFileNameDuplicate ===
                                            "true"
                                        }
                                        disabled={this.state.isView}
                                      />
                                      <label className="form-check-label">
                                        True
                                      </label>
                                    </div>
                                    <div className="form-check ml-4">
                                      <Field
                                        className="form-check-input"
                                        type="radio"
                                        name="checkFileNameDuplicate"
                                        value={"false"}
                                        checked={
                                          values?.checkFileNameDuplicate ===
                                            false ||
                                          values?.checkFileNameDuplicate ===
                                            "false"
                                        }
                                        disabled={this.state.isView}
                                      />
                                      <label className="form-check-label">
                                        False
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 mt-2">
                                  <label className="formTitle">
                                    Discard Duplicate File Content
                                  </label>
                                  <div className="d-flex flex-row flex-wrap">
                                    <div className="form-check">
                                      <Field
                                        className="form-check-input"
                                        type="radio"
                                        name="checkFileContentDuplicate"
                                        value={"true"}
                                        checked={
                                          values?.checkFileContentDuplicate ===
                                            true ||
                                          values?.checkFileContentDuplicate ===
                                            "true"
                                        }
                                        disabled={this.state.isView}
                                      />
                                      <label className="form-check-label">
                                        True
                                      </label>
                                    </div>
                                    <div className="form-check ml-4">
                                      <Field
                                        className="form-check-input"
                                        type="radio"
                                        name="checkFileContentDuplicate"
                                        value={"false"}
                                        checked={
                                          values?.checkFileContentDuplicate ===
                                            false ||
                                          values?.checkFileContentDuplicate ===
                                            "false"
                                        }
                                        disabled={this.state.isView}
                                      />
                                      <label className="form-check-label">
                                        False
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="col-12 mt-3"
                                  style={{
                                    pointerEvents: this.state.isView
                                      ? "none"
                                      : "auto",
                                  }}
                                >
                                  <div className="workflowModalTitle">
                                    Staging Data Processor
                                  </div>
                                  <NewFilter
                                    title={
                                      this.state.stagingData?.find(
                                        (r) =>
                                          r.name ===
                                          values.stagingDataProcessorClass
                                      )?.label ?? "Staging Data Processor"
                                    }
                                    type={"checkbox"}
                                    data={this.state.stagingData}
                                    field={"id"}
                                    filter={
                                      this.state.stagingData?.find(
                                        (r) =>
                                          r.name ===
                                          values.stagingDataProcessorClass
                                      )?.id
                                    }
                                    isSearchable
                                    searchField="description"
                                    onChange={(e) => {
                                      setFieldValue(
                                        "stagingDataProcessorClass",
                                        this.state.stagingData?.find(
                                          (r) => r.id === e
                                        )?.name
                                      );
                                    }}
                                  />
                                  {errors.stagingDataProcessorClass &&
                                  touched.stagingDataProcessorClass ? (
                                    <div className="field-error">
                                      {errors.stagingDataProcessorClass}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="row">
                                {/* <div className="col-12 ml-3">
                                  <label className="formTitle">
                                    Encrypted File
                                  </label>
                                  <div className="d-flex flex-row flex-wrap">
                                    <div className="form-check">
                                      <Field
                                        className="form-check-input"
                                        type="radio"
                                        name="isEncryptedFile"
                                        value={"true"}
                                        checked={
                                          values?.isEncryptedFile === true ||
                                          values?.isEncryptedFile === "true"
                                        }
                                        disabled={this.state.isView}
                                      />
                                      <label className="form-check-label">
                                        True
                                      </label>
                                    </div>
                                    <div className="form-check ml-4">
                                      <Field
                                        className="form-check-input"
                                        type="radio"
                                        name="isEncryptedFile"
                                        value={"false"}
                                        checked={
                                          values?.isEncryptedFile === false ||
                                          values?.isEncryptedFile === "false" ||
                                          !values?.isEncryptedFile
                                        }
                                        disabled={this.state.isView}
                                      />
                                      <label className="form-check-label">
                                        False
                                      </label>
                                    </div>
                                  </div>
                                  <div className="field-error">
                                    {errors.isEncryptedFile}
                                  </div>
                                </div>
                                <Collapse
                                  in={
                                    values?.isEncryptedFile === "true" ||
                                    values?.isEncryptedFile === true
                                      ? true
                                      : false
                                  }
                                  dimension="height"
                                  className="col-12"
                                >
                                  <div>
                                    <div className="col-12 mt-2">
                                      <div className="d-flex flex-column">
                                        <label className="formTitle">
                                          {" "}
                                          Secret Key File Path
                                        </label>
                                        <Field
                                          type="text"
                                          name="secretKeyFilePath"
                                          className={`rs-input `}
                                          placeholder="Enter Secret File Path"
                                          disabled={this.state.isView}
                                        />
                                        {errors.secretKeyFilePath &&
                                        touched.secretKeyFilePath ? (
                                          <div className="field-error">
                                            {errors.secretKeyFilePath}
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="col-12 mt-2">
                                      <div className="d-flex flex-column">
                                        <label className="formTitle">
                                          {" "}
                                          Secret Key Password
                                        </label>
                                        <Field
                                          type="text"
                                          name="secretKeyPassword"
                                          className={`rs-input `}
                                          placeholder="Enter Secret Key Password"
                                          disabled={this.state.isView}
                                        />
                                        {errors.secretKeyPassword &&
                                        touched.secretKeyPassword ? (
                                          <div className="field-error">
                                            {errors.secretKeyPassword}
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </Collapse> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <div className="d-flex">
                      <ButtonBasic
                        onClick={() => {
                          this.setState({ modalShow: false, isView: false });
                          resetForm();
                        }}
                        title={"Cancel"}
                      />
                      {this.state.isView ? null : (
                        <button
                          type="submit"
                          name="submit"
                          className={"btn btn-info btn-md mr-2"}
                          onClick={() => {
                            if (
                              !errors?.stagingDataProcessorClass ||
                            //  !errors?.isEncryptedFile ||
                              !errors?.secretKeyFilePath ||
                              !errors?.secretKeyPassword
                            ) {
                              this.setState({
                                isExpand: true,
                              });
                            }
                            handleSubmit();
                          }}
                        >
                          {this.state.isEdit ? "Update" : "Add"}
                        </button>
                      )}
                    </div>
                  </Modal.Footer>
                </Modal>
              </Form>
            )}
          </Formik>
          <ConfirmationModal
            isShow={this.state.isShowInterface}
            title="Delete Interface"
            message="Are you sure you want to delete this interface ?"
            confirmAction={() => this.confirmDeleteInterface()}
            hideModal={() => this.setState({ isShowInterface: false })}
          />
        </div>
      </>
    );
  }
}
export default withDateTime(InterfaceConfigration);
