import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router";
import { baseUrl } from "../../configuration/apiUrl";
import "./Login.css";
import logo from "../../assets/images/logo-dark.png";
import BasicButton from "../../components/Buttons/ButtonBasic";
import axiosInstance from "../../utils/axiosInstance";
import Toast from "../../components/Toast/Toast";
import withDateTime from "../../utils/timedatehoc";
import { useAuth } from "../../navigation/ProvideAuth";
import { Alert } from "react-bootstrap";
class Login extends Component {
  constructor(props) {
    super(props);
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
    this.state = {
      loginErr: false,
      loginErrorMessage: "",
      spinner: false,
      PasswordError: "",
      isTabActive: false,
      isLoggin: false,
    };
  }

  loginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email address.")
      .required("Email is required *"),
    password: Yup.string().required("password is required *"),
    // .matches(
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    // ),
  });

  handleResponse(response) {
    let clonedResponse = response.clone();
    return clonedResponse.json().then((json) => {
      if (!clonedResponse.ok) {
        return Promise.reject();
      }
      return json;
    });
  }
  async authenticationUser(values) {
    // if (this.state.PasswordError === "") {
    try {
      this.setState({ spinner: true });
      let req = {
        data: {
          username: values.email,
          password: values.password,
        },
      };
      const apiResponse = await axios.post(baseUrl + "/users/login", req);
      const jsonResponse = apiResponse;
      const { userPreferences } = jsonResponse?.data?.data;
      localStorage.setItem(
        "userData",
        JSON.stringify(jsonResponse?.data?.data)
      );

      if (userPreferences?.length > 0) {
        userPreferences?.map((pref) => {
          if (pref.prefKeys === "dateFormat") {
            this.props.dateTime.setDate(pref.prefValues);
          } else if (pref.prefKeys === "timeFormat") {
            this.props.dateTime.setTime(pref.prefValues);
          } else {
            this.props.dateTime.setDateTime(pref.prefValues);
          }
        });
      }

      if (jsonResponse.data.message.status === "200") {
        axiosInstance
          .get(`/roles/users`)
          .then((response) => {
            if (response.message.status !== "200") {
              Toast(response.message.description, "error");
            } else {
              // console.log("roles for user.....", response);
              localStorage.setItem("userRoles", JSON.stringify(response));
              this?.props?.history.push("/");
            }
          })
          .catch((error) => {
            console.log("user info error", error);
          });
      } else {
        console.log("login api error...", jsonResponse);
        this.setState({ loginErr: true });
      }
    } catch (err) {
      // console.log("login err...", err.response.data.message.description);
      this.setState({
        spinner: false,
        loginErr: true,
        loginErrorMessage: err?.response?.data?.message?.description,
      });
    }
    // }
  }
  handleVisibilityChange = function () {
    this.setState({
      isTabActive: window.document.visibilityState === "visible",
    });
  };

  componentDidMount() {
    var urlParams = new URLSearchParams(window.location.search);
    var serializedState = urlParams?.get("state");
    var message = JSON.parse(decodeURIComponent(serializedState));
    this.setState({ errorMessage: message });
    const accessToken = JSON.parse(
      localStorage.getItem("userData")
    )?.accessToken;
    if (accessToken) {
      this.props.history.push("/");
    }
    window.document.addEventListener(
      "visibilitychange",
      this.handleVisibilityChange
    );
    return () => {
      window.document.removeEventListener(
        "visibilitychange",
        this.handleVisibilityChange
      );
    };
  }

  componentDidUpdate() {
    if (
      this.state.isTabActive &&
      JSON.parse(localStorage.getItem("userData"))?.accessToken &&
      JSON.parse(localStorage.getItem("userRoles"))?.data
    ) {
      this?.props?.history.push("/");
    }
  }

  render() {
    if (
      this.state.isTabActive &&
      JSON.parse(localStorage.getItem("userData"))?.accessToken &&
      JSON.parse(localStorage.getItem("userRoles"))?.data
    ) {
      this?.props?.history?.push("/");
    }
    return (
      <div className="maincontainer">
        <div className="container-fluid">
          <div className="row no-gutter">
            <div className="col-md-6 d-none d-md-flex align-items-center bg-image">
              <img
                src={logo}
                alt="Logo"
                width="70%"
                style={{ margin: "20px" }}
              />
            </div>

            <div className="col-md-6 bg-light">
              <div className="login d-flex align-items-center">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-10 col-xl-7 mx-auto">
                      {/* Show an Error Message */}
                      {this.state?.errorMessage?.status === "451" ? (
                        <Alert
                          variant="danger"
                          dismissible
                          onClose={() => {
                            this.setState({ errorMessage: null });
                          }}
                        >
                          {this.state?.errorMessage?.description}
                        </Alert>
                      ) : (
                        ""
                      )}
                      <h4 className="display-5">Sign in</h4>
                      <p className="text-muted mb-4">
                        Login to access your account.
                      </p>

                      <Formik
                        initialValues={{ email: "", password: "" }}
                        validationSchema={this.loginSchema}
                        onSubmit={(values) => {
                          if (this.state.spinner) {
                            return;
                          }
                          this.authenticationUser(values);
                        }}
                      >
                        {({ errors, touched, handleSubmit, handleReset }) => (
                          <Form
                            id="add-notes"
                            className="form"
                            onSubmit={handleSubmit}
                            onReset={handleReset}
                          >
                            <div className="form-group">
                              <div className="mb-3">
                                <Field
                                  className="form-control border-0 shadow-sm px-4"
                                  type="email"
                                  placeholder="Email address"
                                  name="email"
                                />
                                {errors.email && touched.email ? (
                                  <div className="field-error">
                                    {errors.email}
                                  </div>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <Field
                                  className="form-control border-0 shadow-sm px-4"
                                  type="password"
                                  placeholder="Enter Password"
                                  name="password"
                                />
                                {errors.password && touched.password ? (
                                  <div className="field-error">
                                    {errors.password}
                                  </div>
                                ) : null}
                              </div>
                              <div className="form-check">
                                <Link
                                  className=""
                                  to="/forgot-password"
                                  id="forgotPassword"
                                >
                                  <label
                                    htmlFor="customCheck1"
                                    className="form-check-label text-secondary text-underline cursor-pointer"
                                  >
                                    Forgot Password?
                                  </label>
                                </Link>
                              </div>
                              <div className="d-grid gap-2 mt-2">
                                <BasicButton
                                  type="submit"
                                  name="submit"
                                  title="Sign in"
                                  className={"btn btn-info btn-sm"}
                                  style={{
                                    width: "100%",
                                    borderRadius: "20px",
                                  }}
                                  spinner={this.state.spinner}
                                  disabled={this.state.spinner}
                                />
                              </div>
                              {this.state.loginErr && (
                                <div
                                  className="alert alert-danger mt-2"
                                  role="alert"
                                >
                                  {this.state.loginErrorMessage}
                                </div>
                              )}
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withDateTime(Login));
