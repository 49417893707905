import React, { useState, useEffect, useCallback } from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import { useDateTime } from "./DateTime";
import moment from "moment";
import Select from "react-select";
import { DateTimeFormat } from "../../utils/formatDateTime";

export const DateTimePicker = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  const { dateTime } = useDateTime();

  const [formattedValue, setFormattedValue] = useState(
    field.value ? moment(field.value).format(dateTime) : null
  );
  useEffect(() => {
    if (field.value !== null) {
      const timePart = moment(field?.value).format("HH:mm:ss");

      const [hours, minutes, seconds] = timePart?.split(":");
      setSelectedHours({ value: hours, label: hours });
      setSelectedMinutes({ value: minutes, label: minutes });
      setSelectedSeconds({ value: seconds, label: seconds });
    }
  }, []);

  const [selectedHours, setSelectedHours] = useState(null);
  const [selectedMinutes, setSelectedMinutes] = useState(null);
  const [selectedSeconds, setSelectedSeconds] = useState(null);

  const [hourOptions, setHourOptions] = useState([]);
  const [minuteOptions, setMinuteOptions] = useState([]);
  const [secondOptions, setSecondOptions] = useState([]);

  // Generate options for hours, minutes, and seconds
  useEffect(() => {
    const currentDateTime = moment();
    const currentHour = currentDateTime.hour();
    const currentMinute = currentDateTime.minute();
    const currentSecond = currentDateTime.second();
    const isToday = moment(field.value).isSame(currentDateTime, "day");
    const hours = [];
    for (let i = isToday ? 0 : 0; i <= (isToday ? currentHour : 23); i++) {
      hours.push({ value: i, label: i.toString().padStart(2, "0") });
    }
    setHourOptions(hours);

    const minutes = [];
    if (selectedHours !== null) {
      if (selectedHours.value.toString() === currentHour.toString()) {
        for (let i = 0; i <= currentMinute; i++) {
          minutes.push({ value: i, label: i.toString().padStart(2, "0") });
        }
      } else {
        for (let i = 0; i < 60; i++) {
          minutes.push({ value: i, label: i.toString().padStart(2, "0") });
        }
      }
    }
    setMinuteOptions(minutes);

    const seconds = [];
    if (selectedHours && selectedMinutes) {
      if (
        selectedHours.value.toString() === currentHour.toString() &&
        selectedMinutes.value.toString() === currentMinute.toString()
      ) {
        for (let i = 0; i < currentSecond; i++) {
          seconds.push({ value: i, label: i.toString().padStart(2, "0") });
        }
      } else {
        for (let i = 0; i < 60; i++) {
          seconds.push({ value: i, label: i.toString().padStart(2, "0") });
        }
      }
    }
    setSecondOptions(seconds);
  }, [field.value, selectedHours, selectedMinutes]);

  const handleHourChange = (selectedOption) => {
    setSelectedHours(selectedOption);
    setSelectedMinutes(null);
    setSelectedSeconds(null);
  };
  const handleMinuteChange = (selectedOption) => {
    setSelectedMinutes(selectedOption);
    setSelectedSeconds(null);
  };

  const handleSecondChange = (selectedOption) => {
    setSelectedSeconds(selectedOption);
  };

  useEffect(() => {
    if (field.value !== null) {
      const formattedDateTime = moment(field?.value)
        .set("hour", selectedHours ? selectedHours.value : 0)
        .set("minute", selectedMinutes ? selectedMinutes.value : 0)
        .set("second", selectedSeconds ? selectedSeconds.value : 0)
        .toDate();
      setFieldValue(field.name, formattedDateTime);
      setFormattedValue(formattedDateTime);
    }
  }, [selectedHours, selectedMinutes, selectedSeconds]);
  const selectStyle = {
    width: "70px",
    height: "52px",
    borderRadius: "8px",
    background: "#f7f7fc",
    color: "#777",
    border: "1px solid #ccc",
    cursor: "pointer",
    textAlign: "center",
  };
  return (
    <div className="d-flex">
      <div style={{ width: "50%" }}>
        <DatePicker
          {...field}
          {...props}
          selected={(field.value && moment(field.value).toDate()) || null}
          onChange={(val) => {
            setFormattedValue(val);
            setFieldValue(field.name, val);
            setSelectedHours(null);
            setSelectedMinutes(null);
            setSelectedSeconds(null);
          }}
          maxDate={new Date()}
          maxTime={
            field.value
              ? new Date(field.value).toDateString() ===
                new Date().toDateString()
                ? new Date()
                : new Date().setHours(23, 59, 59)
              : new Date()
          }
          minTime={new Date().setHours(0, 0, 0)}
          dateFormat={dateTime}
          className={`custom-date-picker`}
          placeholderText="Select Date"
          autoComplete="off"
          value={DateTimeFormat(dateTime, formattedValue)}
        />
      </div>
      <div className="d-flex">
        <div style={{ width: "100%" }} className="pl-2">
          <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                ...selectStyle,
              }),
            }}
            components={{ DropdownIndicator: null }}
            placeholder="H"
            options={hourOptions}
            value={selectedHours}
            defaultValue={selectedHours}
            isDisabled={field.value ? false : true}
            onChange={handleHourChange}
          />
        </div>
        <div style={{ width: "100%" }} className="pl-1">
          <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                ...selectStyle,
              }),
            }}
            components={{ DropdownIndicator: null }}
            placeholder="M"
            options={minuteOptions}
            value={selectedMinutes}
            defaultValue={selectedMinutes}
            isDisabled={!selectedHours}
            onChange={handleMinuteChange}
          />
        </div>
        <div style={{ width: "100%" }} className="pl-1">
          <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                ...selectStyle,
              }),
            }}
            components={{ DropdownIndicator: null }}
            placeholder="S"
            options={secondOptions}
            defaultValue={selectedSeconds}
            value={selectedSeconds}
            isDisabled={!selectedMinutes}
            onChange={handleSecondChange}
          />
        </div>
      </div>
    </div>
  );
};

export default DateTimePicker;
