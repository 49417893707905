export default function pieChart({
  theme,
  colorPalette,
  loader,
  chartData,
  label,
  radius,
}) {
  let noData = true;
  chartData?.data?.forEach((data) => {
    if (noData) {
      noData = data.value == null || data.value == 0;
    }
  });
  const chart = {
    title: {
      text:
        !loader && noData
          ? `No Data available for ${chartData.title} `
          : chartData.title,
      left: noData ? "center" : "left",
      top: noData ? "center" : "top",
      textStyle: {
        color: "#777",
        fontSize: "13px",
        fontFamily: "Montserrat Bold",
        overflow: "break",
        width: noData ? 200 : 400,
      },
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)",
      position: "inside",
    },
    toolbox: {
      show: true,
      showTitle: false, // hide the default text so they don't overlap each other
      feature: {
        saveAsImage: {
          show: true,
          title: "Save As Image",
        },
      },
    },
    legend: {
      data: noData ? [] : label,
      orient: "horizontal",
      left: "right",
      top: "bottom",
      icon: "circle",
      textStyle: {
        color: "#777",
        fontFamily: "Montserrat Regular",
        fontSize: "10px",
      },
    },
    series: [
      {
        name: chartData?.title,
        type: "pie",
        itemStyle: {
          borderColor: theme === "light" ? "#fcfcfc" : "#1d1d1d",
          borderWidth: 1,
        },
        radius: radius,
        color: colorPalette,
        label: {
          color: "#777",
          fontFamily: "Montserrat Regular",
          fontSize: "10px",
        },
        data: noData ? [] : chartData?.data,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        animationDelay: function (idx) {
          return idx * 100;
        },
      },
    ],
  };
  return chart;
}
