import React, { useState, useEffect } from "react";
import FilterDropdownStyle from "./FilterDropdownStyle";
import chevronDown from "../../assets/images/chevron-down.png";
import DatePicker from "react-datepicker";
import searchIcon from "../../assets/images/search.svg";
import resetIcon from "../../assets/images/reset.svg";
import "react-datepicker/dist/react-datepicker.css";
const NewFilter = ({
  id = "",
  title,
  type,
  date,
  data = [],
  isMulti,
  filter,
  onChange = () => {},
  classes = "",
  btnClasses = "",
  field = "codeValue",
  isSearchable,
  searchField = "label",
  positionRelative,
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [inputData, setInputData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const handleDateChange = (d) => {
    date = null;
    setSelectedDate(d);
  };
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    setInputData(
      data.filter((item) => {
        return item[searchField]
          .toLowerCase()
          .includes(e.target.value.toLowerCase());
      })
    );
  };
  const closeCalendar = () => {};
  useEffect(() => {
    if (data?.length > 0 && data !== inputData) {
      setInputData(data);
    }
    // eslint-disable-next-line
  }, [data]);
  if (type === "date") {
    return (
      <FilterDropdownStyle className={`dropdown ${classes}`}>
        <DatePicker
          selected={date ?? selectedDate}
          onChange={(date) => {
            onChange(
              new Date(date)
                .toLocaleDateString("pt-br")
                .split("/")
                .reverse()
                .join("-")
            );
            handleDateChange(date);
          }}
          customInput={
            <button
              className={`d-flex align-items-center justify-content-between btn tsg-dropdown w-100 h-100 px-2 py-1`}
              onClick={() => {
                closeCalendar();
              }}
            >
              <span>{title}</span>
              <img className="dropdown-icon" src={chevronDown} alt={"chev"} />
            </button>
          }
          className={`d-flex align-items-center justify-content-between custom-date-picker`}
        />
      </FilterDropdownStyle>
    );
  }
  return (
    <FilterDropdownStyle className={`dropdown ${classes}`}>
      <button
        className={`d-flex align-items-center justify-content-between btn tsg-dropdown w-100 h-100 px-2 py-1 dropdownMenuButton${title} ${btnClasses}`}
        type="button"
        // id={`dropdownMenuButton${title}`}
        id={`dropdownMenuButton${id}`}
        data-toggle="dropdown"
        // style={{ padding: "0.1rem 1.125rem" }}
        style={{ height: "38px" }}
      >
        <span>{title}</span>
        <img className="dropdown-icon" src={chevronDown} alt={"chev"} />
      </button>
      <form>
        <div
          className={`dropdown-menu fancy-scroll ${
            positionRelative ? "conditional-dropdown" : ""
          }`}
        >
          {data?.length === 0 && <div className="dropdown-item">No data</div>}
          {isSearchable && (
            <div className="position-relative">
              <img className="dropdown-search-icon" src={searchIcon} alt="" />
              <input
                className="dropdown-search"
                onChange={handleSearch}
                placeholder="Search"
                value={searchValue}
              />
              <img
                className="dropdown-reset-icon"
                onClick={() => handleSearch({ target: { value: "" } })}
                src={resetIcon}
                alt=""
              />
            </div>
          )}
          {type === "checkbox" &&
            !isMulti &&
            inputData.map((val, index) => (
              <div key={index} className={`dropdown-item`}>
                <div className="custom-control custom-checkbox">
                  <input
                    id={id + "Check"}
                    type="checkbox"
                    className="custom-control-input dropdown-checkbox"
                    value={val?.id}
                    checked={
                      filter &&
                      (filter[0] === val[field] || filter === val[field])
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      e.target.checked ? onChange(val[field]) : onChange("");
                      e.target.checked &&
                        document
                          ?.getElementsByClassName(
                            `dropdownMenuButton${title}`
                          )[0]
                          ?.click();
                    }}
                  />
                  <label
                    id={id + "Values"}
                    className="custom-control-label"
                    htmlFor={val?.id}
                  >
                    {val?.label}
                  </label>
                </div>
              </div>
            ))}
          {type === "checkbox" &&
            isMulti &&
            inputData.map((val, index) => (
              <div key={index} className={`dropdown-item`}>
                <div className="custom-control custom-checkbox">
                  <input
                    id={id + "Check"}
                    type="checkbox"
                    className="custom-control-input dropdown-checkbox"
                    value={val?.id}
                    checked={filter?.includes(val[field]) ? true : false}
                    onChange={(e) => {
                      e.target.checked
                        ? onChange({ id: val[field], flag: true })
                        : onChange({ id: val[field], flag: false });
                    }}
                  />
                  <label
                    id={id + "Values"}
                    className="custom-control-label"
                    htmlFor={val?.id}
                  >
                    {val?.label}
                  </label>
                </div>
              </div>
            ))}
        </div>
      </form>
    </FilterDropdownStyle>
  );
};

export default NewFilter;
