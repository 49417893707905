import React, { useEffect, useState, useCallback, useRef } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Toast from "../../components/Toast/Toast";
import BootstrapTable from "react-bootstrap-table-next";
import CurrencyFormat from "react-currency-format";
import axiosInstance from "../../utils/axiosInstance";
import Pagination from "../../components/pagination/pagination";
import { useDateTime } from "../../components/Helper/DateTime";
import LoaderComponent from "../../components/Spinner/LoaderComponent";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import { DateTimeFormat } from "../../utils/formatDateTime";
const formatNumber = (row) => {
  return (
    <CurrencyFormat
      value={row !== null ? row : "-"}
      displayType={"text"}
      thousandSeparator={true}
    />
  );
};

export const ReconStatus = ({
  refresh,
  filter,
  pageFilter,
  setPageFilter,
  reconId,
  setReconId,
}) => {
  const { dateTime } = useDateTime();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [sort, setSort] = useState({ sort_by: "", sort_order: "" });
  const [loading, setLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [reconActionData, setReconActionData] = useState({
    action: "",
    batchId: "",
  });
  const [highlightedRow, setHighlightedRow] = useState(null);

  const onSortTable = (type, newState) => {
    setPage(1);
    setSort({ sort_by: newState.sortField, sort_order: newState.sortOrder });
  };

  const getReconStatus = useCallback(
    (filter, flag) => {
      const order = `&sort_by=${sort.sort_by}&sort_order=${sort.sort_order}`;
      let url = `/recon/last-recons-run?page=${
        flag ? 0 : page - 1
      }&size=${pageSize}${
        sort.sort_by && sort.sort_order ? order : ""
      }${filter}`;
      axiosInstance
        .get(url)
        .then((res) => {
          if (res.message.status !== "200") {
            setData([]);
            setLoading(false);
            setTotalElements(0);
          } else {
            setData(res?.data?.lastRecons || []);
            setPage(res?.page?.number + 1 || 1);
            setTotalPages(res?.page?.totalPages || 0);
            setTotalElements(res?.page?.totalElements || 0);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log("err", err);
          setLoading(false);
        });
    },
    [page, pageSize, sort, filter]
  );

  const reconAction = async () => {
    const data = {
      action: reconActionData?.action,
      batchId: reconActionData?.batchId.toString(),
    };
    try {
      setLoading(true);
      const response = await axiosInstance.post("/recon/action", {
        data,
      });

      if (response.message.status !== "200") {
        setLoading(false);
        setShowConfirmModal(false);
        Toast(response?.message?.description, "error");
      } else {
        getReconStatus(filter, false);
        setLoading(false);
        setShowConfirmModal(false);
        Toast(response?.message?.description, "success");
      }
    } catch (err) {
      setLoading(false);
      setShowConfirmModal(false);
      Toast(err?.response?.data?.message?.description, "error");
    }
  };

  useEffect(() => {
    setLoading(true);
    getReconStatus(filter, false);
  }, [page, pageSize, sort, getReconStatus, refresh]);

  useEffect(() => {
    let timerId = setInterval(() => {
      getReconStatus(filter, false);
      setPageFilter(false);
    }, 5000);
    return () => {
      clearInterval(timerId);
    };
  });

  useEffect(() => {
    if (pageFilter) {
      setLoading(true);
      getReconStatus(filter, true);
      setPageFilter(false);
    }
  }, [pageFilter]);
  const tdBlinkStyle = (cell, row, rowIndex) => {
    return {
      cursor: "pointer",
      backgroundColor: highlightedRow === rowIndex ? "#14a2d1" : "",
      transition: "background-color 0.3s",
    };
  };

  const userRoles = JSON.parse(localStorage.getItem("userRoles"));
  const allPermissions = userRoles?.data?.roles?.flatMap(
    (item) => item.permissions
  );

  const columnsReconStatus = [
    { hidden: true, dataField: "id", text: "ID" },

    {
      dataField: "reconName",
      text: "Recon Name",
      sort: true,
      style: tdBlinkStyle,
    },
    {
      dataField: "dataSetRange",
      text: "Data Set Range",
      formatter: (cell, row) => {
        if (!cell) return null;

        const range = JSON?.parse(cell);
        return (
          <div>
            {Object?.entries(range)?.map(([key, value]) => (
              <div key={key}>
                <strong>{key}:</strong> {value}
              </div>
            ))}
          </div>
        );
      },
      sort: true,
      style: tdBlinkStyle,
    },

    {
      dataField: "startedAt",
      text: "Started At",
      formatter: (cell) => DateTimeFormat(dateTime, cell),
      sort: true,
      style: tdBlinkStyle,
    },

    {
      dataField: "completedAt",
      text: "Completed At",
      formatter: (cell) => DateTimeFormat(dateTime, cell),
      sort: true,
      style: tdBlinkStyle,
    },
    {
      dataField: "batchId",
      text: "Batch ID",
      formatter: formatNumber,
      sort: true,
      style: tdBlinkStyle,
    },
    {
      dataField: "noOfAccounts",
      text: "No. Of Accounts",
      formatter: formatNumber,
      sort: false,
      style: tdBlinkStyle,
    },
    {
      dataField: "reconPercentage",
      text: "Recon Completion",
      formatter: (cell) => (cell ? <ProgressBar percentage={cell} /> : "-"),
      sort: false,
      style: tdBlinkStyle,
    },
    {
      dataField: "status",
      text: "Status",
      sort: false,
      style: tdBlinkStyle,
    },
    {
      dataField: "action",
      text: "Recon Actions",
      formatter: (cell, row) => {
        return (
          <div className="d-flex justify-content-around align-item-center w-100">
            <div className="item">
              <OverlayTrigger
                placement="top"
                overlay={
                  allPermissions?.some(
                    (item) => item.id === "clk_operations_recon_stop"
                  ) ? (
                    row?.status === "COMPLETED" ||
                    row?.status === "DELETING" ? (
                      <Tooltip style={{ display: "none" }}></Tooltip>
                    ) : (
                      <Tooltip id="button-tooltip-2">
                        {row?.status === "MANUALLY-STOPPED"
                          ? "Stopped"
                          : "Stop Recon"}
                      </Tooltip>
                    )
                  ) : (
                    <Tooltip>
                      You do not have permission to perform this action
                    </Tooltip>
                  )
                }
              >
                <i
                  className="fa fa-stop-circle-o"
                  aria-hidden="true"
                  id={
                    allPermissions?.some(
                      (item) => item.id === "clk_operations_recon_stop"
                    )
                      ? "recon-stop"
                      : "recon-stop-disabled"
                  }
                  style={{
                    fontSize: "18px",
                    cursor: allPermissions?.some(
                      (item) => item.id === "clk_operations_recon_stop"
                    )
                      ? row?.status !== "COMPLETED" &&
                        row?.status !== "DELETING" &&
                        row?.status !== "MANUALLY-STOPPED"
                        ? "pointer"
                        : ""
                      : "no-drop",
                    color:
                      allPermissions?.some(
                        (item) => item.id === "clk_operations_recon_stop"
                      ) &&
                      row?.status !== "MANUALLY-STOPPED" &&
                      row?.status !== "DELETING" &&
                      row?.status !== "COMPLETED"
                        ? "#ff0008"
                        : "",
                  }}
                />
              </OverlayTrigger>
            </div>

            <div className="item">
              <OverlayTrigger
                placement="top"
                overlay={
                  allPermissions?.some(
                    (item) => item.id === "clk_operations_recon_delete"
                  ) ? (
                    <Tooltip id="button-tooltip-2">Delete Recon</Tooltip>
                  ) : (
                    <Tooltip>
                      You do not have permission to perform this action
                    </Tooltip>
                  )
                }
              >
                <i
                  className="fa fa-trash-o"
                  aria-hidden="true"
                  id={
                    allPermissions?.some(
                      (item) => item.id === "clk_operations_recon_delete"
                    )
                      ? "recon-delete"
                      : "recon-delete-disabled"
                  }
                  style={{
                    fontSize: "18px",
                    cursor: allPermissions?.some(
                      (item) => item.id === "clk_operations_recon_delete"
                    )
                      ? "pointer"
                      : "no-drop",
                  }}
                />
              </OverlayTrigger>
            </div>
            <div className="item">
              <OverlayTrigger
                placement="top"
                overlay={
                  allPermissions?.some(
                    (item) => item.id === "clk_operations_recon_re_run"
                  ) ? (
                    <Tooltip id="button-tooltip-2">Re-run Recon</Tooltip>
                  ) : (
                    <Tooltip>
                      You do not have permission to perform this action
                    </Tooltip>
                  )
                }
              >
                <i
                  className="fa fa-refresh"
                  aria-hidden="true"
                  id={
                    allPermissions?.some(
                      (item) => item.id === "clk_operations_recon_re_run"
                    )
                      ? "recon-re-run"
                      : "recon-re-run-disabled"
                  }
                  style={{
                    fontSize: "18px",
                    cursor: allPermissions?.some(
                      (item) => item.id === "clk_operations_recon_re_run"
                    )
                      ? "pointer"
                      : "no-drop",
                  }}
                />
              </OverlayTrigger>
            </div>
          </div>
        );
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation();
          if (
            row.status !== "COMPLETED" &&
            row.status !== "MANUALLY-STOPPED" &&
            row.status !== "DELETING" &&
            e.target.id === "recon-stop"
          ) {
            setShowConfirmModal(true);
            setReconActionData({
              ...reconActionData,
              action: "stop",
              batchId: row?.batchId,
            });
          } else if (e.target.id === "recon-delete") {
            setShowConfirmModal(true);
            setReconActionData({
              ...reconActionData,
              action: "delete",
              batchId: row?.batchId,
            });
          } else if (e.target.id === "recon-re-run") {
            setShowConfirmModal(true);
            setReconActionData({
              ...reconActionData,
              action: "re-run",
              batchId: row?.batchId,
            });
          }
        },
      },
      style: tdBlinkStyle,
    },
    {
      dataField: "totalPairsExecuted",
      text: "Total Pairs Executed",
      formatter: formatNumber,
      sort: false,
      style: tdBlinkStyle,
    },
    {
      dataField: "matched",
      text: "Matched",
      style: { color: "#00AF91", cursor: "default" },
      formatter: formatNumber,
      sort: false,
      style: tdBlinkStyle,
    },
    {
      dataField: "unmatched",
      text: "Unmatched",
      style: { color: "#ef476f", cursor: "default" },
      formatter: formatNumber,
      sort: false,
      style: tdBlinkStyle,
    },
    {
      dataField: "totalMissing",
      text: "Missing",
      style: { color: "#ef476f" },
      formatter: formatNumber,
      sort: false,
      style: tdBlinkStyle,
    },
    {
      dataField: "totalErrors",
      text: "Errors",
      style: { color: "#ef476f" },
      formatter: formatNumber,
      sort: false,
      style: tdBlinkStyle,
    },
  ];

  const highlightRowById = (id) => {
    const rowIndex = data.findIndex((row) => row.batchId == id);
    if (rowIndex !== -1) {
      setHighlightedRow(rowIndex);
      setTimeout(() => {
        setHighlightedRow(null);
        setReconId(null);
      }, 3000); // Change color back after 3000 milliseconds (3 seconds)
    }
  };
  const scrollToDivRef = useRef(null);
  useEffect(() => {
    if (
      data.findIndex((row) => row.batchId == reconId) !== -1 &&
      data &&
      !loading
    ) {
      scrollToDivRef?.current?.scrollIntoView({ behavior: "smooth" });
      highlightRowById(reconId);
    }
  }, [reconId, data, loading]);
  return (
    <>
      <div className="row mt-3 text-select">
        <h5 className="title text-bright ml-3 mb-4">Recon Status</h5>

        <div className="col-12">
          <div className="overflow-y-auto">
            <div id="anchor1" ref={scrollToDivRef}>
              <BootstrapTable
                bordered={false}
                classes="rs-table table-layout-auto mb-0 table-custom-style"
                wrapperClasses="overflow-y-auto mb-2 fancy-scroll"
                keyField="id"
                data={data}
                columns={columnsReconStatus}
                remote={{ sort: true }}
                bootstrap4={true}
                onTableChange={onSortTable}
                rowClasses={(row, rowIndex) => {
                  return highlightedRow === rowIndex ? "blink" : "";
                }}
                noDataIndication={
                  loading ? <LoaderComponent /> : "No data found!"
                }
              ></BootstrapTable>
            </div>
          </div>
        </div>
        <div className="col-12 mb-3">
          <Pagination
            setPage={setPage}
            page={page}
            pageSize={pageSize}
            setPageSize={setPageSize}
            totalPages={totalPages}
            totalElements={totalElements}
            data={data || []}
          />
        </div>
      </div>

      <ConfirmationModal
        isShow={showConfirmModal}
        title={`${
          reconActionData?.action?.charAt(0)?.toUpperCase() +
          reconActionData?.action.slice(1)
        } Recon`}
        message={`Are you sure you want to ${reconActionData?.action} the Recon?`}
        hideModal={() => {
          setShowConfirmModal(false);
        }}
        confirmAction={() => reconAction()}
      />
    </>
  );
};
