import React, { useState, useEffect } from "react";
import AddIcon from "../../../assets/images/add-icon.png";
import { Modal } from "react-bootstrap";
import ButtonTransparent from "../../../components/Buttons/ButtonTransparent";
import ButtonBasic from "../../../components/Buttons/ButtonBasic";
import ShowForPermission from "../../../utils/permissionsWrapper";
import axiosInstance from "../../../utils/axiosInstance";
import LoaderComponent from "../../../components/Spinner/LoaderComponent";
import NewFilter from "../../../components/FilterDropdown/NewFilter";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import Toast from "../../../components/Toast/Toast";
import EditIcon from "../../../assets/images/edit.png";
import TrashIcon from "../../../assets/images/trash.png";
import BootstrapTable from "react-bootstrap-table-next";
import ViewModal from "./ViewModal";
import buttonChecker from "../../../utils/buttonsPermissionsChecker";
import { useDateTime } from "../../../components/Helper/DateTime";

const obj = {
  name: "",
  regionId: "",
  glAccount: "",
};

const CitManagement = () => {
  const { setRefresh } = useDateTime();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [id, setId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [cit, setCit] = useState(obj);
  const [regions, setRegions] = useState([]);
  const [glAccList, setGlAccList] = useState([]);
  const mapRegions = (regions) => {
    const arr = [];
    // eslint-disable-next-line
    regions.map((region) => {
      arr.push({
        id: region.id,
        label: region.label,
      });
      if (region.subClass) {
        arr.push(...mapRegions(region.subClass));
      }
    });
    return arr;
  };
  const getRegions = () => {
    setLoading(true);
    axiosInstance
      .get("/hierarchical/filters?filter_name=region")
      .then((res) => {
        setRegions(mapRegions(res.data?.filters[0].filterData));
        getCITs();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCITs = () => {
    axiosInstance
      .get("/cits")
      .then((res) => {
        setData(res.data.cits);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getGlAccounts = async () => {
    axiosInstance
      .get(`/accounts/account-list?type_indicator=gl`)
      .then((res) => {
        setGlAccList(
          res?.data?.accounts?.map((i) => {
            return {
              label: i?.name,
              value: i?.id,
            };
          })
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const HandleDeleteCit = () => {
    setLoading(true);
    axiosInstance
      .delete(`/cits/${id}`)
      .then((res) => {
        // eslint-disable-next-line
        if (res.message.status == 200) {
          setData(data.filter((cit) => cit.id !== id));
          setIsDeleteModal(false);
          setLoading(false);
          Toast("Record deleted!", "success");
        } else {
          setLoading(false);
          setIsDeleteModal(false);
          Toast(res.message.description, "error");
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setIsDeleteModal(false);
      });
  };
  const handleEdit = (id) => {
    setLoading(true);
    axiosInstance
      .put(`/cits`, {
        data: { cits: [{ ...cit, id }] },
      })
      .then((res) => {
        // eslint-disable-next-line
        if (res.message.status == 200) {
          setIsEdit(false);
          setLoading(false);
          setShowModal(false);
          const newData = [...data];
          newData[newData.findIndex((cit) => cit.id === id)] = res.data.cits[0];
          setData([...newData]);
          setCit(obj);
          Toast("Record updated!", "success");
        } else {
          Toast(res.message.description, "error");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        Toast(err.response.data.message.description, "error");
        setLoading(false);
      });
  };
  const handleAdd = () => {
    setLoading(true);
    axiosInstance
      .post("/cits", { data: { cits: [cit] } })
      .then((res) => {
        // eslint-disable-next-line
        if (res.message.status == 200) {
          setLoading(false);
          setShowModal(false);
          setData([...data, res.data.cits[0]]);
          setCit(obj);
          Toast("Record added!", "success");
        } else {
          Toast(res.message.description, "error");
          setLoading(false);
        }
      })
      .catch((err) => {
        Toast(err.response.data.message.description, "error");
        console.log(err);
        setLoading(false);
      });
  };
  const handleInputChange = (e, field) => {
    if (field) {
      setCit({ ...cit, [field]: e });
    } else {
      const { name, value } = e.target;
      setCit({ ...cit, [name]: value });
    }
  };
  const handleSubmit = () => {
    if (isEdit) {
      buttonChecker("clk_cit_edit") && handleEdit(id);
    } else {
      buttonChecker("clk_cit_add") && handleAdd();
    }
  };
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      setShowViewModal(true);
      setId(row.id);
    },
  };
  useEffect(() => {
    // getCITs();
    getRegions();
    getGlAccounts();
    setRefresh(() => () => {
      getRegions();
    });
    return () => {
      setRefresh(() => () => {});
    };
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (isEdit && id) {
      setCit(data.find((cit) => cit.id === id));
    }
    // eslint-disable-next-line
  }, [isEdit, id]);
  const columns = [
    { hidden: true, dataField: "id", text: "ID" },
    {
      dataField: "name",
      text: "Name",
      formatter: (cell) => cell ?? "-",
      sort: true,
    },
    {
      dataField: "regionId",
      text: "Region",
      formatter: (cell) =>
        regions.find((region) => region.id === cell)?.label ?? "-",
      sort: true,
    },
    {
      dataField: "glAccount",
      text: "GL Account",
      formatter: (cell) => cell ?? "-",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row, rowIndex) => {
        return (
          <div>
            <img
              id={"editIconRow" + rowIndex}
              width={24}
              height={24}
              src={EditIcon}
              className={`right-btn mr-2`}
              alt="edit-btn"
              style={{ opacity: buttonChecker("clk_cit_edit") ? "1" : "0.3" }}
            />
            <img
              id={"deleteIconRow" + rowIndex}
              width={24}
              height={24}
              src={TrashIcon}
              className={`right-btn mr-3`}
              alt="dlt-btn"
              style={{ opacity: buttonChecker("clk_cit_del") ? "1" : "0.3" }}
            />
          </div>
        );
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation();
          if (buttonChecker("clk_cit_edit") && e.target.alt === "edit-btn") {
            setId(row.id);
            setIsEdit(true);
            setShowModal(true);
          } else if (
            buttonChecker("clk_cit_del") &&
            e.target.alt === "dlt-btn"
          ) {
            setIsDeleteModal(true);
            setId(row.id);
          }
        },
      },
    },
  ];
  return (
    <>
      {loading && (
        <div
          className="spinner-center"
          style={{ top: "0%", left: "0%", zIndex: 2000 }}
        >
          <LoaderComponent />
        </div>
      )}
      <div style={{ opacity: !loading ? "1" : "0.07" }}>
        <div className="row align-items-center">
          <div className="col-6">
            <div className="d-flex align-items-center">
              <div
                style={{
                  paddingRight: "10px",
                  marginRight: "10px",
                }}
              >
                CIT Details
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="d-flex justify-content-end align-items-center">
              <div>
                <ShowForPermission permission="clk_cit_add">
                  <ButtonTransparent
                    id="add_cit"
                    onClick={() => {
                      setShowModal(true);
                      setIsEdit(false);
                      setCit(obj);
                    }}
                    title={"Add New Vendor"}
                    icon={AddIcon}
                  />
                </ShowForPermission>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12">
            <BootstrapTable
              id="citTable"
              bordered={false}
              bootstrap4={true}
              classes="rs-table table-layout-auto table-custom-style"
              wrapperClasses="overflow-y-auto fancy-scroll"
              keyField="id"
              data={data}
              columns={columns}
              rowEvents={rowEvents}
              noDataIndication="No data found!"
            />
          </div>
        </div>
        <Modal
          backdrop={true}
          size="md"
          show={showModal}
          onHide={() => setShowModal(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>New CIT Vendor</Modal.Header>
          <Modal.Body scrollable="true">
            <div className="row mt-3">
              <div className="col-12">
                <div className="d-flex flex-column">
                  <div className="d-flex flex-column">
                    <div
                      style={{
                        fontSize: "10px",
                        fontFamily: "Montserrat SemiBold",
                        marginBottom: 5,
                      }}
                    >
                      VENDOR NAME
                    </div>
                    <input
                      id="vendorName"
                      type="text"
                      className="rs-input"
                      placeholder="Enter Vendor Name"
                      name="name"
                      value={cit.name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="d-flex flex-column mt-4">
                    <div
                      style={{
                        fontSize: "10px",
                        fontFamily: "Montserrat SemiBold",
                        marginBottom: 5,
                        textTransform: "uppercase",
                      }}
                    >
                      Select Region
                    </div>
                    <NewFilter
                      id="regionId"
                      title={
                        regions.find((r) => r.id === cit.regionId)?.label ??
                        "Select Region"
                      }
                      type={"checkbox"}
                      data={regions}
                      field={"id"}
                      filter={cit.regionId}
                      isSearchable
                      searchField="label"
                      onChange={(e) => handleInputChange(e, "regionId")}
                    />
                  </div>
                  <div className="d-flex flex-column mt-4">
                    <div
                      style={{
                        fontSize: "10px",
                        fontFamily: "Montserrat SemiBold",
                        marginBottom: 5,
                      }}
                    >
                      GL ACCOUNT
                    </div>
                    {/* <input
                      type="text"
                      className="rs-input"
                      placeholder="Enter GL Account"
                      name="glAccount"
                      value={cit.glAccount}
                      onChange={handleInputChange}
                    /> */}
                    <NewFilter
                      id="glAccount"
                      title={
                        glAccList.find((i) => i?.value?.toString() === cit.glAccount?.toString())
                          ?.label ?? "Select GL Account"
                      }
                      type={"checkbox"}
                      field={"value"}
                      data={glAccList}
                      filter={parseInt(cit?.glAccount)}
                      onChange={(e) => setCit({ ...cit, glAccount: e })}
                      isSearchable
                      searchField="label"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              {/* <ButtonBasic
                title={isEdit ? "Update" : "Add"}
                onClick={handleSubmit}
              /> */}
              <button
                id="submit"
                type="submit"
                name="submit"
                className={"btn btn-info btn-md mr-2"}
                onClick={handleSubmit}
              >
                {isEdit ? "Update" : "Add"}
              </button>
              <ButtonBasic
                onClick={() => {
                  setShowModal(false);
                  setCit(obj);
                  setId(null);
                }}
                title={"Cancel"}
              />
            </div>
          </Modal.Footer>
        </Modal>
        <ConfirmationModal
          isShow={isDeleteModal}
          title={"Delete Institution"}
          message={"Are you sure you want to delete this CIT?"}
          hideModal={() => {
            setIsDeleteModal(false);
          }}
          confirmAction={() =>
            buttonChecker("clk_cit_del") && HandleDeleteCit()
          }
        />
        {showViewModal && (
          <ViewModal
            id={id}
            show={showViewModal}
            onHide={setShowViewModal}
            regions={regions}
          />
        )}
      </div>
    </>
  );
};

export default CitManagement;
