import React, { createRef } from "react";
import "../../App.css";
import ReactECharts from "echarts-for-react";
import SingleInfoCard from "../../components/SingleInfoCard/SingleInfoCard";
import $ from "jquery";
import RoundedTab from "../../components/RoundedTab/RoundedTab";
import ButtonBasic from "../../components/Buttons/ButtonBasic";
import FilterIcon from "../../assets/images/filter.png";
import axiosInstance from "../../utils/axiosInstance";
import LoaderComponent from "../../components/Spinner/LoaderComponent";
import renderChartFunction from "../../utils/helpers/renderChartFilter";
import buttonChecker from "../../utils/buttonsPermissionsChecker";
import pieChart from "../../components/Charts/pieChart";
import horizontalBarChart from "../../components/Charts/horizontalBarChart";
import barWithLineChart from "../../components/Charts/barWithLineChart";
import miniChart from "../../components/Charts/miniChart";
import lineChart from "../../components/Charts/lineChart";
import Filter from "../../components/FilterDropdown/GenericFilter";
import FilterDropdown from "../../components/FilterDropdown/FilterDropdown";
import withDateTime from "../../utils/timedatehoc";
import CurrencyFormat from "react-currency-format";
import { regionCheck, mapdata } from "../../utils/regionHelper";
import {
  mapFilters,
  filtersParams,
  handleFilterChange,
} from "../../utils/filtersHelper";
import {
  balancingSummaryPalette,
  colorPalette,
  PASTEL_PALETTE,
  REVERSE_PASTEL,
  YELLOW_PALETTE,
} from "../../constants/ColorPalette";
class AtmDrillDown extends React.Component {
  constructor(props) {
    super(props);
    this.filter = createRef();
    this.state = {
      filterParams: {},
      filter: {},
      filters: {},
      filterData: [],
      showTransactionSummary: false,
      showCashSummary: true,
      locationFilterData: {
        text: "Location",
        data: [
          {
            label: "On Site",
            value: "On Site",
            checked: true,
            isSearched: true,
          },
          {
            label: "Off Site",
            value: "Off Site",
            checked: true,
            isSearched: true,
          },
        ],
      },
      atmTypeFilterData: {
        text: "ATM Type",
        data: [
          {
            label: "Cash Dispense",
            value: "Cash Dispense",
            checked: true,
            isSearched: true,
          },
          {
            label: "Cash Dispense",
            value: "Cash Dispense",
            checked: true,
            isSearched: true,
          },
        ],
      },

      atmKPITiles: {},

      topCashDispensedOptionsLoading: true,
      topCashDispensedOptionsData: {
        title: "",
        yAxis: {
          data: [],
        },
        series: {
          data: [],
        },
      },

      topCashDispensedCountOptionsLoading: true,
      topCashDispensedCountOptionsData: {
        title: "",
        yAxis: {
          data: [],
        },
        series: {
          data: [],
        },
      },

      topExceptionsOptionsLoading: true,
      topExceptionsOptionsData: {
        title: "",
        yAxis: {
          data: [],
        },
        series: {
          data: [],
        },
      },

      leastCashDispensedOptionsLoading: true,
      leastCashDispensedOptionsData: {
        title: "",
        yAxis: {
          data: [],
        },
        series: {
          data: [],
        },
      },

      leastCashDispensedCountOptionsLoading: true,
      leastCashDispensedCountOptionsData: {
        title: "",
        yAxis: {
          data: [],
        },
        series: {
          data: [],
        },
      },

      leastExceptionsOptionsLoading: true,
      leastExceptionsOptionsData: {
        title: "",
        yAxis: {
          data: [],
        },
        series: {
          data: [],
        },
      },

      peakTimeOptionLoading: true,
      peakTimeOptionData: {
        title: "",
        xAxis: {
          data: [],
        },
        series: [
          {
            data: [],
          },
          {
            data: [],
          },
        ],
      },

      cashSummaryOptionsLoading: true,
      cashSummaryOptionsData: {
        title: "",
        xAxis: {
          data: [],
        },
        series: [
          {
            data: [],
          },
        ],
      },

      cashUtilizationOptionsLoading: true,
      cashUtilizationOptionsData: {
        title: "",
        amountCount: {},
        xAxis: {
          data: [],
        },
        series: {
          data: [],
        },
      },

      cashReplenishmentOptionsLoading: true,
      cashReplenishmentOptionsData: {
        title: "",
        totalReplenish: "",
        xAxis: {
          data: [],
        },
        series: {
          data: [],
        },
      },

      costOfCashOptionsLoading: true,
      costOfCashOptionsData: {
        title: "",
        xAxis: {
          data: [],
        },
        series: {
          data: [],
        },
      },

      transactionSummaryOptionsLoading: true,
      transactionSummaryOptionsData: {
        title: "",
        xAxis: {
          data: [],
        },
        series: [
          {
            data: [],
          },
        ],
      },

      approvedTransactionsOptionsLoading: true,
      approvedTransactionsOptionsData: {
        title: "",
        approvedTotalAmountCount: "",
        xAxis: {
          data: [],
        },
        series: {
          data: [],
        },
      },

      declinedTransactionsOptionsLoading: true,
      declinedTransactionsOptionsData: {
        title: "",
        declinedAmountCount: "",
        xAxis: {
          data: [],
        },
        series: {
          data: [],
        },
      },

      onUsTransactionsOptionsLoading: true,
      onUsTransactionsOptionsData: {
        title: "",
        amountCountDto: "",
        xAxis: {
          data: [],
        },
        series: {
          data: [],
        },
      },

      notOnUsTransactionsOptionsLoading: true,
      notOnUsTransactionsOptionsData: {
        title: "",
        notOnUsAmountCount: "",
        xAxis: {
          data: [],
        },
        series: {
          data: [],
        },
      },

      balancingSummaryOptionsLoading: true,
      balancingSummaryOptionsData: {
        title: "",
        data: [],
      },

      previousDaySumOptionsLoading: true,
      previousDaySumOptionsData: {
        title: "",
        data: [],
      },
      atmProfitabilityLoading: true,
      atmProfitabilityData: {
        title: "",
        xAxis: {
          data: [],
        },
        series: [
          {
            data: [],
          },
        ],
      },

      transactionDestinationOptionsLoading: true,
      transactionDestinationOptionsData: {
        title: "",
        yAxis: {
          data: [],
        },
        series: {
          data: [],
        },
      },

      suspenseAccountOptionsLoading: true,
      suspenseAccountOptionsData: {
        title: "",
        data: [],
      },

      transactionCategorizationOptionsLoading: true,
      transactionCategorizationOptionsData: {
        title: "",
        data: [],
      },

      declineTransactionCategorizationOptionsLoading: true,
      declineTransactionCategorizationOptionsData: {
        title: "",
        data: [],
      },

      regions: [],
      deviceId: 0,
      gotResponse: false,
    }; // state end
  }
  handleFilter = async () => {
    this.enableLoaders();
    const { filters } = this.state;
    let allFilters = filtersParams(filters, this.filter.current);
    await Promise.all([
      this.getAtmKpiTiles(allFilters),
      this.getTopATM(allFilters),
      this.getBottomATM(allFilters),
      this.getAtmPeakTime(allFilters),
      this.getTransactionSummary(allFilters),
      this.getCashSummary(allFilters),
      this.getAtmReconCharts(allFilters),
      this.getSuspenseSummary(allFilters),
      this.getTransactionDestination(allFilters),
      this.getTransactionCategorization(allFilters),
      this.getDeclineTransactionCategorization(allFilters),
    ]);
  };
  componentDidMount() {
    $(".count-num").each(function () {
      var $this = $(this);
      $({ Counter: 0 }).animate(
        { Counter: $this.text() },
        {
          duration: 2000,
          easing: "swing",
          step: function () {
            $this.text(Math.ceil(this.Counter));
          },
        }
      );
    });
    this.getFilters();
    this.props.dateTime.setRefresh(() => () => {
      this.handleFilter();
    });
  }
  componentWillUnmount() {
    this.props.dateTime.setRefresh(() => () => {});
  }

  async getFilters() {
    axiosInstance
      .get("hierarchical/filters?screen_id=atm_dashboard")
      .then((res) => {
        let regions = res.data.filters.filter(
          ({ filterName }) => filterName === "region"
        );
        let otherFilters = res.data.filters.filter(
          ({ filterName }) => filterName !== "region"
        );
        const obj = mapFilters(res.data.filters);
        let filter = "";
        if (regions.length > 0) {
          regions[0].filterData = mapdata(regions[0].filterData);
          regions[0].filterData.forEach((x) => {
            x.checked = x.param1 === "1" ? true : false;
          });
          filter = `&region_id=${regions[0].filterData
            .map(({ codeValue }) => codeValue)
            .join(",")}`;
          this.filter.current = filter;
        }
        const filterParams = filtersParams(obj, filter);
        this.setState(
          {
            filters: obj,
            regions,
            gotResponse: true,
            filterData: otherFilters,
          },
          () => {
            this.getAtmKpiTiles(filterParams);
            this.getTopATM(filterParams);
            this.getBottomATM(filterParams);
            this.getAtmPeakTime(filterParams);
            this.getTransactionSummary(filterParams);
            this.getCashSummary(filterParams);
            this.getAtmReconCharts(filterParams);
            this.getSuspenseSummary(filterParams);
            this.getTransactionDestination(filterParams);
            this.getTransactionCategorization(filterParams);
            this.getDeclineTransactionCategorization(filterParams);
          }
        );
      })
      .catch((err) => {
        console.log(err);
        this.setState({ gotResponse: true });
      });
  }

  async getAtmKpiTiles(filter) {
    this.setState({
      atmKPITiles: {},
    });
    try {
      let url = `/charts/atm-kpi-tiles?channel=atm${filter}`;
      const response = await axiosInstance.get(url);
      const atmKPITiles = response?.data?.kpiTiles ?? {};
      this.setState({
        atmKPITiles: {
          ...atmKPITiles,
          chart: atmKPITiles?.chart?.map((obj) => ({
            ...obj,
            totalCount: obj.totalCount,
          })),
        },
      });
    } catch (err) {
      console.log("/atm_kpi_tiles response error ", err);
    }
  }

  async getTopATM(filter) {
    try {
      const {
        topCashDispensedOptionsData,
        topCashDispensedCountOptionsData,
        topExceptionsOptionsData,
      } = this.state;

      const response = await axiosInstance.get(
        `/charts/top-charts?channel=atm${filter}`
      );
      const { cashDispensedAmount, cashDispensedCount, topException } =
        response?.data?.topCharts;

      // for setting cash dispensed amount chart
      topCashDispensedOptionsData.yAxis.data = [];
      topCashDispensedOptionsData.series.data = [];
      topCashDispensedOptionsData.title = cashDispensedAmount.header.title;
      cashDispensedAmount?.chart?.reverse()?.forEach((obj) => {
        topCashDispensedOptionsData.yAxis.data.push(obj.label);
        topCashDispensedOptionsData.series.data.push(obj.value);
      });

      // for setting top cash dispensed count
      topCashDispensedCountOptionsData.yAxis.data = [];
      topCashDispensedCountOptionsData.series.data = [];
      topCashDispensedCountOptionsData.title = cashDispensedCount.header.title;
      cashDispensedCount?.chart?.reverse()?.forEach((obj) => {
        topCashDispensedCountOptionsData.yAxis.data.push(obj.label);
        topCashDispensedCountOptionsData.series.data.push(obj.value);
      });

      // for setting top exceptions
      topExceptionsOptionsData.yAxis.data = [];
      topExceptionsOptionsData.series.data = [];
      topExceptionsOptionsData.title = topException.header.title;
      topException?.chart?.reverse()?.forEach((obj) => {
        topExceptionsOptionsData.yAxis.data.push(obj.label);
        topExceptionsOptionsData.series.data.push(obj.value);
      });

      this.setState({
        topCashDispensedOptionsData,
        topCashDispensedCountOptionsData,
        topExceptionsOptionsData,
        topCashDispensedOptionsLoading: false,
        topCashDispensedCountOptionsLoading: false,
        topExceptionsOptionsLoading: false,
      });
    } catch (err) {
      console.log("/api/charts/top_atms response error ", err);
      this.setState({
        topCashDispensedCountOptionsLoading: false,
        topExceptionsOptionsLoading: false,
        topCashDispensedOptionsLoading: false,
      });
    }
  }

  async getBottomATM(filter) {
    try {
      const response = await axiosInstance.get(
        `/charts/bottom-charts?channel=atm${filter}`
      );
      const {
        leastCashDispensedOptionsData,
        leastCashDispensedCountOptionsData,
        leastExceptionsOptionsData,
      } = this.state;
      const { cashDispensedAmount, cashDispensedCount, exception } =
        response.data?.bottomCharts;

      // for setting cash dispensed amount chart
      leastCashDispensedOptionsData.yAxis.data = [];
      leastCashDispensedOptionsData.series.data = [];
      leastCashDispensedOptionsData.title = cashDispensedAmount.header.title;
      cashDispensedAmount?.chart?.reverse()?.forEach((obj) => {
        leastCashDispensedOptionsData.yAxis.data.push(obj.label);
        leastCashDispensedOptionsData.series.data.push(obj.value);
      });

      // for setting top cash dispensed count
      leastCashDispensedCountOptionsData.yAxis.data = [];
      leastCashDispensedCountOptionsData.series.data = [];
      leastCashDispensedCountOptionsData.title =
        cashDispensedCount.header.title;
      cashDispensedCount?.chart?.reverse()?.forEach((obj) => {
        leastCashDispensedCountOptionsData.yAxis.data.push(obj.label);
        leastCashDispensedCountOptionsData.series.data.push(obj.value);
      });

      // for setting top exceptions
      leastExceptionsOptionsData.yAxis.data = [];
      leastExceptionsOptionsData.series.data = [];
      leastExceptionsOptionsData.title = exception.header.title;
      exception?.chart?.reverse()?.forEach((obj) => {
        leastExceptionsOptionsData.yAxis.data.push(obj.label);
        leastExceptionsOptionsData.series.data.push(obj.value);
      });

      this.setState({
        leastCashDispensedOptionsData,
        // leastCashDispensedCountOptionsData,
        leastExceptionsOptionsData,
        leastCashDispensedOptionsLoading: false,
        leastCashDispensedCountOptionsLoading: false,
        leastExceptionsOptionsLoading: false,
      });
    } catch (err) {
      console.log("/api/charts/BottomATMs response error ", err);
      this.setState({
        leastCashDispensedCountOptionsLoading: false,
        leastExceptionsOptionsLoading: false,
        leastCashDispensedOptionsLoading: false,
      });
    }
  }

  async getAtmPeakTime(filter) {
    try {
      const { peakTimeOptionData } = this.state;
      const response = await axiosInstance.get(
        `/charts/peak-times?channel=atm${filter}`
      );
      const { atmPeakTimes } = response.data;
      peakTimeOptionData.title = atmPeakTimes?.header?.title;
      peakTimeOptionData.xAxis.data = [];
      peakTimeOptionData.series[0].data = [];
      peakTimeOptionData.series[1].data = [];
      atmPeakTimes?.chart?.forEach((obj) => {
        peakTimeOptionData.xAxis.data.push(obj.time);
        peakTimeOptionData.series[0].data.push(obj.totalCount);
        peakTimeOptionData.series[1].data.push(obj.totalCount);
      });

      this.setState({ peakTimeOptionData, peakTimeOptionLoading: false });
    } catch (err) {
      console.log("/api/charts/atm_peak_times response error ", err);
      this.setState({ peakTimeOptionLoading: false });
    }
  }

  async getAtmReconCharts(filter) {
    try {
      const {
        balancingSummaryOptionsData,
        previousDaySumOptionsData,
        atmProfitabilityData,
      } = this.state;

      // balancing summary
      try {
        const response = await axiosInstance.get(
          `/charts/atm-balancing-summary?&channel=atm${filter}`
        );

        balancingSummaryOptionsData.data = [];
        balancingSummaryOptionsData.title = response.data?.header?.title;
        response.data?.chart?.forEach((obj) => {
          balancingSummaryOptionsData.data.push({
            value: obj.count,
            name: obj.status,
            color:
              obj.status.toLowerCase() === "surplus"
                ? balancingSummaryPalette[1]
                : obj.status.toLowerCase() === "shortage"
                ? balancingSummaryPalette[0]
                : obj.status.toLowerCase() === "in-staging"
                ? balancingSummaryPalette[2]
                : obj.status.toLowerCase() === "balanced"
                ? balancingSummaryPalette[3]
                : null,
          });
        });
      } catch (error) {
        console.log("balancing summary chart error", error);
      }

      // previous day summary
      try {
        const response = await axiosInstance.get(
          `/charts/matching-summary?channel=atm${filter}`
        );
        previousDaySumOptionsData.data = [];
        previousDaySumOptionsData.title = response.data?.header?.title;
        previousDaySumOptionsData?.data?.push({
          name: "matched",
          value: response.data?.chart?.matchedCount,
        });
        previousDaySumOptionsData?.data?.push({
          name: "unmatched",
          value: response.data?.chart?.unmatchedCount,
        });
        previousDaySumOptionsData?.data?.push({
          name: "missing",
          value: response.data?.chart?.missingCount,
        });
        previousDaySumOptionsData?.data?.push({
          name: "error",
          value: response.data?.chart?.errorCount,
        });
      } catch (error) {
        console.log("matching summary charts error", error);
      }

      // atm profitability
      try {
        const response = await axiosInstance.get(
          `/charts/atm-profitability?channel=atm${filter}`
        );
        atmProfitabilityData.xAxis.data = [];
        atmProfitabilityData.series[0].data = [];
        atmProfitabilityData.title = response.data?.header?.title;
        response.data?.chart?.forEach((atmProf) => {
          atmProfitabilityData.xAxis.data.push(atmProf.date);
          atmProfitabilityData.series[0].data.push(atmProf.profitAmount);
        });
      } catch (error) {
        console.log("matching summary chart error", error);
      }

      this.setState({
        balancingSummaryOptionsData,
        previousDaySumOptionsData,
        atmProfitabilityData,
        previousDaySumOptionsLoading: false,
        balancingSummaryOptionsLoading: false,
        atmProfitabilityLoading: false,
      });
    } catch (err) {
      console.log("err ", err);
      this.setState({
        balancingSummaryOptionsLoading: false,
        previousDaySumOptionsLoading: false,
        atmProfitabilityLoading: false,
      });
    }
  }

  async getTransactionSummary(filter) {
    try {
      let url = `charts/transaction-summary?channel=atm${filter}`;
      const {
        transactionSummaryOptionsData,
        approvedTransactionsOptionsData,
        declinedTransactionsOptionsData,
        onUsTransactionsOptionsData,
        notOnUsTransactionsOptionsData,
      } = this.state;
      const response = await axiosInstance.get(url);
      const {
        transactionSummaryData,
        approvedTransactions,
        declinedTransactions,
        onUsTransactions,
        notOnUsTransactions,
      } = response.data?.transactionSummary;

      // transaction summary chart
      transactionSummaryOptionsData.xAxis.data = [];
      transactionSummaryOptionsData.series[0].data = [];
      transactionSummaryOptionsData.title =
        transactionSummaryData?.header?.title;
      transactionSummaryData?.chart?.forEach((obj) => {
        transactionSummaryOptionsData.xAxis.data.push(obj.postingDate);
        transactionSummaryOptionsData.series[0].data.push(
          obj.totalTransactions
        );
      });

      // approved transactions chart
      approvedTransactionsOptionsData.xAxis.data = [];
      approvedTransactionsOptionsData.series.data = [];
      approvedTransactionsOptionsData.title =
        approvedTransactions?.header?.title;
      approvedTransactionsOptionsData.approvedTotalAmountCount =
        approvedTransactions?.approvedAmountCount;
      approvedTransactions?.chart?.forEach((obj) => {
        approvedTransactionsOptionsData.xAxis.data.push(obj.postingDate);
        approvedTransactionsOptionsData.series.data.push(obj.totalTransactions);
      });

      // declined transactions chart
      declinedTransactionsOptionsData.xAxis.data = [];
      declinedTransactionsOptionsData.series.data = [];
      declinedTransactionsOptionsData.title =
        declinedTransactions?.header?.title;
      declinedTransactionsOptionsData.declinedAmountCount =
        declinedTransactions?.declinedAmountCount;
      declinedTransactions?.chart?.forEach((obj) => {
        declinedTransactionsOptionsData.xAxis.data.push(obj.postingDate);
        declinedTransactionsOptionsData.series.data.push(obj.totalTransactions);
      });

      // on us transactions chart
      onUsTransactionsOptionsData.xAxis.data = [];
      onUsTransactionsOptionsData.series.data = [];
      onUsTransactionsOptionsData.title = onUsTransactions?.header?.title;
      onUsTransactionsOptionsData.amountCountDto =
        onUsTransactions?.onUsAmountCount;
      onUsTransactions?.chart?.forEach((obj) => {
        onUsTransactionsOptionsData.xAxis.data.push(obj.postingDate);
        onUsTransactionsOptionsData.series.data.push(obj.totalTransactions);
      });

      // not on us transactions chart
      notOnUsTransactionsOptionsData.xAxis.data = [];
      notOnUsTransactionsOptionsData.series.data = [];
      notOnUsTransactionsOptionsData.title = notOnUsTransactions?.header?.title;
      notOnUsTransactionsOptionsData.notOnUsAmountCount =
        notOnUsTransactions?.notOnUsAmountCount;
      notOnUsTransactions?.chart?.forEach((obj) => {
        notOnUsTransactionsOptionsData.xAxis.data.push(obj.postingDate);
        notOnUsTransactionsOptionsData.series.data.push(obj.totalTransactions);
      });

      this.setState({
        transactionSummaryOptionsData,
        transactionSummaryOptionsLoading: false,
        approvedTransactionsOptionsData,
        approvedTransactionsOptionsLoading: false,
        declinedTransactionsOptionsData,
        declinedTransactionsOptionsLoading: false,
        onUsTransactionsOptionsData,
        onUsTransactionsOptionsLoading: false,
        notOnUsTransactionsOptionsData,
        notOnUsTransactionsOptionsLoading: false,
      });
    } catch (err) {
      console.log("/api/charts/transaction_summary response error ", err);
      this.setState({
        transactionSummaryOptionsLoading: false,
        approvedTransactionsOptionsLoading: false,
        declinedTransactionsOptionsLoading: false,
        onUsTransactionsOptionsLoading: false,
        notOnUsTransactionsOptionsLoading: false,
      });
    }
  }

  async getCashSummary(filter) {
    try {
      const {
        cashSummaryOptionsData,
        cashUtilizationOptionsData,
        cashReplenishmentOptionsData,
        costOfCashOptionsData,
      } = this.state;
      const response = await axiosInstance.get(
        `/charts/cash-summary?channel=atm${filter}`
      );
      const {
        cashSummaryChart,
        cashUtilization,
        cashReplenishment,
        costOfCash,
      } = response.data?.cashSummary;

      // cash summary chart
      cashSummaryOptionsData.xAxis.data = [];
      cashSummaryOptionsData.series[0].data = [];
      cashSummaryOptionsData.title = cashSummaryChart?.header?.title;
      cashSummaryChart?.chart?.forEach((obj) => {
        cashSummaryOptionsData.xAxis.data.push(obj.date);
        cashSummaryOptionsData.series[0].data.push(obj.amount);
      });

      // cash utilization chart
      cashUtilizationOptionsData.xAxis.data = [];
      cashUtilizationOptionsData.series.data = [];
      cashUtilizationOptionsData.title = cashUtilization?.header?.title;
      cashUtilizationOptionsData.amountCount =
        cashUtilization?.cashUtilizationAmount;
      cashUtilization?.chart?.forEach((obj) => {
        cashUtilizationOptionsData.xAxis.data.push(obj.date);
        cashUtilizationOptionsData.series.data.push(obj.percentage);
      });

      // cash replenishment chart
      cashReplenishmentOptionsData.xAxis.data = [];
      cashReplenishmentOptionsData.series.data = [];
      cashReplenishmentOptionsData.title = cashReplenishment?.header?.title;
      cashReplenishmentOptionsData.amountCount =
        cashReplenishment?.cashReplenishmentAmountCount;
      cashReplenishment?.chart?.forEach((obj) => {
        cashReplenishmentOptionsData.xAxis.data.push(obj.date);
        cashReplenishmentOptionsData.series.data.push(obj.amount);
      });

      // cost of cash chart
      costOfCashOptionsData.xAxis.data = [];
      costOfCashOptionsData.series.data = [];
      costOfCashOptionsData.title = costOfCash?.header?.title;
      costOfCashOptionsData.amountCount = costOfCash?.costOfCashAmount;

      costOfCash?.chart?.forEach((obj) => {
        costOfCashOptionsData.xAxis.data.push(obj.date);
        costOfCashOptionsData.series.data.push(obj.amount);
      });

      this.setState({
        cashSummaryOptionsData,
        cashSummaryOptionsLoading: false,
        cashUtilizationOptionsData,
        cashUtilizationOptionsLoading: false,
        cashReplenishmentOptionsData,
        cashReplenishmentOptionsLoading: false,
        costOfCashOptionsData,
        costOfCashOptionsLoading: false,
      });
    } catch (err) {
      console.log("/api/charts/cash_summary response error ", err);
      this.setState({
        cashSummaryOptionsLoading: false,
        cashUtilizationOptionsLoading: false,
        cashReplenishmentOptionsLoading: false,
        costOfCashOptionsLoading: false,
      });
    }
  }

  async getTransactionDestination(filter) {
    try {
      const { transactionDestinationOptionsData } = this.state;
      const response = await axiosInstance.get(
        `/charts/transaction-destinations?channel=atm${filter}`
      );

      const { atmTransactionDestinationDto, header } = response?.data;

      // transaction destination chart
      transactionDestinationOptionsData.yAxis.data = [];
      transactionDestinationOptionsData.series.data = [];
      transactionDestinationOptionsData.title = header?.title;
      atmTransactionDestinationDto?.forEach((obj) => {
        transactionDestinationOptionsData.yAxis.data.push(obj.destination);
        transactionDestinationOptionsData.series.data.push(obj.count);
      });
      this.setState({
        transactionDestinationOptionsData,
        transactionDestinationOptionsLoading: false,
      });
    } catch (error) {
      console.log("transaction destination error", error);
      this.setState({ transactionDestinationOptionsLoading: false });
    }
  }

  async getSuspenseSummary(filter) {
    try {
      const { suspenseAccountOptionsData } = this.state;
      const response = await axiosInstance.get(
        `/charts/suspense-summary?channel=atm${filter}`
      );
      const { suspenseAccountsummary, header } = response.data;

      // converting object into array
      let data = Object.entries(
        suspenseAccountsummary?.suspenseAccountsummary
      )?.map((entry) => ({
        name: entry[0],
        value: entry[1],
      }));
      suspenseAccountOptionsData.title = header?.title;
      suspenseAccountOptionsData.data = data;
      this.setState({
        suspenseAccountOptionsData,
        suspenseAccountOptionsLoading: false,
      });
    } catch (error) {
      console.log("suspense summary error", error);
      this.setState({ suspenseAccountOptionsLoading: false });
    }
  }

  async getTransactionCategorization(filter) {
    try {
      const { transactionCategorizationOptionsData } = this.state;
      const response = await axiosInstance.get(
        `/charts/transaction-categorization?channel=atm${filter}`
      );

      const { transactionCategorizationCharts, header } = response?.data;

      let data = transactionCategorizationCharts?.map(
        ({ transactionType, count }) => ({
          name: transactionType,
          value: count,
        })
      );

      transactionCategorizationOptionsData.data = data;
      transactionCategorizationOptionsData.title = header?.title;

      this.setState({
        transactionCategorizationOptionsData,
        transactionCategorizationOptionsLoading: false,
      });
    } catch (err) {
      console.log("transaction categorization error", err);
      this.setState({ transactionCategorizationOptionsLoading: false });
    }
  }

  async getDeclineTransactionCategorization(filter) {
    try {
      const { declineTransactionCategorizationOptionsData } = this.state;
      const response = await axiosInstance.get(
        `/charts/declined-transaction-categorization?channel=atm${filter}`
      );

      const { declinedTransactionCategorizationCharts, header } =
        response?.data;

      let data = declinedTransactionCategorizationCharts?.map(
        ({ count, responseCode, responseDescription }) => ({
          name:
            responseDescription !== null ? responseDescription : responseCode,
          value: count,
        })
      );

      declineTransactionCategorizationOptionsData.data = data;
      declineTransactionCategorizationOptionsData.title = header?.title;

      this.setState({
        declineTransactionCategorizationOptionsData,
        declineTransactionCategorizationOptionsLoading: false,
      });
    } catch (err) {
      console.log("declined transaction categorization error", err);
      this.setState({ declineTransactionCategorizationOptionsLoading: false });
    }
  }

  onChartClick = {
    click: (param) => {
      buttonChecker("clk_atm_dash_chart") &&
        renderChartFunction(
          param,
          this.props,
          this.state?.filters?.business_date?.filter[0]
        );
    },
  };

  setRegionIds = (value) => {
    if (value?.length > 0) {
      this.filter.current = `&region_id=${value
        .map((node) => node.codeValue)
        .join(",")}`;
    } else {
      this.filter.current = "";
    }

    // ******** don't remove this code. commented for future use **********
    const regions = this.state.regions;
    regions.filterData = regionCheck(this.state.regions[0].filterData, value);
    this.setState({ regions });
  };

  setFilter = (filters) => {
    this.setState({ filters });
  };

  enableLoaders() {
    this.setState({
      cashSummaryOptionsLoading: true,
      cashUtilizationOptionsLoading: true,
      cashReplenishmentOptionsLoading: true,
      costOfCashOptionsLoading: true,
      transactionSummaryOptionsLoading: true,
      approvedTransactionsOptionsLoading: true,
      declinedTransactionsOptionsLoading: true,
      onUsTransactionsOptionsLoading: true,
      notOnUsTransactionsOptionsLoading: true,
      balancingSummaryOptionsLoading: true,
      previousDaySumOptionsLoading: true,
      topCashDispensedOptionsLoading: true,
      topCashDispensedCountOptionsLoading: true,
      topExceptionsOptionsLoading: true,
      leastCashDispensedOptionsLoading: true,
      leastCashDispensedCountOptionsLoading: true,
      leastExceptionsOptionsLoading: true,
      peakTimeOptionLoading: true,
      atmProfitabilityLoading: true,
      suspenseAccountOptionsLoading: true,
      transactionDestinationOptionsLoading: true,
    });
  }
  suspenseAccountEvent = (params) => {
    if (buttonChecker("clk_atm_dash_chart")) {
      const { filters } = this.state;
      const businessDate = filters?.business_date?.filter[0] || "";
      this.props.history.push(
        `/Accounts?typeId=6000&business_date=${businessDate}`
      );
    }
  };

  balancingSummaryEvent = (param) => {
    const { filters } = this.state;

    if (buttonChecker("clk_atm_dash_chart")) {
      const data = {};
      if (param.name.toLowerCase() === "balanced") {
        data.id = 130001;
      } else if (param.name.toLowerCase() === "shortage") {
        data.id = 130002;
      } else if (param.name.toLowerCase() === "surplus") {
        data.id = 130003;
      }
      data.amount = param.value;
      data.date =
        filters?.business_date?.filter.length > 0 &&
        filters?.business_date?.filter[0];
      data.channel = 160001;

      this.setState({ data }, () => {
        this.props.history.push({
          pathname: "/device-view",
          state: { data: this.state.data },
        });
      });
    }
  };

  matchingSummaryEvent = (param) => {
    if (buttonChecker("clk_atm_dash_chart")) {
      const { filters } = this.state;
      const data = {};
      if (param.name.toLowerCase() === "matched") {
        data.id = 140001;
      } else if (param.name.toLowerCase() === "unmatched") {
        data.id = 140002;
      } else if (param.name.toLowerCase() === "missing") {
        data.id = 140003;
      } else if (param.name.toLowerCase() === "error") {
        data.id = 140008;
      }

      data.amount = param.value;
      // data.date = param?.name;
      data.date =
        filters?.business_date?.filter.length > 0 &&
        filters?.business_date?.filter[0];
      data.channel = 160001;
      this.props.history.push({
        pathname: "/transaction-log",
        state: { data },
      });
    }
  };

  cashUtilizationEvent = (params) => {
    if (buttonChecker("clk_atm_dash_chart")) {
      const data = {};
      data.amount = params.value;
      data.date = params.name;
      this.props.history.push({
        pathname: "/device-view",
        state: { data },
      });
    }
  };

  cashReplenishmentEvent = (params) => {
    if (buttonChecker("clk_atm_dash_chart")) {
      const data = {};
      data.amount = params.value;
      data.date = params.name;
      this.props.history.push({
        pathname: "/device-view",
        state: { data },
      });
    }
  };
  costOfCashEvent = (params) => {
    if (buttonChecker("clk_atm_dash_chart")) {
      const data = {};
      data.amount = params.value;
      data.date = params.name;

      this.props.history.push({
        pathname: "/device-view",
        state: { data },
      });
    }
  };
  transactionDestinationEvent = (params) => {
    if (buttonChecker("clk_atm_dash_chart")) {
      const { filters } = this.state;
      const data = {};
      data.amount = params.value;
      data.bank_host = params.name;
      data.date =
        filters?.business_date?.filter.length > 0 &&
        filters?.business_date?.filter[0];
      data.channel = 160001;
      this.props.history.push({
        pathname: "/transaction-log",
        state: { data },
      });
    }
  };
  cashSummaryEvent = (params) => {
    if (buttonChecker("clk_atm_dash_chart")) {
      const { filters, regions } = this.state;
      const data = {};
      data.amount = params.value;
      data.date = params.name;
      data.atm_type = filters["atm_type"]?.filter;
      // data.date =
      //   filters?.business_date?.filter.length > 0 &&
      //   filters?.business_date?.filter[0];
      data.region_atm_dash = regions[0]?.filterData;
      this.props.history.push({
        pathname: "/device-view",
        state: { data },
      });
    }
  };
  render() {
    // const colorPalette = ["#00AF91", "#EF476F", "#FFD166", "#118AB2"];
    // const negativeColorPalette = ["#EF476F", "#52575D", "#845ec2", "#142D4C"];
    // const DASHBOARD_PALETTE = [
    //   "#0077A2",
    //   "#FF6F61",
    //   "#9A77B2",
    //   "#7F8C8D",
    //   "#FFC300",
    // ];
    // const YELLOW_PALETTE = [
    //   "#FFCA08",
    //   "#F8931D",
    //   "#CE8D3E",
    //   "#EC7016",
    //   "#9C6A6A",
    // ];
    // const PASTEL_PALETTE = [
    //   "#FA8072",
    //   "#FFD700",
    //   "#98FB98",
    //   "#AFEEEE",
    //   "#FFC0CB",
    //   "#FFFACD",
    //   "#98FF98",
    //   "#E0BBE4",
    //   "#FFD700",
    //   "#E6E6FA",
    //   "#FFDAB9",
    //   "#FF6F61",
    //   "#C8A2C8",
    //   "#87CEEB",
    // ];
    // const REVERSE_PASTEL = [
    //   "#87CEEB",
    //   "#C8A2C8",
    //   "#FF6F61",
    //   "#FFDAB9",
    //   "#E6E6FA",
    //   "#FFD700",
    //   "#E0BBE4",
    //   "#98FF98",
    //   "#FFFACD",
    //   "#FFC0CB",
    //   "#AFEEEE",
    //   "#98FB98",
    //   "#FFD700",
    //   "#FA8072"
    // ];
    const {
      showTransactionSummary,
      cashSummaryOptionsData,
      cashSummaryOptionsLoading,
      cashUtilizationOptionsData,
      cashUtilizationOptionsLoading,
      cashReplenishmentOptionsData,
      cashReplenishmentOptionsLoading,
      costOfCashOptionsData,
      costOfCashOptionsLoading,
      transactionSummaryOptionsData,
      transactionSummaryOptionsLoading,
      approvedTransactionsOptionsData,
      approvedTransactionsOptionsLoading,
      declinedTransactionsOptionsData,
      declinedTransactionsOptionsLoading,
      onUsTransactionsOptionsData,
      onUsTransactionsOptionsLoading,
      notOnUsTransactionsOptionsData,
      notOnUsTransactionsOptionsLoading,
      balancingSummaryOptionsData,
      balancingSummaryOptionsLoading,
      previousDaySumOptionsData,
      previousDaySumOptionsLoading,
      topCashDispensedOptionsData,
      topCashDispensedOptionsLoading,
      topCashDispensedCountOptionsData,
      topCashDispensedCountOptionsLoading,
      topExceptionsOptionsData,
      topExceptionsOptionsLoading,
      leastCashDispensedOptionsData,
      leastCashDispensedOptionsLoading,
      leastCashDispensedCountOptionsData,
      leastCashDispensedCountOptionsLoading,
      leastExceptionsOptionsData,
      leastExceptionsOptionsLoading,
      peakTimeOptionData,
      peakTimeOptionLoading,
      atmProfitabilityData,
      atmProfitabilityLoading,
      suspenseAccountOptionsData,
      suspenseAccountOptionsLoading,
      transactionDestinationOptionsData,
      transactionDestinationOptionsLoading,
      showCashSummary,
      transactionCategorizationOptionsData,
      transactionCategorizationOptionsLoading,
      declineTransactionCategorizationOptionsData,
      declineTransactionCategorizationOptionsLoading,
      filters,
      filterData,
      regions,
      atmKPITiles,
    } = this.state;

    var { theme } = this.props;

    return (
      <>
        {!this.state.gotResponse && (
          <div
            className="spinner-center"
            style={{ top: "0%", left: "0%", zIndex: 2000 }}
          >
            <LoaderComponent />
          </div>
        )}
        <div style={{ opacity: this.state.gotResponse ? "1" : "0.07" }}>
          <div className="row align-items-center d-lg-flex">
            {this.state.gotResponse && (
              <>
                <div
                  className="filter-item col-sm-6 col-lg-2 mt-2"
                  // style={{ minWidth: "210px" }}
                >
                  <FilterDropdown
                    title={"Region"}
                    type="checkbox"
                    data={regions[0]?.filterData}
                    onDropdownChange={(value) => this.setRegionIds(value)}
                  />
                </div>
                {filterData?.map(({ filterName, filterType, filterLabel }) => (
                  <div
                    className="filter-item col-sm-6 col-lg-3 mt-2"
                    style={{ maxWidth: "220px" }}
                    key={filterName}
                  >
                    <Filter
                      id={filterLabel ? filterLabel?.replace(/\s/g, "") : ""}
                      title={filterLabel}
                      type={filterType}
                      data={filters[filterName]?.data}
                      filter={filters[filterName]?.filter}
                      onChange={(e) =>
                        handleFilterChange(
                          e,
                          filterName,
                          filters,
                          this.setFilter
                        )
                      }
                    />
                  </div>
                ))}
                <div className="col-sm-6 col-lg-3 d-flex justify-content-start mt-2">
                  <ButtonBasic
                    icon={FilterIcon}
                    title="Filter"
                    onClick={this.handleFilter}
                  />
                </div>
              </>
            )}
          </div>
          <div className="row">
            <div className="col-12">
              <div className="mt-3">
                <SingleInfoCard>
                  <div className="tabs-wrapper d-flex mb-3">
                    <RoundedTab
                      id="cash-summary-tab"
                      title={"Cash Summary"}
                      state={false}
                      showTransactionSummary={this.state.showTransactionSummary}
                      toggleState={() =>
                        this.setState({
                          showTransactionSummary: false,
                          showCashSummary: true,
                        })
                      }
                    />
                    <RoundedTab
                      id="transaction-summary-tab"
                      title={"Transaction Summary"}
                      state={true}
                      showTransactionSummary={this.state.showTransactionSummary}
                      toggleState={() =>
                        this.setState({
                          showTransactionSummary: true,
                          showCashSummary: false,
                        })
                      }
                    />
                  </div>
                  {showCashSummary && (
                    <div className="cash-summary-wrapper">
                      <ReactECharts
                        className="cash-summary"
                        key={cashSummaryOptionsLoading}
                        onEvents={{
                          click: this.cashSummaryEvent,
                        }}
                        option={lineChart({
                          loader: cashSummaryOptionsLoading,
                          chartData: cashSummaryOptionsData,
                          label: ["Cash Position"],
                        })}
                        style={{
                          textTransform: "capitalize",
                          height: "300px",
                          width: "100%",
                          background: theme === "light" ? "#fcfcfc" : "#1d1d1d",
                          borderRadius: "17px",
                          color: "#ccc",
                        }}
                        loadingOption={{
                          text: "loading",
                          color: "#c23531",
                          textColor: "#000",
                          maskColor: `${
                            theme === "light"
                              ? "rgba(7, 6, 6, 0.1)"
                              : "rgba(7, 6, 6, 0.8)"
                          }`,
                          zlevel: 0,
                          fontSize: 12,
                          showSpinner: true,
                          spinnerRadius: 10,
                          lineWidth: 5,
                          fontWeight: "normal",
                          fontStyle: "normal",
                          fontFamily: "sans-serif",
                        }}
                        opts={{ renderer: "svg" }}
                        lazyUpdate={true}
                        showLoading={cashSummaryOptionsLoading}
                      />
                      <div className="graph-sm-container">
                        <div className="row">
                          <div className="col-sm-6 col-lg-4">
                            <div className="graph-sm-wrapper">
                              {!cashUtilizationOptionsLoading && (
                                <>
                                  <div id="cash-utilization-tile">
                                    <div className="graph-sm-title">
                                      {cashUtilizationOptionsData.title}
                                    </div>
                                    <div className="d-flex justify-content-between mr-3">
                                      <div className="mt-3 graph-label-sm">
                                        Percentage
                                      </div>
                                      <div className="mt-3 graph-label-sm">
                                        Count
                                      </div>
                                    </div>
                                    <div className="d-flex justify-content-between mr-3">
                                      <div
                                        className="graph-sm-number"
                                        style={{ fontSize: "20px" }}
                                      >
                                        {cashUtilizationOptionsData?.amountCount?.amount?.toFixed(
                                          2
                                        ) ?? 0}
                                        %
                                      </div>
                                      <div
                                        className="graph-sm-number"
                                        style={{ fontSize: "13px" }}
                                      >
                                        {cashUtilizationOptionsData.amountCount
                                          .count ?? 0}
                                      </div>
                                    </div>
                                  </div>
                                  <ReactECharts
                                    className="cash-utilization"
                                    onEvents={{
                                      click: this.cashUtilizationEvent,
                                    }}
                                    option={miniChart({
                                      chartData: cashUtilizationOptionsData,
                                      loader: cashUtilizationOptionsLoading,
                                      label: [""],
                                    })}
                                    style={{
                                      textTransform: "capitalize",
                                      height: "140px",
                                      width: "100%",
                                      background:
                                        theme === "light"
                                          ? "#fcfcfc"
                                          : "#1d1d1d",
                                    }}
                                    loadingOption={{
                                      text: "loading",
                                      color: "#c23531",
                                      textColor: "#000",
                                      maskColor: `${
                                        theme === "light"
                                          ? "rgba(7, 6, 6, 0.1)"
                                          : "rgba(7, 6, 6, 0.8)"
                                      }`,
                                      zlevel: 0,
                                      fontSize: 12,
                                      showSpinner: true,
                                      spinnerRadius: 10,
                                      lineWidth: 5,
                                      fontWeight: "normal",
                                      fontStyle: "normal",
                                      fontFamily: "sans-serif",
                                    }}
                                    opts={{ renderer: "svg" }}
                                    lazyUpdate={true}
                                    showLoading={cashUtilizationOptionsLoading}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-sm-6 col-lg-4">
                            <div className="graph-sm-wrapper">
                              {!cashReplenishmentOptionsLoading && (
                                <>
                                  <div id="cash-replenishment-tile">
                                    <div className="graph-sm-title">
                                      {cashReplenishmentOptionsData.title}
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <div className="mt-3 graph-label-sm">
                                        Amount
                                      </div>
                                      <div className="mt-3 graph-label-sm">
                                        Count
                                      </div>
                                    </div>
                                    <div className="d-flex justify-content-between ">
                                      <div
                                        className="graph-sm-number"
                                        style={{ fontSize: "20px" }}
                                      >
                                        <CurrencyFormat
                                          value={
                                            cashReplenishmentOptionsData
                                              ?.amountCount?.amount ?? 0
                                          }
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={`${this.props?.dateTime?.currency} `}
                                        />
                                      </div>
                                      <div
                                        className="graph-sm-number"
                                        style={{ fontSize: "13px" }}
                                      >
                                        {cashReplenishmentOptionsData
                                          ?.amountCount?.count ?? 0}
                                      </div>
                                    </div>
                                  </div>
                                  <ReactECharts
                                    className="cash-replenishment"
                                    onEvents={{
                                      click: this.cashReplenishmentEvent,
                                    }}
                                    option={miniChart({
                                      chartData: cashReplenishmentOptionsData,
                                      loader: cashReplenishmentOptionsLoading,
                                      label: [""],
                                    })}
                                    style={{
                                      textTransform: "capitalize",
                                      height: "140px",
                                      width: "100%",
                                      background:
                                        theme === "light"
                                          ? "#fcfcfc"
                                          : "#1d1d1d",
                                      margin: "0 auto",
                                    }}
                                    loadingOption={{
                                      text: "loading",
                                      color: "#c23531",
                                      textColor: "#000",
                                      maskColor: `${
                                        theme === "light"
                                          ? "rgba(7, 6, 6, 0.1)"
                                          : "rgba(7, 6, 6, 0.8)"
                                      }`,
                                      zlevel: 0,

                                      // Font size. Available since `v4.8.0`.
                                      fontSize: 12,
                                      // Show an animated "spinner" or not. Available since `v4.8.0`.
                                      showSpinner: true,
                                      // Radius of the "spinner". Available since `v4.8.0`.
                                      spinnerRadius: 10,
                                      // Line width of the "spinner". Available since `v4.8.0`.
                                      lineWidth: 5,
                                      // Font thick weight. Available since `v5.0.1`.
                                      fontWeight: "normal",
                                      // Font style. Available since `v5.0.1`.
                                      fontStyle: "normal",
                                      // Font family. Available since `v5.0.1`.
                                      fontFamily: "sans-serif",
                                    }}
                                    opts={{ renderer: "svg" }}
                                    lazyUpdate={true}
                                    showLoading={
                                      cashReplenishmentOptionsLoading
                                    }
                                  />
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-sm-6 col-lg-4">
                            <div className="graph-sm-wrapper">
                              {!costOfCashOptionsLoading && (
                                <>
                                  <div id="cost-of-cash-tile">
                                    <div className="graph-sm-title">
                                      {costOfCashOptionsData.title}
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <div className="mt-3 graph-label-sm">
                                        Amount
                                      </div>
                                      <div className="mt-3 graph-label-sm">
                                        Count
                                      </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <div
                                        className="graph-sm-number"
                                        style={{ fontSize: "20px" }}
                                      >
                                        <CurrencyFormat
                                          value={
                                            costOfCashOptionsData?.amountCount
                                              ?.amount ?? 0
                                          }
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={`${this.props?.dateTime?.currency} `}
                                        />
                                      </div>
                                      <div
                                        className="graph-sm-number"
                                        style={{ fontSize: "13px" }}
                                      >
                                        {costOfCashOptionsData?.amountCount
                                          ?.count ?? 0}
                                      </div>
                                    </div>
                                  </div>
                                  <ReactECharts
                                    className="cost-of-cash"
                                    onEvents={{
                                      click: this.costOfCashEvent,
                                    }}
                                    option={miniChart({
                                      chartData: costOfCashOptionsData,
                                      loader: costOfCashOptionsLoading,
                                      label: [""],
                                    })}
                                    style={{
                                      textTransform: "capitalize",
                                      height: "140px",
                                      width: "100%",
                                      background:
                                        theme === "light"
                                          ? "#fcfcfc"
                                          : "#1d1d1d",
                                    }}
                                    loadingOption={{
                                      text: "loading",
                                      color: "#c23531",
                                      textColor: "#000",
                                      maskColor: `${
                                        theme === "light"
                                          ? "rgba(7, 6, 6, 0.1)"
                                          : "rgba(7, 6, 6, 0.8)"
                                      }`,
                                      zlevel: 0,

                                      // Font size. Available since `v4.8.0`.
                                      fontSize: 12,
                                      // Show an animated "spinner" or not. Available since `v4.8.0`.
                                      showSpinner: true,
                                      // Radius of the "spinner". Available since `v4.8.0`.
                                      spinnerRadius: 10,
                                      // Line width of the "spinner". Available since `v4.8.0`.
                                      lineWidth: 5,
                                      // Font thick weight. Available since `v5.0.1`.
                                      fontWeight: "normal",
                                      // Font style. Available since `v5.0.1`.
                                      fontStyle: "normal",
                                      // Font family. Available since `v5.0.1`.
                                      fontFamily: "sans-serif",
                                    }}
                                    opts={{ renderer: "svg" }}
                                    lazyUpdate={true}
                                    showLoading={costOfCashOptionsLoading}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {showTransactionSummary && (
                    <div className="transaction-summary-wrapper">
                      <ReactECharts
                        className="transaction-summary"
                        onEvents={{
                          click: (params) => {
                            if (buttonChecker("clk_atm_dash_chart")) {
                              const data = {};
                              data.amount = params.value;
                              data.date = params?.name;
                              data.channel = 160001;
                              //if you want to check all matching filter items when land on TL
                              data.checkAllMatching = true;
                              this.props.history.push({
                                pathname: "/transaction-log",
                                state: { data },
                              });
                            }
                          },
                        }}
                        option={lineChart({
                          chartData: transactionSummaryOptionsData,
                          loader: transactionSummaryOptionsLoading,
                          label: ["Volume"],
                        })}
                        style={{
                          textTransform: "capitalize",
                          height: "300px",
                          width: "100%",
                          background: theme === "light" ? "#fcfcfc" : "#1d1d1d",
                          borderRadius: "17px",
                          color: "#ccc",
                        }}
                        opts={{ renderer: "svg" }}
                      />
                      <div className="graph-sm-container">
                        <div className="row">
                          <div className="col-sm-6 col-lg-3">
                            <div className="graph-sm-wrapper">
                              {!approvedTransactionsOptionsLoading && (
                                <>
                                  <div id="approved-transactions-tile">
                                    <div className="graph-sm-title">
                                      {approvedTransactionsOptionsData.title}
                                    </div>
                                    <div className="d-flex justify-content-between mr-3">
                                      <div className="mt-3 color-gray-bright graph-label-sm">
                                        Amount
                                      </div>
                                      <div className="mt-3 color-gray-bright graph-label-sm">
                                        Count
                                      </div>
                                    </div>

                                    <div className="d-flex justify-content-between mr-3">
                                      <div
                                        className="graph-sm-number"
                                        style={{ fontSize: "20px" }}
                                      >
                                        <CurrencyFormat
                                          value={
                                            approvedTransactionsOptionsData
                                              ?.approvedTotalAmountCount
                                              ?.amount ?? 0
                                          }
                                          displayType="text"
                                          thousandSeparator={true}
                                          prefix={
                                            this.props?.dateTime?.currency + " "
                                          }
                                        />
                                      </div>
                                      <div
                                        className="graph-sm-number"
                                        style={{ fontSize: "13px" }}
                                      >
                                        {approvedTransactionsOptionsData
                                          ?.approvedTotalAmountCount?.count ??
                                          0}
                                      </div>
                                    </div>
                                  </div>
                                  <ReactECharts
                                    className="approved-transaction-summary"
                                    onEvents={{
                                      click: (params) => {
                                        if (
                                          buttonChecker("clk_atm_dash_chart")
                                        ) {
                                          const data = {};
                                          data.amount = params.value;
                                          data.date = params?.name;
                                          data.checkAllMatching = true;
                                          // data.date =
                                          //   filters?.business_date?.filter
                                          //     .length > 0 &&
                                          //   filters?.business_date?.filter[0];
                                          data.channel = 160001;
                                          this.props.history.push({
                                            pathname: "/transaction-log",
                                            state: { data },
                                          });
                                        }
                                      },
                                    }}
                                    option={miniChart({
                                      chartData:
                                        approvedTransactionsOptionsData,
                                      loader:
                                        approvedTransactionsOptionsLoading,
                                      label: [""],
                                    })}
                                    style={{
                                      textTransform: "capitalize",
                                      height: "140px",
                                      width: "100%",
                                      background:
                                        theme === "light"
                                          ? "#fcfcfc"
                                          : "#1d1d1d",
                                    }}
                                    loadingOption={{
                                      text: "loading",
                                      color: "#c23531",
                                      textColor: "#000",
                                      maskColor: `${
                                        theme === "light"
                                          ? "rgba(7, 6, 6, 0.1)"
                                          : "rgba(7, 6, 6, 0.8)"
                                      }`,
                                      zlevel: 0,

                                      // Font size. Available since `v4.8.0`.
                                      fontSize: 12,
                                      // Show an animated "spinner" or not. Available since `v4.8.0`.
                                      showSpinner: true,
                                      // Radius of the "spinner". Available since `v4.8.0`.
                                      spinnerRadius: 10,
                                      // Line width of the "spinner". Available since `v4.8.0`.
                                      lineWidth: 5,
                                      // Font thick weight. Available since `v5.0.1`.
                                      fontWeight: "normal",
                                      // Font style. Available since `v5.0.1`.
                                      fontStyle: "normal",
                                      // Font family. Available since `v5.0.1`.
                                      fontFamily: "sans-serif",
                                    }}
                                    opts={{ renderer: "svg" }}
                                    lazyUpdate={true}
                                    showLoading={
                                      approvedTransactionsOptionsLoading
                                    }
                                  />
                                </>
                              )}
                            </div>
                          </div>

                          <div className="col-sm-6 col-lg-3">
                            <div className="graph-sm-wrapper">
                              {!declinedTransactionsOptionsLoading && (
                                <>
                                  <div id="declined-transactions-tile">
                                    <div className="graph-sm-title">
                                      {declinedTransactionsOptionsData.title}
                                    </div>
                                    <div className="d-flex justify-content-between mr-3">
                                      <div className="mt-3 color-gray-bright graph-label-sm">
                                        Amount
                                      </div>
                                      <div className="mt-3 color-gray-bright graph-label-sm">
                                        Count
                                      </div>
                                    </div>

                                    <div className="d-flex justify-content-between mr-3">
                                      <div
                                        className="graph-sm-number"
                                        style={{ fontSize: "20px" }}
                                      >
                                        <CurrencyFormat
                                          value={
                                            declinedTransactionsOptionsData
                                              ?.declinedAmountCount?.amount ?? 0
                                          }
                                          displayType="text"
                                          thousandSeparator={true}
                                          prefix={
                                            this.props?.dateTime?.currency + " "
                                          }
                                        />
                                      </div>
                                      <div
                                        className="graph-sm-number"
                                        style={{ fontSize: "13px" }}
                                      >
                                        {declinedTransactionsOptionsData
                                          .declinedAmountCount.count ?? 0}
                                      </div>
                                    </div>
                                  </div>
                                  <ReactECharts
                                    className="declined-transaction-summary"
                                    onEvents={{
                                      click: (params) => {
                                        if (
                                          buttonChecker("clk_atm_dash_chart")
                                        ) {
                                          const data = {};
                                          data.amount = params.value;
                                          data.date = params?.name;
                                          //if you want to check all matching filter items when land on TL
                                          data.checkAllMatching = true;
                                          data.channel = 160001;
                                          this.props.history.push({
                                            pathname: "/transaction-log",
                                            state: { data },
                                          });
                                        }
                                      },
                                    }}
                                    option={miniChart({
                                      chartData:
                                        declinedTransactionsOptionsData,
                                      loader:
                                        declinedTransactionsOptionsLoading,
                                      label: [""],
                                    })}
                                    style={{
                                      textTransform: "capitalize",
                                      height: "140px",
                                      width: "100%",
                                      background:
                                        theme === "light"
                                          ? "#fcfcfc"
                                          : "#1d1d1d",
                                    }}
                                    loadingOption={{
                                      text: "loading",
                                      color: "#c23531",
                                      textColor: "#000",
                                      maskColor: `${
                                        theme === "light"
                                          ? "rgba(7, 6, 6, 0.1)"
                                          : "rgba(7, 6, 6, 0.8)"
                                      }`,
                                      zlevel: 0,

                                      // Font size. Available since `v4.8.0`.
                                      fontSize: 12,
                                      // Show an animated "spinner" or not. Available since `v4.8.0`.
                                      showSpinner: true,
                                      // Radius of the "spinner". Available since `v4.8.0`.
                                      spinnerRadius: 10,
                                      // Line width of the "spinner". Available since `v4.8.0`.
                                      lineWidth: 5,
                                      // Font thick weight. Available since `v5.0.1`.
                                      fontWeight: "normal",
                                      // Font style. Available since `v5.0.1`.
                                      fontStyle: "normal",
                                      // Font family. Available since `v5.0.1`.
                                      fontFamily: "sans-serif",
                                    }}
                                    opts={{ renderer: "svg" }}
                                    lazyUpdate={true}
                                    showLoading={
                                      declinedTransactionsOptionsLoading
                                    }
                                  />
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-sm-6 col-lg-3">
                            <div className="graph-sm-wrapper">
                              {!onUsTransactionsOptionsLoading && (
                                <>
                                  <div id="on-us-transaction-tile">
                                    <div className="graph-sm-title">
                                      {onUsTransactionsOptionsData.title}
                                    </div>
                                    <div className="d-flex justify-content-between mr-3">
                                      <div className="mt-3 color-gray-bright graph-label-sm">
                                        Amount
                                      </div>
                                      <div className="mt-3 color-gray-bright graph-label-sm">
                                        Count
                                      </div>
                                    </div>
                                    <div className="d-flex justify-content-between mr-3">
                                      <div
                                        className="graph-sm-number"
                                        style={{ fontSize: "20px" }}
                                      >
                                        <CurrencyFormat
                                          value={
                                            onUsTransactionsOptionsData
                                              ?.amountCountDto.amount ?? 0
                                          }
                                          displayType="text"
                                          thousandSeparator={true}
                                          prefix={
                                            this.props?.dateTime?.currency + " "
                                          }
                                        />
                                      </div>
                                      <div
                                        className="graph-sm-number"
                                        style={{ fontSize: "13px" }}
                                      >
                                        {onUsTransactionsOptionsData
                                          .amountCountDto.count ?? 0}
                                      </div>
                                    </div>
                                  </div>
                                  <ReactECharts
                                    className="on-us-transaction-summary"
                                    onEvents={{
                                      click: (params) => {
                                        if (
                                          buttonChecker("clk_atm_dash_chart")
                                        ) {
                                          const data = {};
                                          data.amount = params.value;
                                          data.date = params?.name;
                                          //if you want to check all matching filter items when land on TL
                                          data.checkAllMatching = true;
                                          data.channel = 160001;
                                          this.props.history.push({
                                            pathname: "/transaction-log",
                                            state: { data },
                                          });
                                        }
                                      },
                                    }}
                                    option={miniChart({
                                      chartData: onUsTransactionsOptionsData,
                                      loader: onUsTransactionsOptionsLoading,
                                      label: [""],
                                    })}
                                    style={{
                                      textTransform: "capitalize",
                                      height: "140px",
                                      width: "100%",
                                      background:
                                        theme === "light"
                                          ? "#fcfcfc"
                                          : "#1d1d1d",
                                    }}
                                    loadingOption={{
                                      text: "loading",
                                      color: "#c23531",
                                      textColor: "#000",
                                      maskColor: `${
                                        theme === "light"
                                          ? "rgba(7, 6, 6, 0.1)"
                                          : "rgba(7, 6, 6, 0.8)"
                                      }`,
                                      zlevel: 0,

                                      // Font size. Available since `v4.8.0`.
                                      fontSize: 12,
                                      // Show an animated "spinner" or not. Available since `v4.8.0`.
                                      showSpinner: true,
                                      // Radius of the "spinner". Available since `v4.8.0`.
                                      spinnerRadius: 10,
                                      // Line width of the "spinner". Available since `v4.8.0`.
                                      lineWidth: 5,
                                      // Font thick weight. Available since `v5.0.1`.
                                      fontWeight: "normal",
                                      // Font style. Available since `v5.0.1`.
                                      fontStyle: "normal",
                                      // Font family. Available since `v5.0.1`.
                                      fontFamily: "sans-serif",
                                    }}
                                    opts={{ renderer: "svg" }}
                                    lazyUpdate={true}
                                    showLoading={onUsTransactionsOptionsLoading}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-sm-6 col-lg-3">
                            <div className="graph-sm-wrapper">
                              {!notOnUsTransactionsOptionsLoading && (
                                <>
                                  <div id="not-on-us-transaction-tile">
                                    <div className="graph-sm-title">
                                      {notOnUsTransactionsOptionsData.title}
                                    </div>
                                    <div className="d-flex justify-content-between mr-3">
                                      <div className="mt-3 color-gray-bright graph-label-sm">
                                        Amount
                                      </div>
                                      <div className="mt-3 color-gray-bright graph-label-sm">
                                        Count
                                      </div>
                                    </div>
                                    <div className="d-flex justify-content-between mr-3">
                                      <div
                                        className="graph-sm-number"
                                        style={{ fontSize: "20px" }}
                                      >
                                        <CurrencyFormat
                                          value={
                                            notOnUsTransactionsOptionsData
                                              ?.notOnUsAmountCount.amount ?? 0
                                          }
                                          displayType="text"
                                          thousandSeparator={true}
                                          prefix={
                                            this.props?.dateTime?.currency + " "
                                          }
                                        />
                                      </div>
                                      <div
                                        className="graph-sm-number"
                                        style={{ fontSize: "13px" }}
                                      >
                                        {notOnUsTransactionsOptionsData
                                          .notOnUsAmountCount.count ?? 0}
                                      </div>
                                    </div>
                                  </div>
                                  <ReactECharts
                                    className="not-on-us-transaction-summary"
                                    onEvents={{
                                      click: (params) => {
                                        if (
                                          buttonChecker("clk_atm_dash_chart")
                                        ) {
                                          const data = {};
                                          data.amount = params.value;
                                          data.date = params?.name;
                                          //if you want to check all matching filter items when land on TL
                                          data.checkAllMatching = true;
                                          data.channel = 160001;
                                          this.props.history.push({
                                            pathname: "/transaction-log",
                                            state: { data },
                                          });
                                        }
                                      },
                                    }}
                                    option={miniChart({
                                      chartData: notOnUsTransactionsOptionsData,
                                      loader: notOnUsTransactionsOptionsLoading,
                                      label: [""],
                                    })}
                                    style={{
                                      textTransform: "capitalize",
                                      height: "140px",
                                      width: "100%",
                                      background:
                                        theme === "light"
                                          ? "#fcfcfc"
                                          : "#1d1d1d",
                                    }}
                                    loadingOption={{
                                      text: "loading",
                                      color: "#c23531",
                                      textColor: "#000",
                                      maskColor: `${
                                        theme === "light"
                                          ? "rgba(7, 6, 6, 0.1)"
                                          : "rgba(7, 6, 6, 0.8)"
                                      }`,
                                      zlevel: 0,

                                      // Font size. Available since `v4.8.0`.
                                      fontSize: 12,
                                      // Show an animated "spinner" or not. Available since `v4.8.0`.
                                      showSpinner: true,
                                      // Radius of the "spinner". Available since `v4.8.0`.
                                      spinnerRadius: 10,
                                      // Line width of the "spinner". Available since `v4.8.0`.
                                      lineWidth: 5,
                                      // Font thick weight. Available since `v5.0.1`.
                                      fontWeight: "normal",
                                      // Font style. Available since `v5.0.1`.
                                      fontStyle: "normal",
                                      // Font family. Available since `v5.0.1`.
                                      fontFamily: "sans-serif",
                                    }}
                                    opts={{ renderer: "svg" }}
                                    lazyUpdate={true}
                                    showLoading={
                                      notOnUsTransactionsOptionsLoading
                                    }
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </SingleInfoCard>
              </div>
            </div>
          </div>
          <div className="row">
            {atmKPITiles &&
              atmKPITiles.chart &&
              atmKPITiles?.chart?.map((tile, i) => (
                <div className="col-sm-6 col-lg-3" key={i}>
                  <SingleInfoCard
                    id={tile.title}
                    title={tile.title}
                    value={tile.totalCount}
                    per={
                      tile.trendPercentage === null
                        ? "0 %"
                        : tile.trendPercentage + "%"
                    }
                    perDown={tile.trendType === "decrease" ? true : false}
                  />
                </div>
              ))}
          </div>
          <div className="row">
            <div className="col-sm-6 col-lg-4">
              <div className="mt-3">
                <ReactECharts
                  className="atm-balancing-summary"
                  onEvents={{ click: this.balancingSummaryEvent }}
                  option={pieChart({
                    theme: theme,
                    colorPalette: balancingSummaryOptionsData?.data?.map(
                      (obj) => obj.color
                    ),
                    loader: balancingSummaryOptionsLoading,
                    chartData: balancingSummaryOptionsData,
                    label: ["balanced", "shortage"],
                    radius: ["40%", "70%"],
                  })}
                  style={{
                    textTransform: "capitalize",
                    height: "279px",
                    width: "100%",
                    background: theme === "light" ? "#fcfcfc" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  opts={{ renderer: "svg" }}
                  lazyUpdate={true}
                  showLoading={balancingSummaryOptionsLoading}
                  loadingOption={{
                    text: "loading",
                    color: "#c23531",
                    textColor: "#000",
                    maskColor: `${
                      theme === "light"
                        ? "rgba(7, 6, 6, 0.1)"
                        : "rgba(7, 6, 6, 0.8)"
                    }`,
                    zlevel: 0,

                    // Font size. Available since `v4.8.0`.
                    fontSize: 12,
                    // Show an animated "spinner" or not. Available since `v4.8.0`.
                    showSpinner: true,
                    // Radius of the "spinner". Available since `v4.8.0`.
                    spinnerRadius: 10,
                    // Line width of the "spinner". Available since `v4.8.0`.
                    lineWidth: 5,
                    // Font thick weight. Available since `v5.0.1`.
                    fontWeight: "normal",
                    // Font style. Available since `v5.0.1`.
                    fontStyle: "normal",
                    // Font family. Available since `v5.0.1`.
                    fontFamily: "sans-serif",
                  }}
                />
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="mt-3">
                <ReactECharts
                  className="atm-matching-summary"
                  onEvents={{
                    click: this.matchingSummaryEvent,
                  }}
                  option={pieChart({
                    theme: theme,
                    colorPalette: colorPalette,
                    chartData: previousDaySumOptionsData,
                    loader: previousDaySumOptionsLoading,
                    label: ["matched", "unmatched", "missing", "error"],
                    radius: "70%",
                  })}
                  style={{
                    textTransform: "capitalize",
                    height: "279px",
                    width: "100%",
                    background: theme === "light" ? "#fcfcfc" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  showLoading={previousDaySumOptionsLoading}
                  loadingOption={{
                    text: "loading",
                    color: "#c23531",
                    textColor: "#000",
                    maskColor: `${
                      theme === "light"
                        ? "rgba(7, 6, 6, 0.1)"
                        : "rgba(7, 6, 6, 0.8)"
                    }`,
                    zlevel: 0,

                    // Font size. Available since `v4.8.0`.
                    fontSize: 12,
                    // Show an animated "spinner" or not. Available since `v4.8.0`.
                    showSpinner: true,
                    // Radius of the "spinner". Available since `v4.8.0`.
                    spinnerRadius: 10,
                    // Line width of the "spinner". Available since `v4.8.0`.
                    lineWidth: 5,
                    // Font thick weight. Available since `v5.0.1`.
                    fontWeight: "normal",
                    // Font style. Available since `v5.0.1`.
                    fontStyle: "normal",
                    // Font family. Available since `v5.0.1`.
                    fontFamily: "sans-serif",
                  }}
                  lazyUpdate={true}
                  opts={{ renderer: "svg" }}
                />
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="mt-3">
                <ReactECharts
                  className="atm-profitability"
                  onEvents={this.onChartClick}
                  option={lineChart({
                    loader: atmProfitabilityLoading,
                    chartData: atmProfitabilityData,
                    label: ["Profitability"],
                  })}
                  showLoading={atmProfitabilityLoading}
                  lazyUpdate={true}
                  style={{
                    textTransform: "capitalize",
                    height: "279px",
                    width: "100%",
                    background: theme === "light" ? "#fcfcfc" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  loadingOption={{
                    text: "loading",
                    color: "#c23531",
                    textColor: "#000",
                    maskColor: `${
                      theme === "light"
                        ? "rgba(7, 6, 6, 0.1)"
                        : "rgba(7, 6, 6, 0.8)"
                    }`,
                    zlevel: 0,

                    // Font size. Available since `v4.8.0`.
                    fontSize: 12,
                    // Show an animated "spinner" or not. Available since `v4.8.0`.
                    showSpinner: true,
                    // Radius of the "spinner". Available since `v4.8.0`.
                    spinnerRadius: 10,
                    // Line width of the "spinner". Available since `v4.8.0`.
                    lineWidth: 5,
                    // Font thick weight. Available since `v5.0.1`.
                    fontWeight: "normal",
                    // Font style. Available since `v5.0.1`.
                    fontStyle: "normal",
                    // Font family. Available since `v5.0.1`.
                    fontFamily: "sans-serif",
                  }}
                  opts={{ renderer: "svg" }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-lg-4">
              <div className="mt-3">
                <ReactECharts
                  className="top-cash-dispensed-count"
                  onEvents={this.onChartClick}
                  option={horizontalBarChart({
                    theme: theme,
                    color: colorPalette[4],
                    loader: topCashDispensedCountOptionsLoading,
                    chartData: topCashDispensedCountOptionsData,
                    label: [""],
                  })}
                  style={{
                    textTransform: "capitalize",
                    height: "279px",
                    width: "100%",
                    background: theme === "light" ? "#fcfcfc" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  lazyUpdate={true}
                  showLoading={topCashDispensedOptionsLoading}
                  loadingOption={{
                    text: "loading",
                    color: "#c23531",
                    textColor: "#000",
                    maskColor: `${
                      theme === "light"
                        ? "rgba(7, 6, 6, 0.1)"
                        : "rgba(7, 6, 6, 0.8)"
                    }`,
                    zlevel: 0,

                    // Font size. Available since `v4.8.0`.
                    fontSize: 12,
                    // Show an animated "spinner" or not. Available since `v4.8.0`.
                    showSpinner: true,
                    // Radius of the "spinner". Available since `v4.8.0`.
                    spinnerRadius: 10,
                    // Line width of the "spinner". Available since `v4.8.0`.
                    lineWidth: 5,
                    // Font thick weight. Available since `v5.0.1`.
                    fontWeight: "normal",
                    // Font style. Available since `v5.0.1`.
                    fontStyle: "normal",
                    // Font family. Available since `v5.0.1`.
                    fontFamily: "sans-serif",
                  }}
                  opts={{ renderer: "svg" }}
                />
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="mt-3">
                <ReactECharts
                  className="top-cash-dispensed"
                  onEvents={this.onChartClick}
                  option={horizontalBarChart({
                    theme: theme,
                    color: colorPalette[4],
                    loader: topCashDispensedOptionsLoading,
                    chartData: topCashDispensedOptionsData,
                    label: [""],
                  })}
                  style={{
                    textTransform: "capitalize",
                    height: "279px",
                    width: "100%",
                    background: theme === "light" ? "#fcfcfc" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  lazyUpdate={true}
                  showLoading={topCashDispensedOptionsLoading}
                  loadingOption={{
                    text: "loading",
                    color: "#c23531",
                    textColor: "#000",
                    maskColor: `${
                      theme === "light"
                        ? "rgba(7, 6, 6, 0.1)"
                        : "rgba(7, 6, 6, 0.8)"
                    }`,
                    zlevel: 0,

                    // Font size. Available since `v4.8.0`.
                    fontSize: 12,
                    // Show an animated "spinner" or not. Available since `v4.8.0`.
                    showSpinner: true,
                    // Radius of the "spinner". Available since `v4.8.0`.
                    spinnerRadius: 10,
                    // Line width of the "spinner". Available since `v4.8.0`.
                    lineWidth: 5,
                    // Font thick weight. Available since `v5.0.1`.
                    fontWeight: "normal",
                    // Font style. Available since `v5.0.1`.
                    fontStyle: "normal",
                    // Font family. Available since `v5.0.1`.
                    fontFamily: "sans-serif",
                  }}
                  opts={{ renderer: "svg" }}
                />
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="mt-3">
                <ReactECharts
                  className="top-exceptions"
                  onEvents={this.onChartClick}
                  option={horizontalBarChart({
                    theme: theme,
                    color: colorPalette[1],
                    loader: topExceptionsOptionsLoading,
                    chartData: topExceptionsOptionsData,
                    label: [""],
                  })}
                  style={{
                    textTransform: "capitalize",
                    height: "279px",
                    width: "100%",
                    background: theme === "light" ? "#fcfcfc" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  lazyUpdate={true}
                  showLoading={topExceptionsOptionsLoading}
                  loadingOption={{
                    text: "loading",
                    color: "#c23531",
                    textColor: "#000",
                    maskColor: `${
                      theme === "light"
                        ? "rgba(7, 6, 6, 0.1)"
                        : "rgba(7, 6, 6, 0.8)"
                    }`,
                    zlevel: 0,

                    // Font size. Available since `v4.8.0`.
                    fontSize: 12,
                    // Show an animated "spinner" or not. Available since `v4.8.0`.
                    showSpinner: true,
                    // Radius of the "spinner". Available since `v4.8.0`.
                    spinnerRadius: 10,
                    // Line width of the "spinner". Available since `v4.8.0`.
                    lineWidth: 5,
                    // Font thick weight. Available since `v5.0.1`.
                    fontWeight: "normal",
                    // Font style. Available since `v5.0.1`.
                    fontStyle: "normal",
                    // Font family. Available since `v5.0.1`.
                    fontFamily: "sans-serif",
                  }}
                  opts={{ renderer: "svg" }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-lg-4">
              <div className="mt-3">
                <ReactECharts
                  className="least-cash-dispensed"
                  onEvents={this.onChartClick}
                  option={horizontalBarChart({
                    theme: theme,
                    color: colorPalette[4],
                    loader: leastCashDispensedOptionsLoading,
                    chartData: leastCashDispensedOptionsData,
                    label: [""],
                  })}
                  style={{
                    textTransform: "capitalize",
                    height: "279px",
                    width: "100%",
                    background: theme === "light" ? "#fcfcfc" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  showLoading={leastCashDispensedOptionsLoading}
                  loadingOption={{
                    text: "loading",
                    color: "#c23531",
                    textColor: "#000",
                    maskColor: `${
                      theme === "light"
                        ? "rgba(7, 6, 6, 0.1)"
                        : "rgba(7, 6, 6, 0.8)"
                    }`,
                    zlevel: 0,

                    // Font size. Available since `v4.8.0`.
                    fontSize: 12,
                    // Show an animated "spinner" or not. Available since `v4.8.0`.
                    showSpinner: true,
                    // Radius of the "spinner". Available since `v4.8.0`.
                    spinnerRadius: 10,
                    // Line width of the "spinner". Available since `v4.8.0`.
                    lineWidth: 5,
                    // Font thick weight. Available since `v5.0.1`.
                    fontWeight: "normal",
                    // Font style. Available since `v5.0.1`.
                    fontStyle: "normal",
                    // Font family. Available since `v5.0.1`.
                    fontFamily: "sans-serif",
                  }}
                  lazyUpdate={true}
                  opts={{ renderer: "svg" }}
                />
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="mt-3">
                <ReactECharts
                  className="least-cash-dispensed-count"
                  onEvents={this.onChartClick}
                  option={horizontalBarChart({
                    theme: theme,
                    color: colorPalette[4],
                    loader: leastCashDispensedCountOptionsLoading,
                    chartData: leastCashDispensedCountOptionsData,
                    label: [""],
                  })}
                  style={{
                    textTransform: "capitalize",
                    height: "279px",
                    width: "100%",
                    background: theme === "light" ? "#fcfcfc" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  showLoading={leastCashDispensedCountOptionsLoading}
                  loadingOption={{
                    text: "loading",
                    color: "#c23531",
                    textColor: "#000",
                    maskColor: `${
                      theme === "light"
                        ? "rgba(7, 6, 6, 0.1)"
                        : "rgba(7, 6, 6, 0.8)"
                    }`,
                    zlevel: 0,

                    // Font size. Available since `v4.8.0`.
                    fontSize: 12,
                    // Show an animated "spinner" or not. Available since `v4.8.0`.
                    showSpinner: true,
                    // Radius of the "spinner". Available since `v4.8.0`.
                    spinnerRadius: 10,
                    // Line width of the "spinner". Available since `v4.8.0`.
                    lineWidth: 5,
                    // Font thick weight. Available since `v5.0.1`.
                    fontWeight: "normal",
                    // Font style. Available since `v5.0.1`.
                    fontStyle: "normal",
                    // Font family. Available since `v5.0.1`.
                    fontFamily: "sans-serif",
                  }}
                  lazyUpdate={true}
                  opts={{ renderer: "svg" }}
                />
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="mt-3">
                <ReactECharts
                  className="least-exceptions"
                  onEvents={this.onChartClick}
                  option={horizontalBarChart({
                    theme: theme,
                    color: colorPalette[1],
                    loader: leastExceptionsOptionsLoading,
                    chartData: leastExceptionsOptionsData,
                    label: [""],
                  })}
                  style={{
                    textTransform: "capitalize",
                    height: "279px",
                    width: "100%",
                    background: theme === "light" ? "#fcfcfc" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  showLoading={leastExceptionsOptionsLoading}
                  loadingOption={{
                    text: "loading",
                    color: "#c23531",
                    textColor: "#000",
                    maskColor: `${
                      theme === "light"
                        ? "rgba(7, 6, 6, 0.1)"
                        : "rgba(7, 6, 6, 0.8)"
                    }`,
                    zlevel: 0,

                    // Font size. Available since `v4.8.0`.
                    fontSize: 12,
                    // Show an animated "spinner" or not. Available since `v4.8.0`.
                    showSpinner: true,
                    // Radius of the "spinner". Available since `v4.8.0`.
                    spinnerRadius: 10,
                    // Line width of the "spinner". Available since `v4.8.0`.
                    lineWidth: 5,
                    // Font thick weight. Available since `v5.0.1`.
                    fontWeight: "normal",
                    // Font style. Available since `v5.0.1`.
                    fontStyle: "normal",
                    // Font family. Available since `v5.0.1`.
                    fontFamily: "sans-serif",
                  }}
                  lazyUpdate={true}
                  opts={{ renderer: "svg" }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-lg-4">
              <div className="mt-3">
                <ReactECharts
                  className="decline-transaction-categorization"
                  onEvents={this.onChartClick}
                  option={pieChart({
                    theme: theme,
                    colorPalette: PASTEL_PALETTE,
                    chartData: declineTransactionCategorizationOptionsData,
                    loader: declineTransactionCategorizationOptionsLoading,
                    label: [""],
                    radius: "70%",
                  })}
                  style={{
                    textTransform: "capitalize",
                    height: "279px",
                    width: "100%",
                    background: theme === "light" ? "#fff" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  // loadingOption={this.getLoader}
                  loadingOption={{
                    text: "loading",
                    color: "#c23531",
                    textColor: "#000",
                    maskColor: `${
                      theme === "light"
                        ? "rgba(7, 6, 6, 0.1)"
                        : "rgba(7, 6, 6, 0.8)"
                    }`,
                    zlevel: 0,

                    // Font size. Available since `v4.8.0`.
                    fontSize: 12,
                    // Show an animated "spinner" or not. Available since `v4.8.0`.
                    showSpinner: true,
                    // Radius of the "spinner". Available since `v4.8.0`.
                    spinnerRadius: 10,
                    // Line width of the "spinner". Available since `v4.8.0`.
                    lineWidth: 5,
                    // Font thick weight. Available since `v5.0.1`.
                    fontWeight: "normal",
                    // Font style. Available since `v5.0.1`.
                    fontStyle: "normal",
                    // Font family. Available since `v5.0.1`.
                    fontFamily: "sans-serif",
                  }}
                  lazyUpdate={true}
                  showLoading={declineTransactionCategorizationOptionsLoading}
                  opts={{ renderer: "svg" }}
                />
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="mt-3">
                <ReactECharts
                  className="suspense-account"
                  onEvents={{ click: this.suspenseAccountEvent }}
                  option={pieChart({
                    theme: theme,
                    colorPalette: YELLOW_PALETTE,
                    chartData: suspenseAccountOptionsData,
                    loader: suspenseAccountOptionsLoading,
                    label: [""],
                    radius: "70%",
                  })}
                  style={{
                    textTransform: "capitalize",
                    height: "279px",
                    width: "100%",
                    background: theme === "light" ? "#fcfcfc" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  showLoading={suspenseAccountOptionsLoading}
                  loadingOption={{
                    text: "loading",
                    color: "#c23531",
                    textColor: "#000",
                    maskColor: `${
                      theme === "light"
                        ? "rgba(7, 6, 6, 0.1)"
                        : "rgba(7, 6, 6, 0.8)"
                    }`,
                    zlevel: 0,

                    // Font size. Available since `v4.8.0`.
                    fontSize: 12,
                    // Show an animated "spinner" or not. Available since `v4.8.0`.
                    showSpinner: true,
                    // Radius of the "spinner". Available since `v4.8.0`.
                    spinnerRadius: 10,
                    // Line width of the "spinner". Available since `v4.8.0`.
                    lineWidth: 5,
                    // Font thick weight. Available since `v5.0.1`.
                    fontWeight: "normal",
                    // Font style. Available since `v5.0.1`.
                    fontStyle: "normal",
                    // Font family. Available since `v5.0.1`.
                    fontFamily: "sans-serif",
                  }}
                  lazyUpdate={true}
                  opts={{ renderer: "svg" }}
                />
              </div>
            </div>
            <div className="col-sm-12 col-lg-4">
              <div className="mt-3">
                <ReactECharts
                  className="transaction-categorization"
                  onEvents={this.onChartClick}
                  option={pieChart({
                    theme: theme,
                    colorPalette: REVERSE_PASTEL,
                    chartData: transactionCategorizationOptionsData,
                    loader: transactionCategorizationOptionsLoading,
                    label: [""],
                    radius: "70%",
                  })}
                  style={{
                    textTransform: "capitalize",
                    height: "279px",
                    width: "100%",
                    background: theme === "light" ? "#fff" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  // loadingOption={this.getLoader}
                  loadingOption={{
                    text: "loading",
                    color: "#c23531",
                    textColor: "#000",
                    maskColor: `${
                      theme === "light"
                        ? "rgba(7, 6, 6, 0.1)"
                        : "rgba(7, 6, 6, 0.8)"
                    }`,
                    zlevel: 0,

                    // Font size. Available since `v4.8.0`.
                    fontSize: 12,
                    // Show an animated "spinner" or not. Available since `v4.8.0`.
                    showSpinner: true,
                    // Radius of the "spinner". Available since `v4.8.0`.
                    spinnerRadius: 10,
                    // Line width of the "spinner". Available since `v4.8.0`.
                    lineWidth: 5,
                    // Font thick weight. Available since `v5.0.1`.
                    fontWeight: "normal",
                    // Font style. Available since `v5.0.1`.
                    fontStyle: "normal",
                    // Font family. Available since `v5.0.1`.
                    fontFamily: "sans-serif",
                  }}
                  lazyUpdate={true}
                  showLoading={transactionCategorizationOptionsLoading}
                  opts={{ renderer: "svg" }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-lg-4">
              <div className="mt-3">
                <ReactECharts
                  className="transaction-destination"
                  onEvents={{
                    click: this.transactionDestinationEvent,
                  }}
                  option={horizontalBarChart({
                    theme: theme,
                    color: colorPalette[4],
                    chartData: transactionDestinationOptionsData,
                    loader: transactionDestinationOptionsLoading,
                    label: [""],
                  })}
                  style={{
                    textTransform: "capitalize",
                    height: "279px",
                    width: "100%",
                    background: theme === "light" ? "#fcfcfc" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  opts={{ renderer: "svg" }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="mt-3">
                <ReactECharts
                  className="peak-time"
                  onEvents={this.onChartClick}
                  option={barWithLineChart({
                    theme: theme,
                    loader: peakTimeOptionLoading,
                    chartData: peakTimeOptionData,
                    label: [""],
                  })}
                  style={{
                    textTransform: "capitalize",
                    height: "279px",
                    width: "100%",
                    background: theme === "light" ? "#fcfcfc" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  showLoading={peakTimeOptionLoading}
                  loadingOption={{
                    text: "loading",
                    color: "#c23531",
                    textColor: "#000",
                    maskColor: `${
                      theme === "light"
                        ? "rgba(7, 6, 6, 0.1)"
                        : "rgba(7, 6, 6, 0.8)"
                    }`,
                    zlevel: 0,

                    // Font size. Available since `v4.8.0`.
                    fontSize: 12,
                    // Show an animated "spinner" or not. Available since `v4.8.0`.
                    showSpinner: true,
                    // Radius of the "spinner". Available since `v4.8.0`.
                    spinnerRadius: 10,
                    // Line width of the "spinner". Available since `v4.8.0`.
                    lineWidth: 5,
                    // Font thick weight. Available since `v5.0.1`.
                    fontWeight: "normal",
                    // Font style. Available since `v5.0.1`.
                    fontStyle: "normal",
                    // Font family. Available since `v5.0.1`.
                    fontFamily: "sans-serif",
                  }}
                  lazyUpdate={true}
                  opts={{ renderer: "svg" }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withDateTime(AtmDrillDown);
