import React, { useState, useEffect, useCallback } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import {
  FormCheck,
  OverlayTrigger,
  Tab,
  TabPane,
  Tabs,
  Tooltip,
} from "react-bootstrap";
import axiosInstance from "../../utils/axiosInstance";
import Pagination from "../../components/pagination/pagination";
import LoaderComponent from "../../components/Spinner/LoaderComponent";
import ButtonBasic from "../../components/Buttons/ButtonBasic";
import FilterIcon from "../../assets/images/filter.png";
import Filter from "../../components/FilterDropdown/GenericFilter";
import { useDateTime } from "../../components/Helper/DateTime";
import { NostroDynamicAccountsMenu } from "./nostro-accounts-menu";
import BadgeRounded from "../../components/BadgeRounded/BadgeRounded";
import moment from "moment";

import {
  mapFilters,
  handleFilterChange,
  filtersParams,
  handleNewFilter,
} from "../../utils/filtersHelper";
import NostroModal from "./NostroModal";
import NostroViewModal from "./NostroViewModal";
import { isEmpty } from "lodash";
import LocateTransactionModal from "./LocateTransactionModal";
import { DateFormat } from "../../utils/formatDateTime";

const NostroDynamicAccounts = (props) => {
  const location = useLocation();
  const { presistState, date } = useDateTime();
  const DynamicSubMenuItem = location?.state?.subItem;
  const DynamicMenuSingleItem = location?.state?.item;

  const { setRefresh } = useDateTime();
  const [summaryData, setSummaryData] = useState([]);
  const [nostroData, setNostroData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [reconciledData, setReconciledData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [reconPage, setReconPage] = useState(1);
  const [reconPageSize, setReconPageSize] = useState(15);
  const [reconTotalPages, setReconTotalPages] = useState(0);
  const [reconTotalElements, setReconTotalElements] = useState(0);
  const [sort, setSort] = useState({ sort_by: "", sort_order: "" });
  const [filter, setFilter] = useState({});
  const [tempFilter, setTempFilter] = useState({});
  const [filterData, setFilterData] = useState([]);
  const [showFilters, setShowFilters] = useState([]);
  const [tabskey, setTabsKey] = useState("reconciler-tab");
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [showLocateModal, setShowLocateModal] = useState(false);
  const [indexes, setIndexes] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const [tempQuery, setTempQuery] = useState("");
  const [query, setQuery] = useState("");
  const params = queryString.parse(props.location.search);
  const onSortTable = (type, newState) => {
    setPage(1);
    setSort({ sort_by: newState.sortField, sort_order: newState.sortOrder });
  };

  const fetchSummary = useCallback(
    (filter) => {
      if (selectedAccount?.id !== undefined && selectedAccount?.id !== null) {
        setLoading(true);
        setSummaryData([]);
        let allFilters = filtersParams(filter, null, true);
        let url = `/accounts/summary?${allFilters}&accountIds=${selectedAccount?.id}`;
        axiosInstance
          .get(url)
          .then((res) => {
            if (res.message.status !== "200") {
              setSummaryData([]);
              setLoading(false);
            } else {
              setSummaryData(res?.data || []);
              setLoading(false);
            }
          })
          .catch((err) => {
            setSummaryData([]);
            setLoading(false);
          });
      }
    },
    // eslint-disable-next-line
    [page, pageSize, sort, selectedAccount]
  );

  const fetchDynamicTabData = useCallback(
    (filter, DynamicSubMenuItem) => {
      setNostroData([]);
      if (selectedAccount?.id !== undefined && selectedAccount?.id !== null) {
        setLoading(true);
        let allFilters = filtersParams(filter);
        const order = `&sort_by=${sort.sort_by}&sort_order=${sort.sort_order}`;
        const dynamicTabs =
          tabskey === DynamicSubMenuItem?.pairs[0]?.dsA
            ? `&datasourceA_id=${DynamicSubMenuItem?.pairs[0]?.dsAId}`
            : `&datasourceB_id=${DynamicSubMenuItem?.pairs[0]?.dsBId}`;
        let url = `/recon/fetch-pair-data/${
          DynamicSubMenuItem?.reconId
        }?recon_account_id=${selectedAccount?.id}${dynamicTabs}${
          query ? `&search=${query}` : ""
        }&page=${page - 1}&size=${pageSize}${allFilters}${
          sort.sort_by && sort.sort_order ? order : ""
        }`;

        axiosInstance
          .get(url)
          .then((res) => {
            if (res.message.status !== "200") {
              setNostroData([]);
              setColumns([]);
              setTotalElements(0);
              setTotalPages(0);
              setLoading(false);
            } else {
              setNostroData(res?.data || []);
              const dynamicColumns = res?.data?.headers?.map((header) => ({
                dataField: header,
                text: header,
                style: header === "Description" && {
                  whiteSpace: "normal", // or 'pre-wrap'
                  wordWrap: "break-word",
                  minWidth: "342px",
                },
                align:
                  header === "Balance" ||
                  header === "Debit" ||
                  header === "Credit" ||
                  header === "Amount"
                    ? "right"
                    : "left",
                formatter:
                  header === "Balance" ||
                  header === "Debit" ||
                  header === "Credit" ||
                  header === "Amount"
                    ? (cell) => (cell ? formatNumber(cell) : "-")
                    : header === "Posting Date"
                    ? (cell) => (cell ? DateFormat(date, cell) : "-")
                    : null,
                headerAlign:
                  header === "Balance" ||
                  header === "Debit" ||
                  header === "Credit" ||
                  header === "Amount"
                    ? "right"
                    : null,
              }));
              setColumns(dynamicColumns);
              setPage(res?.page?.number + 1 || 1);
              setTotalPages(res?.page?.totalPages || 0);
              setTotalElements(res?.page?.totalElements || 0);
              setLoading(false);
            }
          })
          .catch((err) => {
            setNostroData([]);
            setTotalElements(0);
            setTotalPages(0);
            setLoading(false);
            setColumns([]);
          });
      }
    },
    // eslint-disable-next-line
    [page, pageSize, sort, selectedAccount, tabskey, filter, query]
  );

  const fetchReconcilerData = useCallback(
    (filter, DynamicSubMenuItem) => {
      setReconciledData([]);
      if (selectedAccount?.id !== undefined && selectedAccount?.id !== null) {
        setLoading(true);
        let allFilters = filtersParams(filter);
        const order = `&sort_by=${sort.sort_by}&sort_order=${sort.sort_order}`;
        let url = `/recon${query ? "/reconciler-search/" : "/reconciler/"}${
          DynamicSubMenuItem?.reconId
        }?recon_account_id=${selectedAccount?.id}&page=${
          reconPage - 1
        }&size=${reconPageSize}${allFilters}${
          sort.sort_by && sort.sort_order ? order : ""
        }${query ? `&search=${query}` : ""}`;

        axiosInstance
          .get(url)
          .then((res) => {
            if (res.message.status !== "200") {
              setReconciledData([]);
              setReconTotalElements(0);
              setReconTotalPages(0);
              setLoading(false);
            } else {
              setReconciledData(res?.data || []);
              setReconPage(res?.page?.number + 1 || 1);
              setReconTotalPages(res?.page?.totalPages || 0);
              setReconTotalElements(res?.page?.totalElements || 0);
              setLoading(false);
            }
          })
          .catch((err) => {
            setReconciledData([]);
            setReconTotalElements(0);
            setReconTotalPages(0);
            setLoading(false);
          });
      }
    },
    // eslint-disable-next-line
    [reconPage, reconPageSize, sort, selectedAccount, query]
  );

  const getFilters = useCallback(() => {
    setLoading(true);
    setFilterData([]);
    setShowFilters([]);
    setTempFilter({});
    setFilter({});
    axiosInstance
      .get(`hierarchical/filters?screen_id=${DynamicMenuSingleItem?.groupName}`)
      .then((res) => {
        if (res.message.status !== "200") {
          setLoading(false);
        } else {
          const filteredFilters = res.data.filters.filter(
            (x) => x.showOnScreen
          );
          const showFiltersData = res.data.filters.filter(
            (x) => !x.showOnScreen
          );
          setShowFilters(showFiltersData);
          setFilterData(filteredFilters);
          const obj = mapFilters(filteredFilters);
          setFilter(obj);
          setTempFilter(obj);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [DynamicMenuSingleItem?.groupName]);
  const formatNumber = (number) => {
    if (isNaN(Number(number))) {
      return number;
    } else {
      number = Number(number);
      return number === 0
        ? ""
        : number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            useGrouping: true,
          });
    }
  };

  const headers = [
    {
      label: "Date",
      key: "date",
    },
    {
      label: "Details",
      key: "details",
    },
    {
      label: "Reference",
      key: "entryRef",
    },
    {
      label: "Debit",
      key: "debit",
    },
    {
      label: "Credit",
      key: "credit",
    },
    {
      label: "Balance",
      key: "balance",
    },
  ];

  const converToCSVHeader = (headers) =>
    headers && headers.length
      ? headers.map((header) => ({ label: header, key: header }))
      : [];

  const handleAccountSelection = (account) => {
    if (selectedAccount?.id !== account?.id) {
      setSelectedAccount(account);
    }
  };

  useEffect(() => {
    getFilters();
  }, [getFilters]);

  useEffect(() => {
    if (
      !isEmpty(filter) &&
      selectedAccount &&
      DynamicMenuSingleItem?.showSummaryHeader
    ) {
      fetchSummary(filter);
    }
  }, [filter, selectedAccount, fetchSummary]);

  useEffect(async () => {
    if (
      !isEmpty(filter) &&
      (selectedAccount !== undefined || selectedAccount !== null)
    ) {
      if (tabskey === "reconciler-tab") {
        await fetchReconcilerData(filter, DynamicSubMenuItem);
      }
      if (
        (tabskey === DynamicSubMenuItem?.pairs[0]?.dsA ||
          tabskey === DynamicSubMenuItem?.pairs[0]?.dsB) &&
        DynamicSubMenuItem?.pairs?.length > 0
      ) {
        await fetchDynamicTabData(filter, DynamicSubMenuItem);
      }
    }
  }, [
    filter,
    selectedAccount,
    fetchSummary,
    fetchDynamicTabData,
    fetchReconcilerData,
    tabskey,
  ]);

  useEffect(() => {
    setRefresh(() => () => {
      fetchSummary(filter);
      fetchDynamicTabData(filter, DynamicSubMenuItem);
      fetchReconcilerData(filter, DynamicSubMenuItem);
    });
    return () => {
      setRefresh(() => () => {});
    };
    // eslint-disable-next-line
  }, [
    fetchSummary,
    fetchDynamicTabData,
    filter,
    fetchReconcilerData,
    DynamicSubMenuItem,
  ]);

  useEffect(() => {
    setSummaryData([]);
    setNostroData([]);
    setReconciledData([]);
  }, [DynamicSubMenuItem?.reconId]);

  // Find the maximum length of dsA and dsB arrays
  let maxDsALength = 0;
  let maxDsBLength = 0;
  reconciledData?.nostroReconciler?.forEach((item) => {
    maxDsALength = Math.max(maxDsALength, item.dsA.length);
    maxDsBLength = Math.max(maxDsBLength, item.dsB.length);
  });

  useEffect(() => {
    setIndexes([]);
    //Extract Index of specific field names
    if (tabskey === "reconciler-tab" && reconciledData) {
      reconciledData?.headers?.forEach((header, index) => {
        if (
          header === "Balance" ||
          header === "Credit" ||
          header === "Debit" ||
          header === "Amount"
        ) {
          setIndexes((prevIndexes) => [...prevIndexes, index]);
        }
      });
    }
  }, [reconciledData?.headers, nostroData]);

  useEffect(() => {
    setShowModal(presistState?.adjustmentFlow);
    setShowLocateModal(presistState?.goBackToLocate);
  }, [presistState?.adjustmentFlow, presistState?.goBackToLocate]);

  // CSS Property Doesn't Support in Firefox
  // So this code for making draggable false for firefox
  useEffect(() => {
    const elements = document.getElementsByClassName("draggable-false");
    for (let i = 0; i < elements.length; i++) {
      elements[i].draggable = false;
    }
  }, []);

  const reconcileStatus = (status) => (
    <BadgeRounded
      color={
        status === "MATCHED"
          ? "success"
          : status === "MISSING"
          ? "warning"
          : status === "SUGGESTED"
          ? "info"
          : "danger"
      }
      title={status}
    />
  );

  return (
    <>
      {loading && (
        <div
          className="spinner-center"
          style={{ top: "0%", left: "0%", zIndex: 2000 }}
        >
          <LoaderComponent />
        </div>
      )}

      <div style={{ opacity: !loading ? "1" : "0.07" }}>
        <div
          className="row"
          style={{ height: collapsed ? "" : window.innerHeight }}
        >
          <div className={`${collapsed ? "" : "col-3"}`}>
            <NostroDynamicAccountsMenu
              collapsed={collapsed}
              setCollapsed={setCollapsed}
              params={params}
              onAccountSelect={handleAccountSelection}
            />
          </div>
          <div
            className={`${collapsed ? "col-md-12" : "col-md-9"}`}
            style={{ marginTop: "10px" }}
          >
            {filterData?.length > 0 && (
              <div className="row align-items-center d-lg-flex">
                {filterData?.map(
                  ({
                    filterName,
                    filterType,
                    filterLabel,
                    showOnScreen,
                    filterId,
                  }) => (
                    <div
                      className="filter-item mt-2 ml-3 d-flex"
                      // style={{ minWidth: "210px" }}
                      key={filterId}
                    >
                      <Filter
                        title={filterLabel}
                        filterId={filterId}
                        type={filterType}
                        data={filter[filterName]?.data}
                        filter={filter[filterName]?.filter}
                        removable={!showOnScreen}
                        onChange={(e) =>
                          handleFilterChange(
                            e,
                            filterName,
                            tempFilter,
                            setTempFilter
                          )
                        }
                        onRemove={(e) =>
                          handleNewFilter(
                            e,
                            filterData,
                            showFilters,
                            tempFilter,
                            setTempFilter,
                            setFilterData
                          )
                        }
                        isSearchable
                      />
                    </div>
                  )
                )}
                <div className="col d-flex justify-content-start mt-2">
                  <ButtonBasic
                    wrapperClass={"d-flex justify-content-end mr-0"}
                    icon={FilterIcon}
                    title="Filter"
                    onClick={() => {
                      setFilter({ ...tempFilter });
                    }}
                  />
                </div>
              </div>
            )}

            <div className="row mt-2">
              <div className="col-12">
                <div className="table-responsive fancy-scroll">
                  {DynamicMenuSingleItem?.showSummaryHeader && (
                    <table className="table">
                      <thead>
                        {summaryData?.length > 0 && (
                          <tr>
                            <th className="background-primary"></th>
                            {Object.keys(summaryData[0]).map((key) => (
                              <th key={key}>
                                {key
                                  .replace(/([a-z])([A-Z])/g, "$1 $2")
                                  .replace(/\b\w/g, (char) =>
                                    char.toUpperCase()
                                  )}
                              </th>
                            ))}
                          </tr>
                        )}
                      </thead>
                      <tbody>
                        {summaryData?.map((item) => (
                          <tr
                            key={item.id}
                            className={
                              item % 2 === 0 ? "white-row" : "gray-row"
                            }
                          >
                            <td
                              style={{
                                fontSize: "16px",
                                color: "#108ab2",
                                fontWeight: "bold",
                                textAlign: "left",
                              }}
                              className="background-primary"
                            >
                              {item.accountType}
                            </td>

                            {Object.keys(item).map((key) => (
                              <td
                                className={`${
                                  key === "openingBalance" ||
                                  key === "closingBalance" ||
                                  (key === "adjustedBalance" && item[key])
                                    ? "text-center"
                                    : "text-center"
                                }`}
                                key={key}
                              >
                                {key === "openingBalance" ||
                                key === "closingBalance" ||
                                (key === "adjustedBalance" && item[key])
                                  ? item[key] == 0
                                    ? "-"
                                    : formatNumber(item[key])
                                  : key === "closingBalanceDate" ||
                                    key === "openingBalanceDate"
                                  ? DateFormat(date, item[key])
                                  : item[key] ?? "-"}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-12">
                <Tabs
                  activeKey={tabskey}
                  onSelect={(key) => setTabsKey(key)}
                  defaultActiveKey={"reconciler-tab"}
                  id="nostro-tab"
                  className="mb-3"
                >
                  <Tab
                    className="mb-sm-2 mb-md-0"
                    eventKey={DynamicSubMenuItem?.pairs[0]?.dsA}
                    title={DynamicSubMenuItem?.pairs[0]?.dsA}
                    tabClassName="custom-tab"
                  >
                    {loading ? (
                      <p>Loading...</p>
                    ) : columns?.length > 0 &&
                      nostroData?.transactions?.length > 0 ? (
                      <BootstrapTable
                        bordered={false}
                        classes="rs-table table-layout-auto mb-0 table-custom-style custom-table-background"
                        wrapperClasses="overflow-y-auto mb-2 fancy-scroll"
                        keyField="id"
                        bootstrap4={true}
                        remote={{ pagination: true }}
                        data={nostroData?.transactions ?? []}
                        columns={columns ?? []}
                      />
                    ) : (
                      <p>No data found or columns are empty.</p>
                    )}

                    <Pagination
                      setPage={setPage}
                      page={page}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      totalPages={totalPages}
                      showCSV
                      totalElements={totalElements}
                      data={nostroData?.transactions ?? []}
                      CSVHeaders={converToCSVHeader(nostroData?.headers) ?? []}
                      csvName="Account Data"
                    />
                  </Tab>

                  <Tab
                    className="mb-sm-2 mb-md-0"
                    eventKey={DynamicSubMenuItem?.pairs[0]?.dsB}
                    title={DynamicSubMenuItem?.pairs[0]?.dsB}
                    tabClassName="custom-tab"
                  >
                    {loading ? (
                      <p>Loading...</p>
                    ) : columns?.length > 0 &&
                      nostroData?.transactions?.length > 0 ? (
                      <BootstrapTable
                        bordered={false}
                        classes="rs-table table-layout-auto mb-0 table-custom-style custom-table-background"
                        wrapperClasses="overflow-y-auto mb-2 fancy-scroll"
                        keyField="id"
                        bootstrap4={true}
                        remote={{ pagination: true }}
                        data={nostroData?.transactions ?? []}
                        columns={columns ?? []}
                      />
                    ) : (
                      <p>No data found or columns are empty.</p>
                    )}
                    <Pagination
                      setPage={setPage}
                      page={page}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      totalPages={totalPages}
                      showCSV
                      totalElements={totalElements}
                      data={nostroData?.transactions ?? []}
                      CSVHeaders={converToCSVHeader(nostroData?.headers) ?? []}
                      csvName="Accounts Data"
                    />
                  </Tab>

                  <Tab
                    eventKey="reconciler-tab"
                    title="Reconciler"
                    tabClassName="custom-tab"
                  >
                    {reconciledData.length !== 0 && reconciledData ? (
                      <div className="row justify-content-end align-items-end">
                        {/* {selectedRow && !showViewModal && (
                          <>
                            <div
                              className="col-6"
                              style={{ marginTop: "64px" }}
                            >
                              <button
                                className="btn btn-info btn-sm"
                                onClick={() => setShowModal(true)}
                                style={{ marginTop: "-90px" }}
                                disabled={
                                  selectedRow?.status === "MATCHED" ||
                                  selectedRow?.status === "ERROR"
                                    ? true
                                    : false
                                }
                              >
                                Match With Adjustment
                              </button>
                              <button
                                className="btn btn-info ml-3 btn-sm"
                                onClick={() => setShowLocateModal(true)}
                                style={{ marginTop: "-90px" }}
                                disabled={
                                  selectedRow?.status === "MATCHED" ||
                                  selectedRow?.status === "ERROR" ||
                                  selectedRow?.status === "SUGGESTED"
                                    ? true
                                    : false
                                }
                              >
                                Locate Transaction
                              </button>
                            </div>
                          </>
                        )} */}
                        <div className="col-12">
                          <div className="table-responsive fancy-scroll">
                            <table
                              className="table nostro-reconciler-table"
                              style={{ lineHeight: "12px" }}
                            >
                              <thead>
                                <tr>
                                  <th
                                    colSpan={
                                      (reconciledData?.headers?.length
                                        ? reconciledData?.headers?.length
                                        : 0) + 3
                                    }
                                  >
                                    <h6 className="text-info text-center">
                                      {reconciledData?.datasourceA}
                                    </h6>
                                  </th>
                                  <th
                                    colSpan={
                                      reconciledData?.headers?.length
                                        ? reconciledData?.headers?.length
                                        : 0
                                    }
                                  >
                                    <h6 className="text-danger text-center">
                                      {reconciledData?.datasourceB}
                                    </h6>
                                  </th>
                                </tr>
                                <tr>
                                  {/* <th className="text-info"></th> */}
                                  <th className="text-info">Action</th>
                                  <th className="text-info">Match Rule</th>
                                  <th className="text-info">Status</th>
                                  {reconciledData?.headers?.map(
                                    (item, index) => (
                                      <th
                                        className={`text-info ${
                                          item === "Debit" ||
                                          item === "Credit" ||
                                          item === "Balance" ||
                                          item === "Amount"
                                            ? "text-right"
                                            : "text-left"
                                        }`}
                                        key={index}
                                      >
                                        {item}
                                      </th>
                                    )
                                  )}
                                  {reconciledData?.headers?.map(
                                    (item, index) => (
                                      <th
                                        className={`text-danger ${
                                          item === "Debit" ||
                                          item === "Credit" ||
                                          item === "Balance" ||
                                          item === "Amount"
                                            ? "text-right"
                                            : "text-left"
                                        }`}
                                        key={index}
                                      >
                                        {item}
                                      </th>
                                    )
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {reconciledData?.transactions?.map(
                                  (item, indexMain) => {
                                    const maxRows = Math.max(
                                      item.dsA.length,
                                      item.dsB.length
                                    );

                                    return Array.from({ length: maxRows }).map(
                                      (_, i) => {
                                        const dsAItem = item.dsA[i] || {};
                                        const dsBItem = item.dsB[i] || {};

                                        const rowClass =
                                          indexMain % 2 === 0
                                            ? "gray-row"
                                            : "white-row";

                                        return (
                                          <tr
                                            key={i}
                                            className={rowClass}
                                            onClick={(e) => {
                                              if (
                                                e.target.type !== "checkbox" &&
                                                e.target.id !== "checkbox" &&
                                                e.target.id !==
                                                  "match-with-adjustment" &&
                                                e.target.id !==
                                                  "locate-transaction"
                                              ) {
                                                setSelectedRow(item);
                                                setShowViewModal(true);
                                              }
                                            }}
                                          >
                                            {/* Render matchedBy and status only on the first row of dsA */}
                                            {/* {i === 0 && ( */}
                                            <>
                                              {/* <td
                                                id="checkbox"
                                                className="text-left pt-2 m-1"
                                                onClick={(e) => {
                                                  if (
                                                    e.target.id === "checkbox"
                                                  ) {
                                                    if (
                                                      selectedRow &&
                                                      selectedRow.outputId ===
                                                        item.outputId
                                                    ) {
                                                      setSelectedRow(null);
                                                    } else {
                                                      setSelectedRow(item);
                                                    }
                                                  }
                                                }}
                                              >
                                                {i === 0 && (
                                                  <FormCheck
                                                    id="checkbox"
                                                    type="checkbox"
                                                    onChange={(e) => {
                                                      if (
                                                        selectedRow &&
                                                        selectedRow.outputId ===
                                                          item.outputId
                                                      ) {
                                                        setSelectedRow(null);
                                                      } else {
                                                        setSelectedRow(item);
                                                      }
                                                    }}
                                                    checked={
                                                      !showViewModal &&
                                                      selectedRow?.outputId ===
                                                        item.outputId
                                                    }
                                                  />
                                                )}
                                              </td> */}
                                              <td>
                                                {i === 0 &&
                                                  (item?.status === "MISSING" ||
                                                    item?.status ===
                                                      "UNMATCHED") && (
                                                    <div className="d-flex justify-content-between align-item-center">
                                                      <div className="item">
                                                        <OverlayTrigger
                                                          placement="top"
                                                          overlay={
                                                            <Tooltip id="button-tooltip-2">
                                                              Locate Transaction
                                                            </Tooltip>
                                                          }
                                                        >
                                                          <i
                                                            id="locate-transaction"
                                                            className="fa fa-list-alt"
                                                            aria-hidden="true"
                                                            style={{
                                                              fontSize: "18px",
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={(e) => {
                                                              setSelectedRow(
                                                                item
                                                              );
                                                              setShowLocateModal(
                                                                true
                                                              );
                                                            }}
                                                          />
                                                        </OverlayTrigger>
                                                      </div>
                                                      <div className="item ml-2">
                                                        <OverlayTrigger
                                                          placement="top"
                                                          overlay={
                                                            <Tooltip id="button-tooltip-2">
                                                              Match With
                                                              Adjustment
                                                            </Tooltip>
                                                          }
                                                        >
                                                          <i
                                                            id="match-with-adjustment"
                                                            className="fa fa-exchange"
                                                            aria-hidden="true"
                                                            style={{
                                                              fontSize: "18px",
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={(e) => {
                                                              setSelectedRow(
                                                                item
                                                              );
                                                              setShowModal(
                                                                true
                                                              );
                                                            }}
                                                          />
                                                        </OverlayTrigger>
                                                      </div>
                                                    </div>
                                                  )}
                                              </td>
                                              <td className="text-center">
                                                {i === 0 &&
                                                  (item.matchedBy ?? "-")}
                                              </td>
                                              <td className="text-left pt-2">
                                                {/* {item.status ?? "-"} */}
                                                {i === 0 &&
                                                  reconcileStatus(item?.status)}
                                              </td>
                                            </>

                                            {Object.keys(dsAItem).map(
                                              (key, index) => {
                                                return reconciledData?.headers?.includes(
                                                  key
                                                ) ? (
                                                  indexes.includes(index) ? (
                                                    <td
                                                      className="text-right"
                                                      key={index}
                                                    >
                                                      {dsAItem[key]
                                                        ? formatNumber(
                                                            dsAItem[key]
                                                          )
                                                        : ""}
                                                    </td>
                                                  ) : (
                                                    <td
                                                      className="text-left"
                                                      key={index}
                                                      style={
                                                        key === "Description"
                                                          ? {
                                                              whiteSpace:
                                                                "normal",
                                                              wordWrap:
                                                                "break-word",
                                                              minWidth: "342px",
                                                              lineHeight: "1.5",
                                                              paddingTop: "6px",
                                                            }
                                                          : null
                                                      }
                                                    >
                                                      {key === "Posting Date"
                                                        ? DateFormat(
                                                            date,
                                                            dsAItem[key]
                                                          )
                                                        : dsAItem[key]
                                                        ? dsAItem[key]
                                                        : ""}
                                                    </td>
                                                  )
                                                ) : null;
                                              }
                                            )}

                                            {Object.keys(dsBItem).map(
                                              (key, index) => {
                                                return reconciledData?.headers?.includes(
                                                  key
                                                ) ? (
                                                  indexes.includes(index) ? (
                                                    <td
                                                      className="text-right"
                                                      key={index}
                                                    >
                                                      {dsBItem
                                                        ? formatNumber(
                                                            dsBItem[key]
                                                          )
                                                        : ""}
                                                    </td>
                                                  ) : (
                                                    <td
                                                      className="text-left"
                                                      key={index}
                                                      style={
                                                        key === "Description"
                                                          ? {
                                                              whiteSpace:
                                                                "normal",
                                                              wordWrap:
                                                                "break-word",
                                                              minWidth: "342px",
                                                              lineHeight: "1.5",
                                                              paddingTop: "6px",
                                                            }
                                                          : null
                                                      }
                                                    >
                                                      {key === "Posting Date"
                                                        ? DateFormat(
                                                            date,
                                                            dsAItem[key]
                                                          )
                                                        : dsBItem[key]
                                                        ? dsBItem[key]
                                                        : ""}
                                                    </td>
                                                  )
                                                ) : null;
                                              }
                                            )}
                                          </tr>
                                        );
                                      }
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                          <Pagination
                            setPage={setReconPage}
                            page={reconPage}
                            pageSize={reconPageSize}
                            setPageSize={setReconPageSize}
                            totalPages={reconTotalPages}
                            totalElements={reconTotalElements}
                            data={reconciledData?.nostroReconciler || []}
                            CSVHeaders={headers}
                          />
                        </div>
                      </div>
                    ) : (
                      <p>No data found or columns are empty.</p>
                    )}
                  </Tab>
                  <TabPane
                    title={
                      <div class="has-search">
                        <span class="fa fa-search form-control-feedback"></span>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Search"
                          value={tempQuery}
                          onChange={(e) => {
                            setTempQuery(e.target.value);
                          }}
                          onKeyUp={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              setPage(1);
                              setQuery(tempQuery);
                            }
                          }}
                        />
                      </div>
                    }
                    tabClassName="custom-tab p-0 border-0 draggable-false cursor-default"
                  ></TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        {showModal && (
          <NostroModal
            showModal={showModal}
            setShowModal={setShowModal}
            selectedAccount={selectedAccount}
            selectedRow={
              presistState?.adjustmentFlow
                ? presistState?.selectedRow
                : {
                    ...selectedRow,
                    dsA: [
                      ...selectedRow?.dsA?.map((txn) => {
                        return { ...txn, status: selectedRow?.status };
                      }),
                    ],
                    dsB: [
                      ...selectedRow?.dsB?.map((txn) => {
                        return { ...txn, status: selectedRow?.status };
                      }),
                    ],
                  }
            }
            dataSource={{
              batchId: reconciledData?.batchId,
              datasourceA: reconciledData?.datasourceA,
              datasourceAId: reconciledData?.datasourceAId,
              datasourceB: reconciledData?.datasourceB,
              datasourceBId: reconciledData?.datasourceBId,
              datasourceAName: reconciledData?.datasourceAName,
              datasourceBName: reconciledData?.datasourceBName,
              headers: reconciledData?.headers,
            }}
          />
        )}
        {showViewModal && (
          <NostroViewModal
            showModal={showViewModal}
            setShowModal={setShowViewModal}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            header={reconciledData?.headers}
          />
        )}
        {showLocateModal && (
          <LocateTransactionModal
            showModal={showLocateModal}
            setShowModal={setShowLocateModal}
            selectedAccount={selectedAccount}
            selectedRow={{
              ...selectedRow,
              dsA: [
                ...selectedRow?.dsA?.map((txn) => {
                  return { ...txn, status: selectedRow?.status };
                }),
              ],
              dsB: [
                ...selectedRow?.dsB?.map((txn) => {
                  return { ...txn, status: selectedRow?.status };
                }),
              ],
            }}
            header={reconciledData?.headers}
            dataSource={{
              batchId: reconciledData?.batchId,
              datasourceA: reconciledData?.datasourceA,
              datasourceAId: reconciledData?.datasourceAId,
              datasourceB: reconciledData?.datasourceB,
              datasourceBId: reconciledData?.datasourceBId,
              datasourceAName: reconciledData?.datasourceAName,
              datasourceBName: reconciledData?.datasourceBName,
              manualMatchingHeaders: reconciledData?.manualMatchingHeaders,
            }}
            dsaAccount={{
              type: summaryData[0]?.accountType ?? "",
              number: summaryData[0]?.accountNumber ?? "",
            }}
            dsbAccount={{
              type: summaryData[1]?.accountType ?? "",
              number: summaryData[1]?.accountNumber ?? "",
            }}
          />
        )}
      </div>
    </>
  );
};
export default NostroDynamicAccounts;
