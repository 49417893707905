export default function horizontalBarChart({
  theme,
  color,
  loader,
  chartData,
}) {
  let noData = true;
  chartData?.series?.data?.forEach((data) => {
    if (noData) {
      noData = data == null;
    }
  });
  const chart = {
    title: {
      text:
        !loader && noData
          ? `No Data available for ${chartData.title} `
          : chartData.title,
      left: noData ? "center" : "left",
      top: noData ? "center" : "top",
      textStyle: {
        color: "#777",
        fontSize: "13px",
        fontFamily: "Montserrat Bold",
        overflow: "break",
        width: noData ? 200 : 400,
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        // Use axis to trigger tooltip
        type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
      },
    },
    toolbox: {
      show: true,
      showTitle: false, // hide the default text so they don't overlap each other
      feature: {
        saveAsImage: {
          show: true,
          title: "Save As Image",
        },
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "value",
      splitLine: {
        lineStyle: {
          color: theme === "light" ? "#ccc" : "#333",
        },
      },
      splitNumber: 2,
    },
    yAxis: {
      type: "category",
      data: noData ? [] : chartData.yAxis.data, // for labels
      axisLine: {
        show: noData ? false : true,
      },
    },
    series: [
      {
        color: color,
        type: "bar",
        stack: "total",
        label: {
          show: true,
          position: "outside",
        },
        emphasis: {
          focus: "series",
        },
        data: chartData?.series?.data, // for data
      },
    ],
  };
  return chart;
}
