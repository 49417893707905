import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, Field } from "formik";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import axiosInstance from "../../../utils/axiosInstance";
import Toast from "../../../components/Toast/Toast";
import NewFilter from "../../../components/FilterDropdown/NewFilter";
import LoaderComponent from "../../../components/Spinner/LoaderComponent";
import * as Yup from "yup";
import queryString from "query-string";
import GoogleMap from "../../../containers/GoogleMap/NewMap";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const BranchMgtSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required Field*"),
  address1: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required Field*"),
  phone: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Required Field*"),
  manager: Yup.string().required("Required Field*"),
  // glAccount: Yup.string().required("Required Field*"),
  branchCode: Yup.string().required("Required Field*"),
  city: Yup.string().required("Required Field*"),
  country: Yup.string().required("Required Field*"),
  postCode: Yup.string().required("Required Field*"),
  province: Yup.string().required("Required Field*"),
});

const BranchManagement = (props) => {
  const [regions, setRegions] = useState([]);
  const [institutions, setInstitution] = useState([]);
  const [regionId, setRegionId] = useState("");
  const [institutionId, setInstitutionId] = useState("");
  const [branch, setBranch] = useState(null);
  const [loader, setLoader] = useState(true);
  const [pos, setPos] = useState({ lat: "", lng: "" });
  const { id } = queryString.parse(props.location.search);
  const componentMounted = useRef(true); // (3) component is mounted
  const [glAccList, setGlAccList] = useState([]);
  const [breadCrumbState] = useState([
    {
      id: 0,
      title: "Branch Management",
      isActive: true,
      routeTo: "/branch-management",
    },
    {
      id: 1,
      title: "Update Branch",
      isActive: false,
      routeTo: `/update-branch-management?id=${id}`,
    },
  ]);

  const mapRegions = (regions) => {
    const arr = [];
    // eslint-disable-next-line
    regions.map((region) => {
      arr.push({
        id: region.codeValue,
        label: region.label,
      });
      if (region.subClass) {
        arr.push(...mapRegions(region.subClass));
      }
    });
    return arr;
  };
  const getRegions = () => {
    axiosInstance
      .get("/hierarchical/filters?filter_name=region")
      .then((res) => {
        setRegions(mapRegions(res.data?.filters[0].filterData));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getInstitutions = () => {
    axiosInstance
      .get("/institutions")
      .then((res) => {
        setInstitution(
          res.data.institutions.map((institution) => ({
            id: institution.institutionId,
            label: institution.institutionName,
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getGlAccounts = async () => {
    axiosInstance
      .get(`/accounts/account-list?type_indicator=gl`)
      .then((res) => {
        setGlAccList(
          res?.data?.accounts?.map((i) => {
            return {
              label: i?.name,
              value: i?.id,
            };
          })
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getBranch = (id) => {
    axiosInstance
      .get(`/branches/${id}`)
      .then((res) => {
        setBranch(res.data.branch);
        setPos({
          lat: res.data.branch.latitude,
          lng: res.data.branch.longitude,
        });
        setInstitutionId(res.data.branch.institutionId.toString() ?? "");
        setRegionId(+res.data.branch.regionId ?? "");
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const createBranch = async (values) => {
    const data = {
      branch: {
        regionId: regionId,
        name: values.name,
        address1: values.address1,
        address2: values.address2,
        phone: values.phone,
        latitude: pos?.lat,
        longitude: pos?.lng,
        manager: values.manager,
        institutionId: institutionId,
        glAccount: values.glAccount === "" ? null : values.glAccount,
        branchCode: values.branchCode,
        city: values.city,
        country: values.country,
        province: values.province,
        postCode: values.postCode,
      },
    };
    axiosInstance
      .put(`/branches/${id}`, { data })
      .then((res) => {
        if (res.message.status !== "200") {
          Toast(res.message.description, "error");
        } else {
          Toast("Branch updated successfully!", "success");
          props.history.push("/branch-management");
        }
      })
      .catch((error) => {
        console.log("error ", error);
        Toast(error.response.data.message.description, "error");
      });
  };

  useEffect(() => {
    if (!id) props.history.push("/branch-management");
    else getBranch(id);
    getRegions();
    getInstitutions();
    getGlAccounts();
    // eslint-disable-next-line
    return () => {
      // This code runs when component is unmounted
      componentMounted.current = false; // (4) set it to false when we leave the page
    };
  }, []);

  return (
    <>
      {loader && (
        <div
          className="spinner-center"
          style={{ top: "0%", left: "0%", zIndex: 2000 }}
        >
          <LoaderComponent />
        </div>
      )}
      <div
        className="container-fluid"
        style={{
          borderRadius: "10px",
          opacity: !loader ? "1" : "0.07",
        }}
      >
        <div className="row">
          <div className="col-sm-12 col-lg-6">
            <Breadcrumbs
              data={breadCrumbState}
              history={props.history}
              onClick={(e) => this.breadcrumbOnClick(e)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="d-flex align-items-center mt-3">
              <div
                style={{
                  paddingRight: "10px",
                  marginRight: "10px",
                  fontFamily: "Montserrat SemiBold",
                }}
              >
                Update Branch
              </div>
            </div>
          </div>
        </div>

        {branch && (
          <Formik
            initialValues={{
              name: branch.name || "",
              phone: branch.phone || "",
              address1: branch.address1 || "",
              address2: branch.address2 || "",
              manager: branch.manager || "",
              glAccount: branch.glAccount || "",
              branchCode: branch.branchCode || "",
              city: branch.city || "",
              country: branch.country || "",
              province: branch.province || "",
              postCode: branch.postCode || "",
            }}
            validationSchema={BranchMgtSchema}
            onSubmit={(values) => {
              if (regionId !== "" && institutionId !== "") {
                createBranch(values);
              }
            }}
          >
            {({ errors, touched, handleSubmit, setFieldValue, values }) => (
              <Form id="add-user" className="form" onSubmit={handleSubmit}>
                <div className="row mt-5">
                  {/* Main Left side column started */}
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="d-flex flex-column">
                          <label className="form-label">Branch Name</label>
                          <Field
                            id="name"
                            className="rs-input"
                            type="text"
                            placeholder="Enter Branch Name"
                            name="name"
                          />
                          {errors.name && touched.name ? (
                            <div className="field-error">{errors.name}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="mt-3 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="d-flex flex-column">
                          <label className="form-label">Branch Code</label>
                          <Field
                            id="branchCode"
                            className="rs-input"
                            type="text"
                            placeholder="Enter Branch Code"
                            name="branchCode"
                          />
                          {errors.branchCode && touched.branchCode ? (
                            <div className="field-error">{errors.phone}</div>
                          ) : null}
                        </div>
                      </div>

                      <div className="mt-3 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="d-flex flex-column">
                          <label className="form-label">GL Account</label>
                          {/* <Field
                            className="rs-input"
                            type="text"
                            placeholder="Enter GL Account"
                            name="glAccount"
                          /> */}
                          <NewFilter
                            id="glAccount"
                            title={
                              glAccList.find(
                                (i) =>
                                  i?.value?.toString() ===
                                  values?.glAccount?.toString()
                              )?.label ?? "Select GL Account"
                            }
                            type={"checkbox"}
                            field={"value"}
                            data={glAccList}
                            filter={parseInt(values?.glAccount)}
                            onChange={(e) => setFieldValue("glAccount", e)}
                            isSearchable
                            searchField="label"
                          />
                          {/* {errors.glAccount && touched.glAccount ? (
                            <div className="field-error">
                              {errors.glAccount}
                            </div>
                          ) : null} */}
                        </div>
                      </div>

                      <div className="mt-3 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="d-flex flex-column">
                          <label className="form-label">Phone</label>
                          <Field
                            id="phone"
                            className="rs-input"
                            type="text"
                            placeholder="Enter Phone Number"
                            name="phone"
                          />
                          {errors.phone && touched.phone ? (
                            <div className="field-error">{errors.phone}</div>
                          ) : null}
                        </div>
                      </div>

                      <div className="mt-3 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="d-flex flex-column">
                          <label className="form-label">Manager</label>
                          <Field
                            id="manager"
                            className="rs-input"
                            type="text"
                            placeholder="Enter Manager"
                            name="manager"
                          />
                          {errors.manager && touched.manager ? (
                            <div className="field-error">{errors.manager}</div>
                          ) : null}
                        </div>
                      </div>

                      <div className="mt-3 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="d-flex flex-column">
                          <label className="form-label">Region</label>
                          <NewFilter
                            id="region"
                            title={
                              regions.find(
                                (r) => r?.id === regionId?.toString()
                              )?.label ?? "Select Region"
                            }
                            type={"checkbox"}
                            data={regions}
                            field={"id"}
                            filter={regionId?.toString()}
                            positionRelative
                            onChange={(e) => setRegionId(e)}
                            isSearchable
                            searchField="label"
                          />
                          {regionId === "" ? (
                            <div className="field-error">Required field*</div>
                          ) : null}
                        </div>
                      </div>

                      <div className="mt-3 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="d-flex flex-column">
                          <label className="form-label">Institution</label>
                          <NewFilter
                            id="institution"
                            title={
                              institutions.find(
                                (i) => i.id === institutionId?.toString()
                              )?.label ?? "Select Institution"
                            }
                            type={"checkbox"}
                            data={institutions}
                            field={"id"}
                            positionRelative
                            filter={institutionId?.toString()}
                            onChange={(e) => setInstitutionId(e)}
                            isSearchable
                            searchField="label"
                          />
                          {institutionId === "" ? (
                            <div className="field-error">Required field*</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Main Left side column ended */}

                  {/* Main Right side column started */}
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="d-flex flex-column">
                          <label className="form-label">Branch Address 1</label>
                          <Field
                            id="address1"
                            className="rs-input"
                            type="text"
                            placeholder="Enter Branch Address 1"
                            name="address1"
                          />
                          {errors.address1 && touched.address1 ? (
                            <div className="field-error">{errors.address1}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="d-flex flex-column">
                          <label className="form-label">Branch Address 2</label>
                          <Field
                            id="address2"
                            className="rs-input"
                            type="text"
                            placeholder="Enter Branch Address 2"
                            name="address2"
                          />
                          {errors.address2 && touched.address2 ? (
                            <div className="field-error">{errors.address2}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="mt-3 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="d-flex flex-column">
                          <label className="form-label">Branch City</label>
                          <Field
                            id="city"
                            className="rs-input"
                            type="text"
                            placeholder="Enter Branch City"
                            name="city"
                          />
                          {errors.city && touched.city ? (
                            <div className="field-error">{errors.city}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="mt-3 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="d-flex flex-column">
                          <label className="form-label">Branch Province</label>
                          <Field
                            id="province"
                            className="rs-input"
                            type="text"
                            placeholder="Enter Branch Province"
                            name="province"
                          />
                          {errors.province && touched.province ? (
                            <div className="field-error">{errors.province}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="mt-3 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="d-flex flex-column">
                          <label className="form-label">Branch Country</label>
                          <Field
                            id="country"
                            className="rs-input"
                            type="text"
                            placeholder="Enter Branch Country"
                            name="country"
                          />
                          {errors.country && touched.country ? (
                            <div className="field-error">{errors.country}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="mt-3 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="d-flex flex-column">
                          <label className="form-label">PostCode</label>
                          <Field
                            id="postCode"
                            className="rs-input"
                            type="text"
                            placeholder="Enter PostCode"
                            name="postCode"
                          />
                          {errors.postCode && touched.postCode ? (
                            <div className="field-error">{errors.postCode}</div>
                          ) : null}
                        </div>
                      </div>
                      {/* sub row ended*/}
                      <div className="mt-3 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="d-flex flex-column">
                          <label className="form-label">Latitude</label>
                          <input
                            id="latitude"
                            className="rs-input"
                            type="text"
                            placeholder="Enter Latitude"
                            name="latitude"
                            value={pos?.lat}
                            onChange={(e) =>
                              setPos({ ...pos, lat: e.target.value })
                            }
                          />
                          {pos?.lat === "" ? (
                            <div className="field-error">Required field*</div>
                          ) : null}
                        </div>
                      </div>

                      <div className="mt-3 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="d-flex flex-column">
                          <label className="form-label">Longitude</label>
                          <input
                            id="longitude"
                            className="rs-input"
                            type="text"
                            placeholder="Enter Longitude"
                            name="longitude"
                            value={pos.lng}
                            onChange={(e) =>
                              setPos({ ...pos, lng: e.target.value })
                            }
                          />
                          {pos.lng === "" ? (
                            <div className="field-error">Required field*</div>
                          ) : null}
                        </div>
                      </div>
                      <div
                        className="row mt-3"
                        style={{
                          width: "100%",
                        }}
                      >
                        <GoogleMap
                          className="ml-4"
                          mapStyle={{ width: "100%", height: "35vh" }}
                          onClick={(lat, lng) => {
                            setPos({ lat, lng });
                          }}
                          center={{
                            lat: branch.latitude,
                            lng: branch.longitude,
                          }}
                          pos={pos}
                        />
                      </div>
                    </div>
                  </div>
                  {/* Main Right side column ended */}
                </div>

                <div className="d-flex mt-5 pb-3">
                  <button
                    id="updateBranch"
                    type="submit"
                    name="submit"
                    className={"btn btn-info btn-md"}
                    onClick={handleSubmit}
                  >
                    Update Branch
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </>
  );
};

export default BranchManagement;
