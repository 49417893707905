import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Select, { components } from "react-select";
import ButtonBasic from "../../../../components/Buttons/ButtonBasic";
import OperatorDescription from "../OperatorDescription.json";
import { AggregateValidationSchema, errorStyling } from "../ValidationSchema";

const AggregateModal = ({
  values,
  operatorOptions,
  dsbFields,
  aggregateFields,
  setAggregateFields,
  handleSubmit,
  show,
  onHide,
  editObject,
}) => {
  const [formValues, setFormValues] = useState(values);
  useEffect(() => {
    if (values) setFormValues(values);
  }, [values]);
  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      onSubmit={(values) => {
        let valueIndex = -1;
        aggregateFields?.forEach((obj, i) => {
          if (obj.value === "data." + formValues?.resultset_col_name) {
            valueIndex = i;
          }
        });
        if (valueIndex > -1) {
          setAggregateFields([
            ...aggregateFields?.filter((obj, i) => i !== valueIndex),
            {
              value: "data." + values?.resultset_col_name,
              label: "data." + values?.resultset_col_name,
            },
          ]);
        } else {
          setAggregateFields([
            ...aggregateFields,
            {
              value: "data." + values?.resultset_col_name,
              label: "data." + values?.resultset_col_name,
            },
          ]);
        }
        handleSubmit(editObject?.path, values);
        setFormValues({});
        onHide();
      }}
      validationSchema={AggregateValidationSchema}
    >
      {({ handleSubmit, errors, values, setFieldValue, setValues }) => (
        <Form id="aggregateForm" className="form" onSubmit={handleSubmit}>
          <Modal
            backdrop={true}
            size="xl"
            aria-labelledby="example-modal-sizes-title-lg"
            show={show}
            onHide={() => onHide()}
          >
            <Modal.Header closeButton>
              {editObject?.isNew
                ? "Add DSB Fetch Criteria Aggregate"
                : "Edit DSA Fetch Criteria Aggregate"}
            </Modal.Header>
            <Modal.Body scrollable="true">
              <div className="p-3 my-3">
                <div className="row">
                  <div className="col-3">
                    {/* Label Text Field */}
                    <div className="workflowModalTitle">Label</div>
                    <Field
                      title={values?.label}
                      className="form-control rs-input highlight"
                      type="text"
                      placeholder="Label"
                      name={`label`}
                      onChange={(e) => setFieldValue(`label`, e.target.value)}
                    />
                  </div>
                  <div className="col-3">
                    {/* DS B Field Name DropDown */}
                    <div className="workflowModalTitle">DSB Field Name</div>
                    <div title={values?.ds_b_field_name}>
                      <Select
                        options={dsbFields}
                        name={"ds B Field Name"}
                        value={dsbFields?.find(
                          (field) => field.value === values?.ds_b_field_name
                        )}
                        onChange={(e) =>
                          setFieldValue(`ds_b_field_name`, e?.value)
                        }
                        isClearable
                      />
                    </div>
                    <ErrorMessage
                      component={() => (
                        <div style={errorStyling}>
                          {errors?.ds_b_field_name}
                        </div>
                      )}
                    />
                  </div>
                  <div className="col-3">
                    {/* Operator DropDown */}
                    <div className="workflowModalTitle">Operator</div>
                    <div
                      title={
                        operatorOptions?.rightCriteriaAggregateOperators?.find(
                          (operator) => operator.value == values?.operator
                        )?.label
                      }
                    >
                      <Select
                        options={
                          operatorOptions?.rightCriteriaAggregateOperators
                        }
                        name={"Operator"}
                        value={operatorOptions?.rightCriteriaAggregateOperators?.find(
                          (operator) => operator.value == values?.operator
                        )}
                        onChange={(e) => setFieldValue(`operator`, e?.value)}
                        components={{
                          Option: (props) =>
                            OperatorDescription?.find(
                              (op) => op?.operator == props?.data?.value
                            )?.description ? (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip">
                                    {
                                      OperatorDescription?.find(
                                        (op) =>
                                          op?.operator == props?.data?.value
                                      )?.description
                                    }
                                  </Tooltip>
                                }
                              >
                                <div>
                                  <components.Option {...props} />
                                </div>
                              </OverlayTrigger>
                            ) : (
                              <components.Option {...props} />
                            ),
                        }}
                        isClearable
                      />
                    </div>
                    <ErrorMessage
                      component={() => (
                        <div style={errorStyling}>{errors?.operator}</div>
                      )}
                    />
                  </div>
                  <div className="col-3">
                    {/* Result Set Col Text Field */}
                    <div className="workflowModalTitle">
                      Result Set Col Name
                    </div>
                    <Field
                      title={values?.resultset_col_name}
                      className="form-control rs-input highlight"
                      type="text"
                      placeholder="Result Set Col Name"
                      name={`resultset_col_name`}
                      onChange={(e) => {
                        let value = e.target.value;
                        // Replace spaces with underscores
                        value = value.replace(/\s+/g, "_");
                        // Remove special characters (allow only alphanumeric and underscores)
                        value = value.replace(/[^a-zA-Z0-9_]/g, "");
                        setFieldValue(`resultset_col_name`, value);
                      }}
                    />
                    <ErrorMessage
                      component={() => (
                        <div style={errorStyling}>
                          {errors?.resultset_col_name}
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex">
                <ButtonBasic
                  id="cancel"
                  title="Cancel"
                  onClick={() => onHide()}
                />
              </div>
              <button
                id="submit"
                type="submit"
                name="submit"
                className={"btn btn-info btn-md mr-2"}
                onClick={handleSubmit}
              >
                Add
              </button>
            </Modal.Footer>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default AggregateModal;
