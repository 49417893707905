import React, { useState, useEffect, useCallback } from "react";
import arrowRight from "../../../assets/images/arrow-right-lg.png";
import axios from "axios";
import moment from "moment";
import ButtonBasic from "../../../components/Buttons/ButtonBasic";
import ButtonTransparent from "../../../components/Buttons/ButtonTransparent";

import axiosInstance from "../../../utils/axiosInstance";
import { transactionFlowNames as flowNames } from "../../../constants/Constants";
import { TransactionLogStyle } from "./TransactionsStyle";
import { useDateTime } from "../../../components/Helper/DateTime";
import Toast from "../../../components/Toast/Toast";
import { nullChecker } from "../../../components/Helper/NullChecker";
import { DateFormat } from "../../../utils/formatDateTime";
const TransactionlogModal = ({ transactionId }) => {
  const { date, dateTime } = useDateTime();
  const [transactionData, setTransactionData] = useState({});
  const [comment, setComment] = useState("");
  const username =
    JSON.parse(localStorage.getItem("userData")).firstName +
    " " +
    JSON.parse(localStorage.getItem("userData")).lastName;
  const getTransactions = useCallback(async () => {
    let _transactionData;
    try {
      const apiResponse = await axiosInstance.get(
        `/transactions/${transactionId}`
      );
      if (apiResponse.message.status !== "200") {
        Toast(apiResponse.message.description, "error");
      } else {
        _transactionData = apiResponse.data;
        setTransactionData(_transactionData);
      }
    } catch (e) {
      // console.log("e", e);
      const response = await axios.get("/api/transactions/1234");
      _transactionData = response.data.data;
      setTransactionData(_transactionData);
    }
  }, [transactionId]);

  useEffect(() => {
    getTransactions();
  }, [getTransactions]);

  const addNote = () => {
    let now = moment();
    let note = {
      comment,
      author: username,
      date: now.format(date),
      time: now.hour() + ":" + now.minute(),
    };
    setComment("");
    let _notes = transactionData?.notes || [];
    _notes.push(note);
    setTransactionData({ ...transactionData, notes: _notes });
  };
  return (
    <TransactionLogStyle>
      <div className="row">
        <div className="col-8">
          <div className="row">
            <div className="d-flex px-3 align-items-center">
              <h5 className="mb-3 color-dark mr-2" style={{ fontSize: "18px" }}>
                Transaction Flow
              </h5>
              <div className="legend-wrapper d-flex align-items-center mb-3">
                {transactionData?.transactionFlow?.map((flow, i) => {
                  return (
                    <div
                      className="tsg-badge-rounded danger tsg-badge-sm"
                      key={i}
                    >
                      <div className="legend-title color-light">
                        {flowNames.map((obj) =>
                          obj.id === flow ? obj.name : null
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div
                  className="col-12 overflow-auto"
                  style={{ height: "28em" }}
                >
                  {transactionData?.matchPair?.map(
                    ({ datasourceA, datasourceB, matchingData }, i) => (
                      <div key={i}>
                        <div className="matching-rule-wrapper my-3">
                          <div className="d-flex flex-row mb-3">
                            <div className="color-lightBlue flow-badge atm">
                              {datasourceA}
                            </div>
                            <span className="d-inline-flex flex-column mx-2 justify-content-center">
                              <img
                                style={{
                                  width: "20px",
                                  filter: "brightness(0.5)",
                                }}
                                src={arrowRight}
                                alt="arrow-right"
                              />
                            </span>
                            <div className="color-success flow-badge switch">
                              {datasourceB}
                            </div>
                          </div>
                        </div>
                        <table className="table table-dim-dark table-borderless transaction-flow-modal">
                          <thead>
                            <tr>
                              <th scope="col"></th>
                              {matchingData.map(({ label, matched }, i) => {
                                return (
                                  <th
                                    scope="col"
                                    key={i}
                                    style={{
                                      color: matched ? "" : "#ef476f",
                                    }}
                                  >
                                    {label}
                                  </th>
                                );
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="text-bold">{datasourceA}</td>
                              {matchingData.map(
                                ({ datasourceA, matched, label }, i) => (
                                  <td
                                    key={i}
                                    style={{
                                      color: matched ? "" : "#ef476f",
                                    }}
                                  >
                                    {label.toLowerCase() === "posting date"
                                      ? datasourceA.value
                                        ? DateFormat(date, datasourceA.value)
                                        : nullChecker(datasourceA.value)
                                      : nullChecker(datasourceA.value)}
                                  </td>
                                )
                              )}
                            </tr>
                            <tr>
                              <td className="text-bold">{datasourceB}</td>
                              {matchingData.map(
                                ({ datasourceB, matched, label }, i) => (
                                  <td
                                    key={i}
                                    style={{
                                      color: matched ? "" : "#ef476f",
                                    }}
                                  >
                                     {label.toLowerCase() === "posting date"
                                      ? datasourceB.value
                                        ? DateFormat(date, datasourceB.value)
                                        : nullChecker(datasourceB.value)
                                      : nullChecker(datasourceB.value)}
                                  </td>
                                )
                              )}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="notes-wrapper">
            <h5 className="mb-3 color-gray-dim">Notes</h5>
            <div className="overflow-auto" style={{ height: "22em" }}>
              {transactionData?.notes?.map(
                ({ author, comment, date, time }, i) => (
                  <ul className="rs-notes-container" key={i}>
                    <li className="rs-notes-item">
                      <h6>{author}</h6>
                      <div className="note-comment">{comment}</div>
                      <sub>
                        {date} {time}
                      </sub>
                    </li>
                  </ul>
                )
              )}
            </div>
            <div className="mt-3 position-relative">
              <textarea
                style={{
                  border: "none",
                  padding: "5px",
                  borderRadius: "5px",
                  marginRight: "10px",
                }}
                placeholder="Write a Note"
                rows="3"
                id="note"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                className="mb-0 w-100"
              ></textarea>
              <div className="w-100 mt-2">
                <ButtonBasic
                  classes={"primary px-4 w-100"}
                  title={"Add Note"}
                  onClick={() => addNote()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </TransactionLogStyle>
  );
};
export default TransactionlogModal;
