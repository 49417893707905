import { CSVLink } from "react-csv";
import CSVIcon from "../../assets/images/csv1.png";
const Pagination = ({
  page = 1,
  setPage,
  totalPages = 1,
  pageSize = 15,
  setPageSize,
  tableName = "",
  data = [],
  CSVHeaders = [],
  csvName,
  showCSV = false,
  totalElements,
}) => {
  page = page === 0 ? 1 : page;
  if (totalPages <= 0) return null;
  // totalPages = totalPages === 0 ? 1 : totalPages;

  return (
    <nav
      aria-label="Page navigation example"
      className="d-flex justify-content-between mt-2"
    >
      <span className="dropdown justify-content-start">
        {setPageSize && (
          <span>
            <button
              className="btn btn-default btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {pageSize}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <span className="dropdown-item" onClick={() => setPageSize(15)}>
                15
              </span>
              <span className="dropdown-item" onClick={() => setPageSize(30)}>
                30
              </span>
              <span className="dropdown-item" onClick={() => setPageSize(50)}>
                50
              </span>
              <span className="dropdown-item" onClick={() => setPageSize(100)}>
                100
              </span>
            </div>
          </span>
        )}
        &nbsp;&nbsp;
        {showCSV && (
          <CSVLink
            data={data}
            filename={csvName ?? "Accounts Details"}
            headers={CSVHeaders}
            target="_blank"
            className="mr-2"
          >
            <img
              width={25}
              height={25}
              src={CSVIcon}
              style={{ backgroundColor: "white" }}
              className="right-btn mr-2"
              alt="rightbtn"
            />
          </CSVLink>
        )}
      </span>
      <p>
        {totalElements && (
          <p id="totalElements">Total Records: {totalElements}</p>
        )}
      </p>
      <ul className="pagination react-bootstrap-table-pagination justify-content-end">
        <li className={`page-item ${page === 1 ? "disabled" : ""}`}>
          <button
            id="firstPage"
            className="page-link"
            disabled={page === 1}
            onClick={() =>
              tableName ? setPage(1, pageSize, tableName) : setPage(1)
            }
          >
            {"<<"}
          </button>
        </li>
        <li className={`page-item ${page === 1 ? "disabled" : ""}`}>
          <button
            id="prevPage"
            className="page-link"
            disabled={page === 1}
            onClick={() =>
              tableName
                ? setPage(page - 1, pageSize, tableName)
                : setPage(page - 1)
            }
          >
            {"<"}
          </button>
        </li>
        <li className={`page-item ${page === 1 ? "active" : ""}`}>
          <button
            className="page-link"
            onClick={() => {
              page === 1
                ? tableName
                  ? setPage(1, pageSize, tableName)
                  : setPage(1)
                : page === totalPages && totalPages !== 2
                ? tableName
                  ? setPage(page - 2, pageSize, tableName)
                  : setPage(page - 2)
                : tableName
                ? setPage(page - 1, pageSize, tableName)
                : setPage(page - 1);
            }}
          >
            {page === 1
              ? page
              : page === totalPages && totalPages !== 2
              ? page - 2
              : page - 1}
          </button>
        </li>
        {totalPages > 1 && (
          <li
            className={`page-item ${
              page === 1
                ? ""
                : totalPages === 2 && page === totalPages
                ? "active"
                : page === totalPages
                ? ""
                : "active"
            }`}
          >
            <button
              className="page-link"
              onClick={() => {
                page === 1
                  ? tableName
                    ? setPage(2, pageSize, tableName)
                    : setPage(2)
                  : page === totalPages && totalPages !== 2
                  ? tableName
                    ? setPage(page - 1, pageSize, tableName)
                    : setPage(page - 1)
                  : tableName
                  ? setPage(page, pageSize, tableName)
                  : setPage(page);
              }}
            >
              {page === 1
                ? 2
                : page === totalPages && totalPages !== 2
                ? page - 1
                : page}
            </button>
          </li>
        )}
        {totalPages > 2 && (
          <li className={`page-item ${page === totalPages ? "active" : ""}`}>
            <button
              className="page-link"
              onClick={() => {
                page === 1
                  ? tableName
                    ? setPage(3, pageSize, tableName)
                    : setPage(3)
                  : page === totalPages
                  ? tableName
                    ? setPage(page, pageSize, tableName)
                    : setPage(page)
                  : tableName
                  ? setPage(page + 1, pageSize, tableName)
                  : setPage(page + 1);
              }}
            >
              {page === 1 ? 3 : page === totalPages ? page : page + 1}
            </button>
          </li>
        )}
        <li className={`page-item ${page === totalPages ? "disabled" : ""}`}>
          <button
            id="nextPage"
            className="page-link"
            disabled={page === totalPages}
            onClick={() =>
              tableName
                ? setPage(page + 1, pageSize, tableName)
                : setPage(page + 1)
            }
          >
            {">"}
          </button>
        </li>
        <li className={`page-item ${page === totalPages ? "disabled" : ""}`}>
          <button
            id="lastPage"
            className="page-link"
            disabled={page === totalPages}
            onClick={() =>
              tableName
                ? setPage(totalPages, pageSize, tableName)
                : setPage(totalPages)
            }
          >
            {">>"}
          </button>
        </li>
      </ul>
    </nav>
  );
};
export default Pagination;
