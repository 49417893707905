import React, { useContext } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import DateTimePicker from "../../../../../components/Helper/DateTimePicker";
import DevicePicker from "./DevicePicker";
import MultiStepFormContext from "./MultiStepFormContext";

const FormSchema = Yup.object().shape({
  lastRef: Yup.number().required("Last Ref is required*").typeError("Must be a number"),
  dateTime: Yup.date()
    .typeError("Date & Time is required*")
    .required("Required*"),
  deviceId: Yup.string()
    .typeError("Device ID is required*")
    .required("Device ID is required*"),
});

const Details = () => {
  const { details, setDetails, devices, next } =
    useContext(MultiStepFormContext);

  return (
    <Formik
      initialValues={details}
      validationSchema={FormSchema}
      onSubmit={(values) => {
        setDetails(values);
        next();
      }}
      // validate={(values) => {
      //   const errors = {};
      //   if (!values.lastRef) errors.lastRef = "Last Ref is required*";
      //   if (!values.dateTime) errors.dateTime = "DateTime is required*";
      //   if (!values.deviceId) errors.deviceId = "Device is required*";
      //   return errors;
      // }}
    >
      {({ errors, touched, handleSubmit }) => {
        return (
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <label className="form-label">Last Ref</label>
                <Field
                  className="rs-input"
                  type="text"
                  placeholder="Ref Id"
                  name="lastRef"
                  style={{ width: "100%", height: "50px" }}
                />
                <div className="field-error">{errors.lastRef}</div>
              </div>
              <div className="col-md-5">
                <label className="form-label">Date & Time</label>
                <DateTimePicker name="dateTime" />
                <div className="field-error">{errors.dateTime}</div>
              </div>{" "}
              <div className="col-md">
                <label className="form-label">Device ID</label>
                <Field
                  label="Device"
                  component={DevicePicker}
                  options={devices}
                  placeholder="Select a device"
                  name="deviceId"
                />
                <div className="field-error">{errors.deviceId}</div>
              </div>
            </div>
            <div className="row">
              <button
                type="button"
                className="btn btn-info ml-4 mt-5"
                onClick={handleSubmit}
              >
                Next
              </button>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};
export default Details;
